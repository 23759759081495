import { Heading, Card, HStack, Center,Checkbox,Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement } from "@chakra-ui/react"
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {useState, useEffect} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'
import Login from "./Login Mod";
import ForgotPass from "./ForgotPass Mod";

import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import app from './firebase';
import Profile from "./Profile";
import Dashboard from "./Dashboard"
import { Navigate, useNavigate } from "react-router-dom";



import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import unsplash from "./Assets/unsplash.jpg"
import kphoto from "./kphoto.jpg"
import logoPng from "./Assets/logo.png"



function Signin({setsuccessfulSignin, successfulSignin}){

  let navigate = useNavigate()
  let [hover, sethover] = useState(false)
  let [alerterrorMessage, setAlerterrromessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)
  let [login, setLogin] = useState(false)
  let [forgot, setforgot] = useState(false)
  

  let onClose = () => {
    setAlerterrromessage("")
  }


  let initialValues = {
    email: "",
    password: ""
  }


  let validationSchema = Yup.object({
    email: Yup.string().required("Email required").email("Invalid email format"),
    password: Yup.string().required("Password required")
  })

  let onSubmit= async (values, actions) => {

// try this code which might bring an error
    try{
      let auth = getAuth(app)
//The waiting period
      await signInWithEmailAndPassword(auth, values.email, values.password)
      console.log("Signed in with email and password successfully")
      // This property means currently signed in user email verified
      if(auth.currentUser.emailVerified){
      setsuccessfulSignin(true)
      navigate("/Dashboard")
      }
      else{
        //setsuccessfulSignin(false)
        setAlerterrromessage("Email is not verified. Please verify your email")
      }
    }


// When the errors occurs execute this
    catch (error) {
      console.error("There was an error signing in:", error.message)
      setAlerterrromessage("Something went wrong, please try again later!")

    }

    setTimeout((e)=> {
      actions.setSubmitting(false)
      actions.resetForm()
    },500)

  }



  let signoutHandler = async()=>{

    // try this code which might bring an error
    try{
      let auth = getAuth(app)
    // Waiting period here
    await signOut(auth)
      console.log("You've signed out successfully")
      setsuccessfulSignin(false)
    }
    // when the error occures execute this
    catch(errors) {
      console.errors("There was an error signing out", errors.message)
    }

  }  


    return(
        <div>
          {/*   {!successfulSignin? */}

              <div>
                  
                {!login && !forgot? 

                <Card>
                 
                <Text marginBottom={8}>Log in to Little Lemon restaurant to continue to order online today, tomorrow and beyond.</Text>
                

                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>
                
                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
                <Field as={Input} type="email" placeholder="Email" name="email"  />
                <FormErrorMessage>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>
 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >
               
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <Field as={Input} type={visiblePass? "text": "password"} placeholder="Password" name="password"  />
                </InputGroup>
                <FormErrorMessage>
                <ErrorMessage name="password"/>
                </FormErrorMessage>

                </FormControl>
              
                <HStack width="100%" justifyContent="space-between">
                <Field as={Checkbox} name="rememberMe" id="rememberMe">Remember me</Field>  
                <Text mt={4}><span onClick={(e)=> setforgot(true)} style={{color: "#EE9972", fontWeight: "bold", cursor: "pointer", marginLeft:5}}>Forgot pasword?</span></Text>    
              
                </HStack>

                <Button type="submit" isLoading={formik.isSubmitting} mt={8} width="full" background="#F4CE14" fontWeight="bold" >Login</Button>

                </Form>
                )}
                </Formik>
                <Text mt={4}>Don't have an account? <span onClick={(e)=> {
                  setLogin(true)
                  }} style={{color: "#EE9972", fontWeight: "bold", cursor: "pointer", marginLeft:5}}>Sign up</span></Text>
               
                </Card>

                //This nested () inner tenary operator means that check for additional conditions

                : (forgot? <ForgotPass/> : <Login login={login} setLogin={setLogin} />) }

                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>               
               
            </div> 


        </div>
    )
}

export default Signin