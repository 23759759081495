import { Heading, Box,FormLabel, useToast,Button, Text, InputGroup, Textarea, InputLeftElement, InputRightElement, Center, HStack, VStack, Image, Input, Select, Option, Container, useBreakpointValue, FormControl, FormErrorMessage, 
AlertDialog, InputLeftAddon, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, Flex, Radio, RadioGroup, Checkbox } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage, FormikConsumer} from 'formik' ;
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from "react-router-dom";

import { faImagePortrait, faAngleDown, faWarning, faCalendarAlt, faWineGlassEmpty, faClock, faClockFour, faTimesCircle, faTimesSquare, faClockRotateLeft, faCalendar, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Stepper, Step, StepIndicator, StepStatus, StepIcon, StepNumber, StepSeparator, StepTitle, StepDescription} from '@chakra-ui/react'

import kphoto from "./kphoto.jpg"
import lemonc from "./Assets/lemonc.jpg"
import chefs from "./Assets/chefs.jpg"
import grilledFish from "./Assets/grilledFish.jpg"
import restaurant from "./Assets/restaurant.jpg"
import indoor from "./Assets/indoor.jpg"
import pastalm from "./Assets/pastalm.jpg"


import OrderOnline from "./OrderOnline";
import { faPhone,  faWineGlass, faUser } from "@fortawesome/free-solid-svg-icons";
import PhoneInput from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import app from "./firebase"
import {getFirestore, addDoc, collection} from "firebase/firestore"


  


function ReservationMob() {


let [userDate, setUserDate] = useState("")

let steps = [
    {title: 'First', description: 'Book Reservation'},
    {title: 'Second', description: "Confirm Reservation"},
]


 //Creating Form validation

 let initialValues= {
    activity: "",
    //date: "",
    occasion: "",
    diners: "",
    time: ""
  }

  let validationSchema = Yup.object({
    activity: Yup.string().oneOf(["Indoor Seating", "Outdoor Seating"], "Select either indoor or outdoor").required("Option is Required"),
    //date: Yup.string().required("Date is required"),
    userDate: userDate==""? Yup.string().required("Date required") : Yup.string(),
    occasion: Yup.string().required("occasion is required"),
    diners: Yup.string().required("No. of Diner is required"),
    time: Yup.string().required("Time is required")

  })




    let toast = useToast()


    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })


    let [formSubmitted, setFormsubmitted] = useState(null)


    function onSubmit (values, actions){

        //I'm wrapping setTimeout around console.log and co. here bcos i want to create illusion of a loading sign.  
          setTimeout((e) => {
           console.log("Form Data:",values)
           actions.setSubmitting(false)
           //actions.resetForm() //this reset the values typed by users to empty
           setFormsubmitted(true)
      
        }, 500)
      
      } 
       

      // Everthing for OrderOnline
      let [alertMessage, setAlertmessage] = useState("")
      let [alerterrorMessage, setalerterrorMessage] = useState("")
      let navigate = useNavigate()


//Creating Form validation

let initialValues2= {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message:"",
    check: ""
  }



  let validationSchema2 = Yup.object({
    firstname:Yup.string().required("First Name Required"),
    lastname: Yup.string().required("Last Name Required"),
    email: Yup.string().email("Invalid email format").required(" Email Required"),
    phone: Yup.string().required("Phone Number Required"),
    message:Yup.string().min(25, "Minimum characters is 25"),
    check: Yup.string().required("Please check this box if you want to proceed.")

  })


  let onSubmit2 = async(values, actions) => {

    setTimeout((e)=>{
        actions.setSubmitting(false)
    },30000)

    
    let pro= new Promise((resolve, reject)=>{

        setTimeout(() => {
            reject()
        },30000);
    })

    toast.promise(pro, {
        loading: {
            title: "Reservation pending",
            description: "PLease wait"
        },
        success: {
           title: "Looks great", 
            description: "Reservation resolved"
            },
        error: {
            title: "Promise failed",
            description: "Looks like we encountered a problem. Please wait a few minutes and try again. If the problem persist, please contact support@littlelemon.com"
             }
    })

    //Try this code which might bring an error
    try{
        // The addDoc takes two parameters thus collection and values
        // Collection also takes two values ie. dataB and the name

        let dataB = getFirestore(app)
        let collectionResv= collection(dataB, "Reservation")
        await addDoc(collectionResv, values)
        setAlertmessage(`Your Reservation has been confirmed ${values.firstname}, check your email.`)       

        toast.closeAll()
        
        let pro2 = new Promise((resolve, reject)=>{
            setTimeout(() => {
                resolve()
            }, 100);
        })

        toast.promise(pro2,{
            loading: {
                title: "Promise pending",
                description: "PLease wait"
            },
            success: {
                title: "Looks great",
                description: "Reservation resolved"
            },
            error: {
                title: "Promise failed",
                description: "Something wrong"
            }
        })

        

    }

    //If the error occurs, execute this
    catch(error){
        console.error("Error submitting form:", error.message )
        setalerterrorMessage("Something went wrong, please try again later!")
    }

      setTimeout((e)=> {
        console.log("Form Data:", values)
        //actions.resetForm()        
        },1000) 


        setTimeout((e) =>{
            setAlertmessage("")
            actions.resetForm({values: {firstname: "", lastname: "", email: "", message: ""}})
            setUserDate("")
        }, 10000)

    }



   function onClose(){    
    setAlertmessage("")
   } 

   let onClose2 = () => {
    setalerterrorMessage("")
   }



if(formSubmitted){
    return(
        <div>

        {/*//Form Validation*/}
    <Formik
    onSubmit={onSubmit2} 
    initialValues={initialValues2}
    validationSchema={validationSchema2}
    >
         {(formik) => (

        <Form>

<Container p={5} minHeight="50vh" maxWidth="full" background="#495E57" style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: 5, marginLeft: -10}} paddingBottom={10} id="Top">
        <Box paddingTop={8}>
        <VStack mt={4}>
                
            
            <FormControl isInvalid={!!formik.errors.firstname && formik.touched.firstname}>
            <FormLabel htmlFor="firstname" fontSize="sm" style={{color:"white"}}>⭐ First Name</FormLabel>
            <Field as={Input} name="firstname" id="firstname" color="#495E57" background="white" width="45vw" fontSize="sm" placeholder="First Name" required/>

            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="firstname" />
            </FormErrorMessage>
            </FormControl>
            
            </VStack>

            <VStack mt={4}>
            
            <FormControl isInvalid= {!!formik.errors.email && formik.touched.email}>
            <FormLabel htmlFor="email" fontSize="sm" style={{color:"white"}}>⭐ Email</FormLabel>
            <Field as={Input} color="#495E57" background="white" id="email" name="email" fontSize="sm" width="45vw" placeholder="you@company.com" required/>

            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="email"/>
            </FormErrorMessage>
            </FormControl>
            
            </VStack>



            <VStack alignItems="start" mt={10} spacing={4} style={{color:"white", marginLeft:0}}>
             
            <VStack mt={4} alignItems="start">

                <HStack spacing={4} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faCalendar} size="xl" />
                {/*i'm using instanceof because values.date might not always be a date. Basically it means it's coming from date or created from the date template or blueprint*/}
               {    /* <FormikConsumer>{({values}) => <p>{values.date instanceof Date? values.date.toDateString() : "Select Date"}</p>}</FormikConsumer> */}
                <p style={{fontSize: "small"}}>{userDate? userDate : "Select Date"}</p>
                </HStack>

                <HStack spacing={4} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faUser} size="xl"/>

                <FormikConsumer>{({values}) => <p style={{fontSize:"small"}}>{values.diners? values.diners: "Select Diner"}</p> }</FormikConsumer>

                </HStack>

            </VStack>


            <VStack mt={4} alignItems="start">

                <HStack spacing={4} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faClock} size="xl"/>

                <FormikConsumer>{({values}) => <p style={{fontSize: "small"}}>{values.time? values.time : "Select Time"}</p> }</FormikConsumer>

                </HStack>

                <HStack spacing={4} onClick={(e)=> setFormsubmitted(null)} cursor="pointer">
                <FontAwesomeIcon icon={faWineGlass} size="xl"/>
                <FormikConsumer>{({values}) => <p style={{fontSize: "small"}}>{values.occasion? values.occasion: "Select Occasion"}</p>}</FormikConsumer>
                </HStack>

            </VStack>


            </VStack>


        </Box> 

        


        <Box paddingTop={8}>
            <VStack mt={4} >
            
            <FormControl isInvalid={!!formik.errors.lastname && formik.touched.lastname} >
            <FormLabel htmlFor="lastname" fontSize="sm" style={{color:"white"}}>⭐ Last Name</FormLabel>
            <Field as={Input} name="lastname" id="lastname" color="#495E57" background="white" width="45vw" fontSize="sm" placeholder="Last Name" required/>
            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="lastname" />
            </FormErrorMessage>
            </FormControl>
                
        
            </VStack>

            
            <VStack mt={4} >
            
            <FormControl isInvalid={!!formik.errors.phone && formik.touched.phone} >
            <FormLabel htmlFor="phone" fontSize="sm" style={{color:"white"}}>⭐ Phone Number</FormLabel>
            <Field as={PhoneInput} flags={flags} defaultCountry="US" selected={formik.values.phone} onChange={(phone) => formik.setFieldValue("phone", phone)} name="phone" id="phone" color="#495E57" background="white" style={{width:170}} fontSize="sm" placeholder="Phone Number" required/>
            <FormErrorMessage>
            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
            <ErrorMessage name="phone" />
            </FormErrorMessage>
            </FormControl>
                
            </VStack>

            
            <VStack mt={20} ml={-8}>
                <FormControl isInvalid={!!formik.errors.message && formik.touched.message}>
                <FormLabel htmlFor="message" fontSize="sm" style={{color:"white"}}>Special Requests </FormLabel>
                <Field as={Textarea} minHeight={150} name="message" id="message" color="#495E57" background="white" fontSize="sm" width="55vw" placeholder="Comment" />
            
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="message"/>
                </FormErrorMessage>
                </FormControl>
    
            </VStack>

            

            <Box mt={8} ml={-40} color="white"  alignItems="start">

            <FormikConsumer>{({values}) => <Heading  as="h2" fontSize="xl">{values.activity}</Heading>}</FormikConsumer>
    
            <HStack mt={5} >
                
                <FormControl isInvalid={formik.errors.check && formik.touched.check}>
                <Field as={Checkbox} id="check" name="check" required> You agree to our friendly  <Link to="/privacyPolicy" style={{fontWeight:"bold", textDecoration: "underline"}}>Privacy Policy</Link> </Field>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="check"/>
                </FormErrorMessage>
                
                </FormControl>
            </HStack>
            </Box>
    </Box>


</Container>




<Center>
<Container mt={10}  mb={-20} alignItems="start" minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap:5,  paddingLeft: 1, paddingRight: 1} }>  
    <Box overflow="hidden" borderRadius={10}>
    <Image src={indoor} borderRadius={10} width={150} height={150}  objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
    </Box>
    <Box overflow="hidden" borderRadius={10}>
    <Image src={grilledFish} width={150} height={150} borderRadius={10} objectFit="cover"  _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out"/>
    </Box>
    <Box overflow="hidden" borderRadius={10}>
    <Image src={pastalm} width={150} height={150} borderRadius={10} objectFit="cover"  _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out"/>
    </Box>

</Container>
</Center>


<Center >
<Button type="submit" isDisabled={!formik.values.time} isLoading={formik.isSubmitting} borderRadius={10} p={6} paddingLeft={20} paddingRight={20} marginBottom={20} marginTop={-10} background="#F4CE14" fontWeight="bold">Confirm Reservation</Button><br/>
</Center>


{/*validation ends here */}
{/*this is so i can use FormikConsumer */}


<AlertDialog
isOpen={alertMessage}
onClose={onClose}>

<AlertDialogOverlay>
    <AlertDialogContent>
        <AlertDialogHeader color="#495E57">
            All Good!
         <Center> <FontAwesomeIcon icon={faCheckCircle} size="2xl"/> </Center>
            <AlertDialogCloseButton />
        </AlertDialogHeader>
        <AlertDialogBody fontSize="x-large" color="#495E57">
            {alertMessage}<br/>  <FormikConsumer>{({values}) => <Text fontSize="md" mt={5}>Your table for {values.occasion} of {values.diners} is reserved for { values.date instanceof Date && values.date.toDateString()} at {values.time}.</Text>}</FormikConsumer>

        </AlertDialogBody>
    </AlertDialogContent>

</AlertDialogOverlay>
</AlertDialog> 




<AlertDialog
isOpen={alerterrorMessage}
onClose={onClose2}>

<AlertDialogOverlay>
    <AlertDialogContent backgroundColor= "#FF8A65">
        <AlertDialogHeader paddingTop={5} fontWeight="bold">
            Oops!        
            <AlertDialogCloseButton />
        </AlertDialogHeader>
        <AlertDialogBody paddingBottom={5}>
            {alerterrorMessage}<br/> 

        </AlertDialogBody>
    </AlertDialogContent>

</AlertDialogOverlay>
</AlertDialog> 


</Form>
)}


</Formik>
{/*validation ends here */}



<Stepper size="sm" index ={2} paddingLeft={2} paddingRight={2}  marginBottom={5} marginTop={-10}>
    {/* Execute a function for each item   */}
    {steps.map((f, index)=>{
    return  <Step key ={index}>
            <StepIndicator>
                <StepStatus complete={<StepIcon/>} incomplete={<StepNumber />} active={<stepNumber />} />
            </StepIndicator>

            <Box>
            <StepTitle  >{f.title}</StepTitle>
            <StepDescription >{f.description}</StepDescription>
            </Box>

            <StepSeparator />


        </Step>
    })}
</Stepper>



</div>
    )
}

else{

return(
    <div> 

         {/*//Form Validation*/} 

         {/* Nothing should interfere with the 3F's that is Formik, (formik) sub and Form. 
Also sunmit button dont need onsubmit because it's part of the form*/ }

        <Formik
        onSubmit={onSubmit} 
        initialValues={initialValues}
        validationSchema={validationSchema}  
        >

        {(formik) => (

        <Form>


    <Box p={5} minHeight="50vh" background="#495E57" paddingBottom={10}>
              
        <VStack spacing={5} alignItems="start" marginLeft={1} paddingLeft={1}>
                
            <Heading as= "h1" size="2xl" color="#F4CE14" paddingTop={10} id="Top">
                    Reservations
             </Heading><br/>
                   
        </VStack>

        
            
            
            <FormControl isInvalid={!!formik.errors.activity && formik.touched.activity}>

            <RadioGroup>

            <HStack spacing={4}  alignItems="start"  marginLeft={1} paddingLeft={1} width="98%" justifyContent="space-between" >
                <div>
                <HStack color="white" fontSize="md">
                <FormLabel htmlFor="radio" fontSize="sm">Indoor seating </FormLabel>
                <Field as={Radio} name="activity" value="Indoor Seating" id="radio" />
                </HStack>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="activity"/>
                </FormErrorMessage>
                </div>

                <div>
                <HStack spacing={10} color="white" fontSize="md">
                <FormLabel htmiFor="outdoor" fontSize="sm">Outdoor seating</FormLabel>
                <Field as={Radio} name="activity" value="Outdoor Seating" id="outdoor"/>
                </HStack>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="activity"/>
                </FormErrorMessage>
                </div>
                
            </HStack>
            </RadioGroup>

            </FormControl>

        
        

        <Container style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20, marginLeft: -10}} maxWidth="full" paddingBottom={10} >


            <div >
                   
                <VStack mt={8} borderRadius={8} color="#495E57">

                    {/* toISOString() includes time unlike toString() hence i need to split it at "T" at index 0 to retrieve the date which is the first element. Eg "2022-04-01T12:30:00*/}
                    <FormControl isInvalid= {!!formik.errors.userDate && formik.touched.userDate} >
                    <FormLabel htmlFor="userDate" fontSize="sm" style={{color:"white"}}>Date</FormLabel>
                    <Field as={Input} value={userDate} onChange={(e)=> setUserDate(e.target.value)} type="date" min={new Date().toISOString().split("T")[0]} background="white"  placeholder="Select Date" name="userDate" id="userDate" width="40vw" required={userDate ==""? true : ""}/>
                    <FormErrorMessage>
                    <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>   
                    <ErrorMessage name="userDate"/>
                    </FormErrorMessage>
                    </FormControl>
                    

                </VStack>


                <VStack mt={8} fontSize="md">
                <FormControl isInvalid= {!!formik.errors.diners && formik.touched.diners}>
                <FormLabel htmlFor="diners" fontSize="sm" style={{color:"white"}}>Number of Diners</FormLabel>
                <InputGroup>
                <InputLeftElement children={<FontAwesomeIcon size="xl" color="#495E57" icon={faImagePortrait} />}/>
                <Field style={{textAlign: "center"}} as={Select} color="#495E57" background="white" id="diners" name="diners" fontSize="sm" width="40vw" >
                    <optgroup label= "Table 1">
                   <option value="" disabled>No. of Diners</option>
                   <option>1 Diner</option>
                   <option>3 Diners</option>
                   <option>5 Diners</option>
                   <option>7 Diners</option>
                   <option>9 Diners</option>
                    </optgroup>
        
                    <optgroup label="Table 2">
                    <option>2 Diners</option>
                    <option>4 Diners</option>
                    <option>6 Diners</option>
                    <option>8 Diners</option>
                    </optgroup>
            
                </Field>
                </InputGroup>
                <FormErrorMessage>
                    <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                    <ErrorMessage name="diners"/>
                    </FormErrorMessage>
                </FormControl>
    
                </VStack>
            </div>


            <Box mt={8}>
                <VStack >                
                <FormControl isInvalid={!!formik.errors.occasion && formik.touched.occasion}>
                <FormLabel htmlFor="occasion" fontSize="sm" style={{color:"white"}}>Occasion</FormLabel>
                <InputGroup>
                <InputLeftElement children={<FontAwesomeIcon size="xl" color="#495E57" icon={faWineGlassEmpty}/>}/>
                <Field as={Select} style={{textAlign: "center"}} name="occasion" id="occasion" color="#495E57" background="white" fontSize="sm" width="40vw" >
                    <option value="" disabled>Occasion</option>
                    <option >Birthday </option>
                    <option>Engagement  </option>
                    <option>Anniversary</option>
                </Field>
                </InputGroup>
                <FormErrorMessage>
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                <ErrorMessage name="occasion" />
                </FormErrorMessage>
                </FormControl>
                    
                </VStack>


                <VStack mt={8} fontSize="sm">                    
                    <FormControl isInvalid={!!formik.errors.time && formik.touched.time}>
                    <FormLabel htmlFor="time" fontSize="sm" style={{color:"white"}}>Time</FormLabel>
                    <InputGroup>
                    <InputLeftElement children={<FontAwesomeIcon size="xl" color="#495E57" icon={faClock} />}/>
                    <Field style={{textAlign: "center"}} as={Select} name="time" id="time" color="#495E57" background="white" fontSize="sm" width="40vw" >
                        <optgroup label="Time 5-7 pm">
                        <option value="" disabled>Select Time</option>
                        <option>5:00 pm</option>
                        <option>6:00 pm</option>
                        <option>7:00 pm</option>
                        </optgroup>

                        <optgroup label="Time 8-10 pm">
                        <option>8:00 pm</option>
                        <option>9:00 pm</option>
                        <option>10:00 pm</option>
                        </optgroup>
                    </Field>
                    </InputGroup>
                    <FormErrorMessage>
                    <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                    <ErrorMessage name="time"/>
                    </FormErrorMessage>
                    </FormControl>

                </VStack>
            </Box>

        </Container>

    </Box>

    
        <Container mt={10} mb={-20} minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap:5,  paddingLeft: 1, paddingRight: 1} }>  
        <Box overflow="hidden" borderRadius={10}>
        <Image src={lemonc} width={150} height={150} borderRadius={10}  objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>
        <Box overflow="hidden" borderRadius={10}>
        <Image src={chefs} width={150} height={150} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>
        <Box overflow="hidden" borderRadius={10}>
        <Image src={restaurant} width={150} height={150} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>

        </Container>
            
        <Center >
        <Button type="submit" borderRadius={10} p={6} paddingLeft={20} paddingRight={20} marginBottom={20} marginTop={-10} background="#F4CE14" fontWeight="bold" isLoading={formik.isSubmitting}>Reserve a Table</Button>
        </Center>
    
             
</Form>
    )}
    
</Formik>


<Stepper size="sm" index ={1} paddingLeft={2} paddingRight={2}  marginBottom={5} marginTop={-10}>
    {/* Execute a function for each item   */}
    {steps.map((f, index)=>{
    return  <Step key ={index}>
            <StepIndicator>
                <StepStatus complete={<StepIcon/>} incomplete={<StepNumber />} active={<stepNumber />} />
            </StepIndicator>

            <Box>
            <StepTitle  >{f.title}</StepTitle>
            <StepDescription >{f.description}</StepDescription>
            </Box>

            <StepSeparator />


        </Step>
    })}
</Stepper>

{/*}
    <Container mt={10} alignItems="start" marginLeft={30} paddingLeft={20} minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap:30,  paddingLeft: 100, paddingRight: 150} }>  
        <Image src={kphoto} borderRadius={10}  objectFit="cover" />
        <Image src={kphoto} borderRadius={10} objectFit="cover"/>
        <Image src={kphoto} borderRadius={10} objectFit="cover"/>

    </Container>
    <Button type="submit" onClick={onSubmit} isLoading={formik.isSubmitting} borderRadius={10} p={6} paddingLeft={20} paddingRight={20} marginLeft={540} marginBottom={20} marginTop={-10} background="#F4CE14" fontWeight="bold">Reserve a Table</Button><br/>
        */}



   {/*validation ends here */}


   {/* Everthing here is coming from OrderOnline*/}
          
             

    </div>

    )

}
}

export default ReservationMob