import { Container,Button, useBreakpointValue, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton,Image, Card, Select,FormControl, Input,Box, HStack,Text, Heading, VStack, FormLabel, FormErrorMessage, Divider, Spacer, InputGroup, InputLeftAddon, InputLeftElement, Radio, RadioGroup, useBreakpoint } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {Formik, Form, ErrorMessage, Field, FormikConsumer} from 'formik'
import * as Yup from "yup"
import {useState, useEffect} from "react"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Mastercard from "./Assets/Mastercard.png"
import Gpays from "./Assets/Gpays.png"
//import {BeatLoader} from "react-spinners"
//import {PaystackButton} from 'react-paystack'
import { getFirestore, getDoc, doc } from "firebase/firestore"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import app  from "./firebase"

import { faClock, faEnvelope, faLocationPinLock, faMessage, faPhone, faUser, faWarning } from "@fortawesome/free-solid-svg-icons"
import {faCcMastercard, faCcVisa, faGooglePay, faPaypal} from "@fortawesome/free-brands-svg-icons"
import { Skeleton, SkeletonText, Tooltip,Link,CircularProgress, useToast,Center } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import ChatbotCompo from './ChatbotCompo'


function CheckoutMob ({cart, setCart, count, setCount}){

  let toast = useToast()

  let orderTotal = cart.reduce((acc, item) =>acc + item.price, 0)

  let stateTax= 0.0625 * orderTotal
  let localTax= 0.02 * orderTotal
  let deliveryFee= orderTotal? 7.64 :0
  let [disc, setDisc] = useState(0.20)
  let discountAmt = disc * orderTotal

  let total = orderTotal + stateTax + localTax + deliveryFee - discountAmt
//Discount

let [forDiscount, setforDiscount] = useState(false)
let [norm, setNorm] = useState(false)

let [progress, setProgress] = useState(true)
let [paypal, setPaypal] = useState(false)
let [notPay, setNotPay] = useState(false)
let [alertMessage, setAlertMessage] = useState("")
let [bot, setBot] = useState(false)
let navigate= useNavigate()

let onClose = ()=>{
  setAlertMessage("")
}


// This is another tunnel that receives item id's and item quantity's

function removeFromCart(idReceived, qtyReceived, nameReceived){
  
  // uuid ensures no two items added to the cart have the same id

  let updatedCart = cart.filter(item=> item.uuid !== idReceived)
  setCart(updatedCart)
  

  //We want to find the index of a specific/correct item in the cart array by checking if the id
  //of the ttem in the cart matches the "idReceived" we want to remove

  // in other words, we're searching for the index of the item in the cart array that matches the "idReceived" we want to remove
  //let indexToRemove = cart.findIndex(item => item.id === idReceived)
  //if(indexToRemove !== -1){   //In javascript, the findIndex method returns -1 if no matching index is found. Mind you index starts from 0
  
    //let updatedCart = [...cart]  //Create a copy of the cart array because we don't want to affect/modify the original cart. This ensures any changes affect only the copy
   // updatedCart.splice(indexToRemove, 1)   //Remove one item at the found/specified index. The ".splice()" remove items in an array starting at the specified index which is the first argument. The second argument sprcifies the number of items to remove. If it was two it'll remove the specified index and the next two items following the specified index
   // setCart(updatedCart)   // Update the cart state
  //}


  


  if(count > 0){
  setCount(prevCount => prevCount  - qtyReceived)  
  }
  else{
    navigate("/dashboard")
    setCart([])

  }

  //count < 1 && setCount(prevCount => prevCount * 0)

  

  // The "array.prototype.reduce()" is used to perform calculations involving items in an array which brings it to a single value. 
  // The first argument could be any name and the code will still work. Not necessarily accumulator or total.
  let arr = cart.reduce((addition, obj)=> addition + Object.keys(obj).length, 0);

  arr===8 && navigate("/dashboard")
  console.log("number of properties:", arr )


  toast({
    title: qtyReceived + " quantity(s) of " + nameReceived + " removed",
    description: "A meal has been removed from your order",
    status: "info",
    duration: 9000,
    isClosable: true,
    position: "top-right",
  })

}



  let [initialValues, setInitialValues] = useState({
    name: "",
    lastname: "",
    email: "",
    phoneNumber:"",
    address: "",
    city: "",
    zipCode: "",
    time: "",
    day: "",
    payment: "",
    cardNumber: "",
    nameCard: "",
    expMonth: "",
    expYear: "",
    cvv: "",
    discountCode: ""

  })


  let validationSchema = Yup.object({
    name: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email("Invalid email format"),
    phoneNumber: Yup.string("Phone number required"),
    address: Yup.string().required("Address required"),
    city: Yup.string().required("City required"),
    zipCode: Yup.string().required("Zip code required"),
    time: Yup.string().required("Time required"),
    day: Yup.string().required("Day required"),
    payment: Yup.string().oneOf(["creditCard", "paypal"], "Select payment option"),
    
     
    cardNumber: paypal? Yup.string(): Yup.string().required("Credit Card Number required"),
    nameCard: paypal? Yup.string(): Yup.string().required("Name on card required"),
    expMonth: paypal? Yup.string(): Yup.string().required("Expiration month required"),
    expYear: paypal? Yup.string(): Yup.string().required("Expiration year required"),
    cvv: paypal? Yup.string(): Yup.string().required("Cvv required"),
    discountCode: Yup.string()
    
  })

  let onSubmit = (values, actions) => {
    
 if(forDiscount){

  setNorm(false)

    if(values.discountCode === "DISC"){
      setDisc(0.70)
      setNorm(false)
      setforDiscount(false)
      
      toast({
        title: "Discount code applied",
        description: "You've received a 70% discount on your total order",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right"
      })
     
    }

    else{
     toast({
      title: "Oops, something went wrong",
      description: "You've entered invalid discount code",
      status: "error",
      duration: 9000,
      position: "top-right"
     }) 
     actions.setSubmitting(false)
    setforDiscount(false)
    setNorm(false)
    }

  }

 if(norm){

  setforDiscount(false)  
      setTimeout(()=> {
      setAlertMessage("Something went wrong, please try again later")
      actions.setSubmitting(false)
     // actions.resetForm()
      setNorm(false)

    }, 2000)

  }

  else{
    actions.setSubmitting(false)
  }

  }



  let dataB = getFirestore(app)
  let auth = getAuth(app)

  useEffect((e)=>{

    let authStateChangedListener = onAuthStateChanged(auth, async(currentUser)=>{

    if(currentUser){
    let userRef = doc(dataB, "users", auth.currentUser.uid)
    let getUserDoc = await getDoc(userRef)
 
    getUserDoc.exists() && setInitialValues(getUserDoc.data())

    setProgress(false)

     // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
    let pro = new Promise((resolve, reject)=>{
      resolve()
    })
    // This ensures any existing toasts are closed before displaying new ones
    toast.closeAll()
   toast.promise(pro, {
       loading: {
      title: "Contact Info pending",
      description: "Please wait"
    },
    success: {
      title: "Contact Info resolved",
      description: "Looks great"
    },
    error: {
      title: "Promise rejected",
      description: "Something wrong"
    },
    
    })

    }
    })

    // The "new Promise()" serve as a placeholder for an actual asynchronous operation 
    let pro = new Promise((resolve, reject)=>{
      setTimeout((e)=>{
        reject()
      }, 30000)
    })
    // This ensures any existing toasts are closed before displaying new ones
   toast.closeAll()
   toast.promise(pro, {
       loading: {
      title: "Contact Info pending",
      description: "Please wait"
    },
    success: {
      title: "Contact Info resolved",
      description: "Looks great"
    },
    error: {
      title: "Promise rejected",
      description: "Something wrong"
    },
    
    })
    
    
    return()=>{
      authStateChangedListener()
    }

  },[auth, dataB, toast])


  let handleChange = (e)=>{
    setInitialValues({...initialValues, [e.target.name]: e.target.value})
  }



    return(
        <div>
            <Container paddingBottom={20} minHeight="40vh" background="#495E57" postion="relative" id="landing-section">
          
          <VStack spacing={5} alignItems="start" marginLeft={1} >
          
          <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10}>
            Checkout 
          </Heading>

         
          </VStack>


          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
              {(formik)=> (
 
              <Form>

          <Container mt={20} alignItems="start" paddingLeft={0} paddingRight={0}>
          
        
            <Box >
            <Heading marginBottom={10} as= "h1" size="2xl" color="#F4CE14" >
            Your Details
            </Heading>

                <Card background="#EDEFEE" position="relative" >
                <Heading as="h2" marginLeft={5} marginTop={5} color="black">Contact Information</Heading>
                
    {progress? <Center  minHeight="35vh">
                <CircularProgress position="absolute" style={{zIndex: 1}} top={20} transform="translate(0.5%,15%)" value={80} size="120px" isIndeterminate/>
                </Center> 

                :  <div>
                <HStack mt={10} margin={5} ml={1} mr={1} width="50%" justifyContent="space-between">


                <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
                <FormLabel htmlFor="name" color="black">First Name</FormLabel>
                <InputGroup>
                <InputLeftAddon p={-5} color="blue.600" children={<FontAwesomeIcon icon={faUser}/>}/>
                <Field as={Input} value={initialValues.name} onChange={handleChange} fontSize="sm" name="name" id="name" color="#495E57" width="40vw" readOnly fontWeight="bold" placeholder="First Name" background="transparent"/>
                </InputGroup>
                <FormErrorMessage>
                <ErrorMessage name="name"/>
                </FormErrorMessage>

                </FormControl>
              

                <FormControl isInvalid={!!formik.errors.lastname && formik.touched.lastname}>
                <FormLabel htmlFor="lastname" color="black">Last Name</FormLabel>
                <Field as={Input} value={initialValues.lastname} onChange={handleChange} fontSize="sm" fontWeight="bold" readOnly name="lastname" id="lastname" width="40vw" placeholder="Last Name" color="#495E57" background="transparent"/>
                <FormErrorMessage>
                <ErrorMessage name="lastname"/>
                </FormErrorMessage>

                </FormControl>

                </HStack>


                <HStack mt={10} margin={5} ml={1} mr={1} width="60%" justifyContent="space-between">

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel htmlFor="email" color="black">Email</FormLabel>
                <InputGroup>
                <InputLeftAddon p={-5} color="blue.600" children={<FontAwesomeIcon icon={faEnvelope}/>} />
                <Field as={Input} value={initialValues.email} onChange={handleChange} fontSize="sm" name="email" paddingLeft={2} background="transparent" width="45vw" id="email" color="#495E57" placeholder="Email" />
                </InputGroup>
                <FormErrorMessage>
                <ErrorMessage name="email"/>
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.phoneNumber && formik.touched.phoneNumber}>
                <FormLabel htmlFor="phoneNumber" type="tel" color="black">Phone Number</FormLabel>
                <InputGroup>
                <InputLeftAddon p={-5} color="blue.600" children={<FontAwesomeIcon icon={faPhone}/>} />
                <Field as={Input} value={initialValues.phoneNumber} onChange={handleChange} fontSize="sm" width="33vw" paddingLeft={2} paddingRight={-2} name="phoneNumber" id="phoneNumber" placeholder="Phone Number" color="#495E57" background="transparent" />
                </InputGroup>
                <FormErrorMessage>
                <ErrorMessage name="phoneNumber"/>
                </FormErrorMessage>

                </FormControl>

                </HStack>
                </div>}
                </Card>

               
               <Card background="#EDEFEE" mt={5}>
                <Heading as="h2" marginLeft={5} marginTop={5} color="black">Order Information</Heading>

                <FormControl mt={10} margin={5} isInvalid={!!formik.errors.address && formik.touched.address}>
                <FormLabel htmlFor="address" color="black">Delivery Address</FormLabel>
                <InputGroup>
                <InputLeftElement color="blue.600" children={<FontAwesomeIcon size="xl" icon={faLocationPinLock}/>} />
                <Field as={Input} fontSize="sm" width="80vw" paddingLeft={12} name="address" id="address" placeholder="123 Example Street" color="#495E57" background="white"/>
                </InputGroup>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning}/>
                <ErrorMessage name="address" />
                </FormErrorMessage>

                </FormControl>

                
                <HStack mt={10} margin={5} width="85%" justifyContent="space-between">

                <FormControl isInvalid={!!formik.errors.zipCode && formik.touched.zipCode}>
                <FormLabel htmlFor="zipCode" color="black">Zip Code</FormLabel>
                <Field as={Input} fontSize="sm" width="35vw" name="zipCode" id="zipCode" color="#495E57" placeholder="Zip Code" background="white"/>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning} />
                <ErrorMessage name="zipCode" />
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.city && formik.touched.city}>
                <FormLabel htmlFor="city" color="black">City</FormLabel>
                <Field as={Input} fontSize="sm" width="40vw" name="city" id="city" placeholder="City" color="#495E57" background="white"/>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning} />
                <ErrorMessage name="city"/>
                </FormErrorMessage>

                </FormControl>

                </HStack>
                
                
                <HStack  mt={10} margin={5} width="40%" justifyContent="space-between">
                <FormControl isInvalid={!!formik.errors.time && formik.touched.time}>
                  <FormLabel htmlFor="time" color="black">Time</FormLabel>
                  <InputGroup>
                  <InputLeftElement color="blue.600" children={<FontAwesomeIcon size="xl" icon={faClock}/>} />
                  <Field as={Select} width="40vw"  paddingLeft={8} name="time" id="time"  color="#495E57" background="white" fontSize="sm"  >
                        <option style={{fontStyle: "italic"}} selected value="" disabled>Select Time</option>
                        <optgroup label="Time 11am-2 pm">
                        <option>ASAP (25 MINS)</option>
                        <option>11:00 am</option>
                        <option>12:00 pm</option>
                        <option>2:00 pm</option>
                        </optgroup>

                        <optgroup label="Time 5-10 pm">
                        <option>5:00 pm</option>
                        <option>8:00 pm</option>
                        <option>9:00 pm</option>
                        <option>10:00 pm</option>
                        </optgroup>
                  </Field>
                  </InputGroup>
                  <FormErrorMessage>
                    <FontAwesomeIcon icon={faWarning} />
                  <ErrorMessage name="time"/>
                  </FormErrorMessage>
                </FormControl>


                <FormControl fontSize="sm" isInvalid={!!formik.errors.day && formik.touched.day}>
                  <FormLabel htmlFor="day">Day</FormLabel>
                  <Select as={DatePicker} name="day"  minDate={new Date()} dateFormat="dd-MM (EEE)" selected={formik.values.day} onChange={(day)=> formik.setFieldValue("day", day)} placeholderText="Select Day" width="40vw" fontSize="sm" id="day" color="#495E57" background="white" />
                  <FormErrorMessage>
                    <FontAwesomeIcon icon={faWarning}/>
                  <ErrorMessage name="day" />
                  </FormErrorMessage>
                </FormControl>

                </HStack>

                </Card>

                
                <Card mt= {5} background="#EDEFEE">
                <Heading as="h2" marginLeft={5} marginTop={5} color="black">Payment Information</Heading>

                <RadioGroup defaultValue="creditCard">

                <VStack alignItems="start" mt={10} margin={5}>
                <Heading as="h3" fontSize="xl">Credit Card</Heading>
                <HStack>
                <Radio name="payment" value="creditCard" onChange={(e)=> setPaypal(false)} id="creditCard"/>
                <FormLabel htmlFor="creditCard">Pay with credit card via Stripe</FormLabel>
                </HStack>
      {!paypal && <Text>Your credit card information is secure, and your card is not charged until after you've confirmed your order.</Text> }
                <HStack >
                <FontAwesomeIcon size="2xl" style={{border: "2px solid #495E57", padding:5, borderRadius:10}} color="#1a1f71" icon={faCcVisa}/>
                <FontAwesomeIcon size="2xl" style={{border: "2px solid #495E57", padding:5, borderRadius:10}} icon={Mastercard}/>
                <Image src={Mastercard} height="45px" style={{border: "2px solid #495E57", padding:5, borderRadius:10}}  />
                <Image src={Gpays} height="45px" width="52px" style={{border: "2px solid #495E57", padding:5, borderRadius:10, filter: "contrast(250%"}}  />


               
                </HStack>
                </VStack>


                <VStack alignItems="start" margin={5}>
                <Heading as="h3" fontSize="xl" >Paypal</Heading>
                <HStack>
                <Radio name="payment" value="paypal" onChange={(e)=> {
                  setPaypal(true)
                  setNotPay(true)
                }
                } id="paypal"/>
                <FormLabel htmlFor="paypal">Pay with your Paypal account</FormLabel>
                </HStack>
        {paypal && <Text>We welcome your Paypal payment. Your Paypal account will not be charged until after you've confirmed your order.</Text> }
                <HStack >
                <FontAwesomeIcon size="2xl" style={{border: "2px solid #495E57", padding:5, borderRadius:10}} color="#003087" icon={faPaypal}/>
               
                </HStack>
                </VStack>

                </RadioGroup>


  {paypal? <Box width="50vw"></Box>: <div>
                <HStack mt={10} margin={5} >
              
                <FormControl isInvalid={!!formik.errors.cardNumber && formik.touched.cardNumber}>
                <FormLabel htmlFor="cardNumber" color="black">Credit Card No.</FormLabel>
                <Field as={Input} fontSize="sm" width="40vw" name="cardNumber" id="cardNumber" color="#495E57" placeholder="Card number" background="white"/>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning} />
                <ErrorMessage name="cardNumber"/>
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.nameCard && formik.touched.nameCard}>
                <FormLabel htmlFor="nameCard" color="black">Name On Card</FormLabel>
                <Field as={Input} fontSize="sm" width="40vw" name="nameCard" id="nameCard" placeholder="Card name" color="#495E57" background="white"/>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning} />
                <ErrorMessage name="nameCard"/>
                </FormErrorMessage>

                </FormControl>

                </HStack>


                <HStack mt={10} margin={5} width="40%" justifyContent="space-between">

                <FormControl fontSize="sm" isInvalid={!!formik.errors.expMonth && formik.touched.expMonth}>
                <FormLabel htmlFor="expMonth" color="black">Expiration Date</FormLabel>
                <Select as={DatePicker} showMonthYearPicker dateFormat="MM" placeholderText="Select Month" selected={formik.values.expMonth} onChange={(expMonth)=> formik.setFieldValue("expMonth", expMonth)} fontSize="sm" name="expMonth" width="35vw" id="expMonth" color="#495E57" placeholder="Month" background="white"/>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning} />
                <ErrorMessage name="expMonth"/>
                </FormErrorMessage> 

                </FormControl>


                <FormControl mt={8} fontSize="sm" isInvalid={!!formik.errors.expYear && formik.touched.expYear}>
        
                <Select as={DatePicker} showYearPicker minDate={new Date()} dateFormat="yyyy" placeholderText="Select Year" selected={formik.values.expYear} onChange={(expYear)=> formik.setFieldValue("expYear", expYear)} name="expYear" width="25vw" id="expYear" placeHolder=" Select Year" color="#495E57" background="white"/>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning} />
                <ErrorMessage name="expYear"/>
                </FormErrorMessage>

                </FormControl>
            

                 <FormControl margin={5} ml={0} isInvalid={!!formik.errors.cvv && formik.touched.cvv}>
                <FormLabel htmlFor="cvv" color="black">CVV/CVC </FormLabel>
                <Field as={Input} fontSize="sm" width="15vw" name="cvv" id="cvv" color="#495E57" maxLength="3" placeholder="CVC" background="white"/>
                <FormErrorMessage>
                  <FontAwesomeIcon icon={faWarning} />
                <ErrorMessage name="cvv" />
                </FormErrorMessage>

                </FormControl>
                </HStack>

                
                </div> }
                </Card>


            
            </Box>



            <Box mt={20}>
            <Heading as= "h1" marginBottom={10} size="2xl" color="#F4CE14" >
            Your Cart
            </Heading>
            
          <Card background="#EDEFEE">
          <Heading as="h2" marginLeft={5} marginTop={5} color="black">Order Summary</Heading>

            <VStack  mt={10} margin={5} alignItems="start">
            
            {cart.map((item)=> {
             return <Box key={item.uuid}>
                <HStack justifyContent="space-between" width="105%" alignItems="start">
                <HStack alignItems="start"> 
                <Box overflow="hidden"> 
                <Image src={item.image} width={100} height={100} _hover={{transform: "scale(1.1)", transition: "transform 0.3s ease-in-out"}} />
                </Box>
                <VStack spacing={0} marginLeft={2} alignItems="start">
                <Heading as="h3" fontSize="xl">{item.name}</Heading>
                <Text>{item.descp1}</Text>
                <Text>{item.descp2}</Text>
                <Text fontSize="smaller">QTY. {item.qty}</Text>
                <Button size="sm" onClick={(e)=> removeFromCart(item.uuid, item.qty, item.name)} background="#F4CE14" borderRadius={10} fontWeight="bold">Remove</Button>
                </VStack>
                </HStack>
      
                <Heading as="h3" fontSize="xl">${item.price.toFixed(2)}</Heading>
                             
                </HStack>
                <Divider bg="blue.500" h={1} mt={8} />
              </Box>
                })}



              <HStack spacing={5}>
                    
                  <FormControl isInvalid= {!!formik.errors.discountCode && formik.touched.discountCode}>
                  <Field as={Input} name="discountCode" fontSize="xl"  color="#495E57" placeholder="Discount Code" background="white"/>
                  <FormErrorMessage>
                    <ErrorMessage name="discountCode" />
                  </FormErrorMessage>
                  </FormControl>
                
                  <Button type="submit" onClick={(e)=> setforDiscount(true)} borderRadius={10} colorScheme="blue" fontWeight="bold">Apply</Button>
                
              </HStack>


              <HStack mt={5} justifyContent="space-between" width="100%" >
                <Text>Subtotal</Text>
                <Heading as="h3" fontSize="xl">${orderTotal.toFixed(2)}</Heading>

              </HStack>

               <HStack justifyContent="space-between" width="100%" >
                <Text>Delivery fee</Text>
                <Heading as="h3" fontSize="xl">+${deliveryFee}</Heading>
              </HStack>

              <HStack justifyContent="space-between" width="100%" >
                <Text>State Tax</Text>
                <Heading as="h3" fontSize="xl">${stateTax.toFixed(2)}</Heading>
              </HStack>

              <HStack justifyContent="space-between" width="100%" >
                <Text>Local Tax</Text>
                <Heading as="h3" fontSize="xl">${localTax.toFixed(2)}</Heading>
              </HStack>

               <HStack justifyContent="space-between" width="100%" >
                <Text>Discount {disc === 0.70? "(70%)" : "(20%)"}</Text>
                <Heading as="h3" fontSize="xl" color="#EE9972">-${discountAmt.toFixed(2)}</Heading>
              </HStack>

              <Divider h={1} background="blue.500" />
              
              <HStack mt={4} justifyContent="space-between" width="100%" >
                <Heading as="h3" fontSize="xl" fontWeight="bold">Order Total</Heading>
                <Heading as="h2" fontSize="2xl" fontWeight="bold">${total.toFixed(2)}</Heading>

              </HStack>

              <Button type= "submit" onClick={(e)=> setNorm(true)} isLoading={norm && formik.isSubmitting} width="full" borderRadius={10} p={6} mt={10} isDisabled={progress === true} background="#F4CE14" fontWeight="bold">{paypal? "Check out with Paypal" : "Place Order"}</Button>

              <Text mt={4} fontSize="sm">Have questions? or Need help to complete your order?</Text>

              <HStack mt={-5} spacing={5}>
                <Box >
                  
                  <HStack cursor="pointer" >
                  <FontAwesomeIcon color="#EE9972" icon ={faMessage}/>
                    <Tooltip label="Message our bot" hasArrow arrowSize={15}>
                  <Text mt={4} fontWeight="bold" onClick={(e)=> setBot(true)} color="#EE9972" _hover={{color:"#F4CE14"}}>Live Chat</Text>
                    </Tooltip>
                  </HStack>
                
                </Box>

                <Box>
                  <HStack cursor="pointer" >
                  <FontAwesomeIcon color="#EE9972" icon ={faPhone}/>
                  <Link href="tel: (312) 593-2744" isExternal style={{textDecoration: "none"}}>
                    <Tooltip label="Call support team" hasArrow arrowSize={15}>
                  <Text mt={4} fontWeight="bold" color="#EE9972" _hover={{color:"#F4CE14"}}>Phone</Text>
                    </Tooltip>
                  </Link>
                  </HStack>
                </Box>

                <Box>
                  <HStack cursor="pointer">
                  <FontAwesomeIcon color="#EE9972" icon ={faEnvelope}/>
                  <Link href="mailto: info@littlelemon.com" isExternal style={{textDecoration: "none"}}>
                    <Tooltip label="Email our support team" hasArrow>
                  <Text mt={4} fontWeight="bold" color="#EE9972" _hover={{color:"#F4CE14"}}>Email </Text>
                    </Tooltip>

                  </Link>

                  </HStack>
                </Box>

              </HStack>
              
               
            </VStack>

          </Card>
          
          
            </Box>
                 

          </Container>


          </Form>
            
            
            )}
            </Formik>

            <AlertDialog
            isOpen={alertMessage}
            onClose={onClose}
            >

              <AlertDialogOverlay>
                <AlertDialogContent backgroundColor= "#FF8A65">
                  <AlertDialogHeader paddingTop={5} fontWeight="bold">
                    <AlertDialogCloseButton />
                   {paypal? "Oops!" : "FAILED TO PROCESS PAYMENT"}
                  </AlertDialogHeader>
                  <AlertDialogBody paddingBottom={5}>
                    {paypal? alertMessage: "Sorry, we encountered an error while processing payment. Additional Info: Your card was declined "}
                  </AlertDialogBody>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog> 


        {bot &&   <ChatbotCompo />}


          </Container>

        </div>
    )
}


export default CheckoutMob