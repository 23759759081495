import {Drawer, useBreakpointValue, DrawerOverlay, DrawerContent, DrawerBody, DrawerHeader, DrawerCloseButton} from '@chakra-ui/react'
import MenuDash from "./Menu4Dash"
import Profile from "./Profile"
import EmptyCart from "./EmptyCart"
import { useNavigate } from 'react-router-dom'
import {BeatLoader} from "react-spinners"
import app from './firebase'
import { getFirestore, doc, getDoc, setDoc,collection, addDoc, getDocs, deleteDoc } from 'firebase/firestore'
import { getDatabase, ref, onValue } from 'firebase/database'
import { getAuth, onAuthStateChanged } from 'firebase/auth'


//This Sample
import { Container,Button,Alert, AlertIcon, Slide,Image, Card,Box, HStack,Text, Heading, VStack, FormLabel, FormErrorMessage, Divider, Spacer } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {useState, useEffect} from "react"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { faEnvelope, faMessage, faPhone } from "@fortawesome/free-solid-svg-icons"


function Dashboard({profileSettings, setProfileSettings, avatarUrl, setAvatarUrl, name, setName, count, setCount, addToCart, isOpenShop, setIsOpenShop, cart, setCart}) {

let database = getDatabase(app)

let [online, setOnline] = useState()
let [isOpen2, setIsOpen2] = useState(false)
let [errorMessage, setErrorMessage] = useState(false)

  
let mobileView = useBreakpointValue({
  base:true,
  md: false
})



// This is to target the md or tablet/ipad view
let tabletView = useBreakpointValue({
  md: true,
  lg: false
})



let dataB = getFirestore(app)
let auth = getAuth(app)


    let[loading, setLoading] = useState(false)
    let navigate = useNavigate()

    let onCloseShop =()=> {
        setIsOpenShop(false)
      }


    // Here is a sample
    
  let orderTotal = cart.reduce((acc, item) =>acc + item.price, 0)
  //let orderTotal = 100
    let stateTax= 0.0625 * orderTotal
    let localTax= 0.02 * orderTotal
    let deliveryFee= orderTotal? 7.64 :0
    let [disc, setDisc] = useState(0.20)
    let discountAmt = disc * orderTotal
  
    let total = orderTotal + stateTax + localTax + deliveryFee - discountAmt
  //Discount
    
    function onSubmit(values, actions){
     values.discountCode ==="DISCOUNT24" &&  setDisc(0.70)
  
    }

  
    // This is another tunnel that receives item id's
  function removeFromCart(idReceived, qtyReceived){

  // uuid ensures no two items added to the cart have the same id
  let updatedCart = cart.filter(item=> item.uuid !== idReceived)
  setCart(updatedCart)
    
    //We want to find the index of a specific/correct item in the cart array by checking if the id
  //of the ttem in the cart matches the "idReceived" we want to remove

  // in other words, we're searching for the index of the item in the cart array that matches the "idReceived" we want to remove
 // let indexToRemove = cart.findIndex(item => item.id === idReceived)
  //if(indexToRemove !== -1){   //In javascript, the findIndex method returns -1 if no matching index is found. Mind you index starts from 0
  
   // let updatedCart = [...cart]  //Create a copy of the cart array because we don't want to affect/modify the original cart. This ensures any changes affect only the copy
    //updatedCart.splice(indexToRemove, 1)   //Remove one item at the found/specified index. The ".splice()" remove items in an array starting at the specified index which is the first argument. The second argument sprcifies the number of items to remove. If it was two it'll remove the specified index and the next two items following the specified index
    //setCart(updatedCart)   // Update the cart state
  //}
  
    if(count > 0){
      setCount(prevCount => prevCount  - qtyReceived)  
      }

  }


  useEffect((e)=>{
    
    let onAuthStateChangedListener = onAuthStateChanged(auth, async(currentUser)=>{
      if(currentUser){

        try{
          // We use collection because we want to retrieve multiple documents from the sub collection and doc is for a specific document
          let userDocumentRef = collection(dataB, "cartItems", auth.currentUser.uid, "userCart")
          let getUserDoc = await getDocs(userDocumentRef)
          // create an array to store the retrieve the userCart items 
          let fetchItems = []
          // we iterate again because data retrieved from firestore includes metadata about Timestamp, thats why we use .data() to get the actual data
          // We iterate through the documents to get each document in the userCart. Iterate means to execute a function for each item
          getUserDoc.forEach((item)=>{
            // We push/add each documents data (excluding metadata) into the fetchItems array
            // We could have just push directly but that wiil end up with an array containing each document plus metadata
            fetchItems.push(item.data())
          })


          setCart(fetchItems)
          console.log("Cart Items fetched successfully")
        }
        catch (error){
          console.error("Error fetching cart Items", error.message)
        }   
      }
      

      return()=>{
        onAuthStateChangedListener()
      }
  
    })
  }, [])


  useEffect((e)=>{
    
    setCount(cart.length)
  }, [cart])


  useEffect((e)=>{
    let connectionStatus = ref(database, ".info/connected")
    // onValue means keep an eye on the connection status if it changes let me know and give me a picture/snapshot
    let connectionStatusListener = onValue(connectionStatus, (snapshot)=>{
      setOnline(snapshot.val())
    })

  }, [])

  
  let checkOutButton = async(e)=>{
    
    setTimeout((e)=>{
      setLoading(false)
    },30000)

    if (online){

        setLoading(true)

    try{
        // We're using collection because we want to save multiple documents in the userCart sub collection and doc is for a specific document
      let userCollection = collection(dataB, "cartItems", auth.currentUser.uid, "userCart")
     
       // Lets delete any existing sub collection of userCart
       let getUserDoc = await getDocs(userCollection)
      
       getUserDoc.forEach(async(oldUserDoc)=>{
        // We use ref beacuse in firestore to delete a document the deleteDoc() expects 'DocumentReference' as an argument
        // The ref is a way of saying delete the document that the reference points to. You cant just use oldUserDoc without the reference
        await deleteDoc(oldUserDoc.ref)
       })

    // We're iterating/looping through each item in the cart array so that each item in the cart is saved to the Firestore collection one by one/ as an individual document            
    // Iterate means to exceute a function for each item
      cart.forEach( async(item)=>{    
        
        await addDoc(userCollection, item)
        console.log("Cart items is saved successfully")
        navigate("/checkout")
      })

    }
    catch (error){
      console.error("Error saving cart items:", error.message)
      setLoading(false)
    }
  }
  
  else{
    setIsOpen2(true)
    setErrorMessage(true)
    
    setTimeout(() => {
      setIsOpen2(false)
      setErrorMessage(false)
    }, 9000);
  }


  }

    
  return(
        <div>
            {profileSettings? <Profile avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl}/>
            :<MenuDash count={count} setCount={setCount} avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl} name={name} setName={setName}  addToCart={addToCart} isOpenShop={isOpenShop} setIsOpenShop={setIsOpenShop} />}
        

{/* maxWidth="40vw" */}
            <Drawer
        isOpen={isOpenShop}
        onClose={onCloseShop}>
          <DrawerOverlay>
            <DrawerContent  maxWidth={tabletView? "80vw" :(!mobileView? "40vw": "")}>
              <DrawerHeader fontWeight="bold"  bg="#EDEFEE" >
                Shopping Cart
                <DrawerCloseButton/>
              </DrawerHeader>
              <DrawerBody >
              {!orderTotal? <EmptyCart/> :

<Container p={5} minHeight="40vh" maxWidth="full" background="linear-gradient(to top, #495E57, white, #495E57 )" postion="relative" id="landing-section">

           
<Container mt={5}  paddingLeft={-10} paddingRight={-10} style={{display: "grid", gridTemplateColumns: "1fr"}}  alignItems="start">

<Box>
            <Heading marginBottom={10} as= "h1" size="2xl" color="#F4CE14" >
            My Cart
            </Heading>
            
            
          <Card background="#EDEFEE" mr={ mobileView && -5} ml={mobileView && -5}>
          <Heading as="h2" marginLeft={5} marginTop={5} color="black">Order Summary</Heading>

            <VStack mt={10} margin={5} alignItems="start">
            
            {cart.map((item)=> {
             return <Box key={item.uuid}>
                <HStack justifyContent="space-between" width="100%" alignItems="start">
                <HStack alignItems="start"> 
                <Box overflow="hidden"> 
                <Image src={item.image} width={ mobileView? 100: 160} height={mobileView? 100: 160} _hover={{transform: "scale(1.1)", transition: "transform 0.3s ease-in-out"}} />
                </Box>
                <VStack spacing={0} marginLeft={2} alignItems="start">
                <Heading as="h3" fontSize= "xl">{item.name}</Heading>
                <Text>{item.descp1}</Text>
                <Text>{item.descp2}</Text>
                <Text fontSize="smaller">QTY. {item.qty}</Text>
                <Button size="sm" onClick={(e)=> removeFromCart(item.uuid, item.qty)} background="#F4CE14" borderRadius={10} fontWeight="bold">Remove</Button>
                </VStack>
                </HStack>
      
                <Heading as="h3" fontSize="xl">${item.price.toFixed(2)}</Heading>
                             
                </HStack>
                <Divider bg="blue.500" h={1} mt={8} />
              </Box>
                })}




              <HStack mt={5} justifyContent="space-between" width="100%" >
                <Text>Subtotal</Text>
                <Heading as="h3" fontSize="xl">${orderTotal.toFixed(2)}</Heading>

              </HStack>

               <HStack justifyContent="space-between" width="100%" >
                <Text>Delivery fee</Text>
                <Heading as="h3" fontSize="xl">+${deliveryFee}</Heading>
              </HStack>

              <HStack justifyContent="space-between" width="100%" >
                <Text>State Tax</Text>
                <Heading as="h3" fontSize="xl">${stateTax.toFixed(2)}</Heading>
              </HStack>

              <HStack justifyContent="space-between" width="100%" >
                <Text>Local Tax</Text>
                <Heading as="h3" fontSize="xl">${localTax.toFixed(2)}</Heading>
              </HStack>

               <HStack justifyContent="space-between" width="100%" >
                <Text>Discount {disc === 0.70? "(70%)" : "(20%)"}</Text>
                <Heading as="h3" fontSize="xl" color="#EE9972">-${discountAmt.toFixed(2)}</Heading>
              </HStack>

              <Divider h={1} background="blue.500" />
              
              <HStack mt={4} justifyContent="space-between" width="100%" >
                <Heading as="h3" fontSize="xl" fontWeight="bold">Order Total</Heading>
                <Heading as="h2" fontSize="2xl" fontWeight="bold">${total.toFixed(2)}</Heading>

              </HStack>

              
              <Button type= "submit" isLoading={loading && true} spinner={<BeatLoader size={10} />} onClick={checkOutButton} width="full" borderRadius={10} p={6} mt={10} background="#F4CE14" fontWeight="bold">CheckOut</Button>
            

              <Text mt={4} fontSize="sm">Have questions? or Need help to complete your order?</Text>

              <HStack mt={-5} spacing={5}>
                <Box>
                  <HStack>
                  <FontAwesomeIcon color="#EE9972" icon ={faMessage}/>
                  <Text mt={4} fontWeight="bold" color="#EE9972">Live Chat</Text>
                  </HStack>
                </Box>

                <Box>
                  <HStack>
                  <FontAwesomeIcon color="#EE9972" icon ={faPhone}/>
                  <Text mt={4} fontWeight="bold" color="#EE9972">Phone</Text>
                  </HStack>
                </Box>

                <Box>
                  <HStack>
                  <FontAwesomeIcon color="#EE9972" icon ={faEnvelope}/>
                  <Text mt={4} fontWeight="bold" color="#EE9972">Email</Text>
                  </HStack>
                </Box>

              </HStack>
              
               
            </VStack>

            

          </Card>
          
          
            </Box>     

</Container>

</Container>}
             
              </DrawerBody>
              
        {errorMessage &&    
        <Slide in={isOpen2} direction="right" style={{marginTop: 120}} >
        <Alert status="error" marginBottom={5} mt={-10}>
                <AlertIcon />
            <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
            </Alert> 
            </Slide>}
            </DrawerContent>
            
          </DrawerOverlay>
        </Drawer>


            
        </div>
    )
}


export default Dashboard