import {Box, Text, Heading, HStack, VStack} from "@chakra-ui/react"
import { useBreakpointValue } from "@chakra-ui/react"


function PrivacyPolicy(){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })


  // This is to target md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  if (mobileView){
    return(
          <div>
            
            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={5} marginRight={5}  >
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="privacyPolicy">
                          Privacy Policy
                   </Heading><br/>

               
               <HStack spacing={40}>
                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                    

                    <Text fontWeight="bold" fontStyle="italic" color="white" fontSize="xl" mt={5}>
                    Little Lemon respects and protects the privacy of visitors to our website. 
                    The purpose of this privacy policy is to provide you with a description of the type of information 
                    we collect and how we use that information.

                    </Text>
                </Box>


            
                </HStack>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} mt={40} minHeight="40vh" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={5} marginRight={5}>
                  
               
                <Box>

                    <Text mt={-130} color="black" fontSize="xl" >
                    Effective Date: [14th September 2022]<br/><br/>
                   
                    Thank you for visiting Little Lemon, a restaurant located in Chicago, U.S. 
                    This Privacy Policy outlines how we collect, use, disclose, and protect your 
                    personal information when you interact with our website, online services, 
                    and in-person services.<br/><br/> 
                    </Text>

                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Information We Collect
                    </Heading>
                    <Heading as="h2" size="lg" mt={5}>Personal Information:</Heading>
                    </Box>
                   
                    <Text color="black" mt={5} fontSize="xl" >
                    <span style={{fontWeight: "bold"}}>Contact Information: </span>    
                    When you make a reservation or contact us, we may collect your name, email address, phone number, 
                    and other relevant contact details.<br/>
                    <span style={{fontWeight: "bold"}}>Payment Information: </span>    
                    If you make a purchase, we collect payment information, such as credit card details, 
                    to process your transaction securely.<br/><br/>
                    </Text>
 
                    
                    <Box >
                    <Heading as="h2" size="lg">Automatically Collected Information:</Heading>
                    </Box>
                   
                    <Text color="black" mt={5} fontSize="xl" >
                    <span style={{fontWeight: "bold"}}>Device Information: </span>    
                    We may collect information about the device you use to access our website, 
                    including IP address, browser type, and device identifiers.<br/>
                    <span style={{fontWeight: "bold"}}>Usage Information: </span>    
                    We gather information about your interactions with our website, 
                    such as pages visited, date and time of access, and referral pages.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    How We Use Your Information
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    <span style={{fontWeight: "bold"}}>Service Improvement: </span>    
                    We use collected information to enhance our services, customize user experience, 
                    and respond to customer preferences.<br/>
                    <span style={{fontWeight: "bold"}}>Transactions: </span>    
                    Personal and payment information is used to process reservations, 
                    purchases, and other transactions initiated by you.<br/>
                    <span style={{fontWeight: "bold"}}>Communication: </span>    
                    We may use your contact information to communicate with you about reservations, 
                    promotions, and important updates.<br/><br/>

                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Information Sharing
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We do not sell, trade, or otherwise transfer your personal information to 
                    external parties without your consent, except for the purpose of providing our services. 
                    We may share information with trusted third parties, such as payment processors, 
                    to facilitate transactions.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Cookies and Similar Technologies
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Our website uses cookies to enhance your browsing experience. You can adjust 
                    cookie preferences through your browser settings.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Security
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We prioritize the security of your personal information and employ industry-standard 
                    measures to protect it from unauthorized access, disclosure, or alteration.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Your Choices
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    You have the right to review, update, or delete your personal information. To do so, 
                    please contact us at [info@littlelemon.com].
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Third Party Websites
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Please be aware that when you click on links that take you to third party websites, you
                    will be subject to their respective privacy policies. While Little Lemon's supports the 
                    protection of your privacy on the internet, we cannot be responsible for the actions
                    of third parties.
                    </Text><br/>

                    
                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Changes to Privacy Policy
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We reserve the right to update this Privacy Policy periodically. Any changes will be reflected 
                    on this page with an updated effective date.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Contact Us
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    If you have any questions or concerns about our Privacy Policy, 
                    please contact us at:<br/><br/>

                    Little Lemon Restaurant<br/>
                    Address: 976 Pisa Ave, Chicago, IL, 60611<br/>
                    Email: info@littlelemon.com<br/>
                    Phone: (312) 593-2744

                    </Text>

                </Box>
      
              </VStack>

                   
            </Box>        

          </div>
    )
  }

  else{

    return(
        <div>

            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20} marginRight={30} paddingRight={tabletView? 0:20}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} >
                          Privacy Policy
                   </Heading><br/>

               
               <HStack spacing={40}>
                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                    

                    <Text fontWeight="bold" fontStyle="italic" color="white" fontSize="xl" mt={5}>
                    Little Lemon respects and protects the privacy of visitors to our website. 
                    The purpose of this privacy policy is to provide you with a description of the type of information 
                    we collect and how we use that information.

                    </Text>
                </Box>


            
                </HStack>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} mt={40} minHeight="40vh" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20}  marginRight={30} paddingRight={tabletView? 0:20}>
                  
               
                <Box>

                    <Text mt={-130} color="black" fontSize="xl" >
                    Effective Date: [14th September 2022]<br/><br/>
                   
                    Thank you for visiting Little Lemon, a restaurant located in Chicago, U.S. 
                    This Privacy Policy outlines how we collect, use, disclose, and protect your 
                    personal information when you interact with our website, online services, 
                    and in-person services.<br/><br/> 
                    </Text>

                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Information We Collect
                    </Heading>
                    <Heading as="h2" size="lg" mt={5}>Personal Information:</Heading>
                    </Box>
                   
                    <Text color="black" mt={5} fontSize="xl" >
                    <span style={{fontWeight: "bold"}}>Contact Information: </span>    
                    When you make a reservation or contact us, we may collect your name, email address, phone number, 
                    and other relevant contact details.<br/>
                    <span style={{fontWeight: "bold"}}>Payment Information: </span>    
                    If you make a purchase, we collect payment information, such as credit card details, 
                    to process your transaction securely.<br/><br/>
                    </Text>
 
                    
                    <Box >
                    <Heading as="h2" size="lg">Automatically Collected Information:</Heading>
                    </Box>
                   
                    <Text color="black" mt={5} fontSize="xl" >
                    <span style={{fontWeight: "bold"}}>Device Information: </span>    
                    We may collect information about the device you use to access our website, 
                    including IP address, browser type, and device identifiers.<br/>
                    <span style={{fontWeight: "bold"}}>Usage Information: </span>    
                    We gather information about your interactions with our website, 
                    such as pages visited, date and time of access, and referral pages.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    How We Use Your Information
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    <span style={{fontWeight: "bold"}}>Service Improvement: </span>    
                    We use collected information to enhance our services, customize user experience, 
                    and respond to customer preferences.<br/>
                    <span style={{fontWeight: "bold"}}>Transactions: </span>    
                    Personal and payment information is used to process reservations, 
                    purchases, and other transactions initiated by you.<br/>
                    <span style={{fontWeight: "bold"}}>Communication: </span>    
                    We may use your contact information to communicate with you about reservations, 
                    promotions, and important updates.<br/><br/>

                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Information Sharing
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We do not sell, trade, or otherwise transfer your personal information to 
                    external parties without your consent, except for the purpose of providing our services. 
                    We may share information with trusted third parties, such as payment processors, 
                    to facilitate transactions.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Cookies and Similar Technologies
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Our website uses cookies to enhance your browsing experience. You can adjust 
                    cookie preferences through your browser settings.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Security
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We prioritize the security of your personal information and employ industry-standard 
                    measures to protect it from unauthorized access, disclosure, or alteration.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Your Choices
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    You have the right to review, update, or delete your personal information. To do so, 
                    please contact us at [info@littlelemon.com].
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Third Party Websites
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Please be aware that when you click on links that take you to third party websites, you
                    will be subject to their respective privacy policies. While Little Lemon's supports the 
                    protection of your privacy on the internet, we cannot be responsible for the actions
                    of third parties.
                    </Text><br/>

                    
                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Changes to Privacy Policy
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We reserve the right to update this Privacy Policy periodically. Any changes will be reflected 
                    on this page with an updated effective date.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Contact Us
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    If you have any questions or concerns about our Privacy Policy, 
                    please contact us at:<br/><br/>

                    Little Lemon Restaurant<br/>
                    Address: 976 Pisa Ave, Chicago, IL, 60611<br/>
                    Email: info@littlelemon.com<br/>
                    Phone: (312) 593-2744

                    </Text>

                </Box>
      
              </VStack>

                   
            </Box>

        </div>
    )
  }
}

export default PrivacyPolicy