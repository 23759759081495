import ChatBot from 'react-simple-chatbot'
import { Button } from '@chakra-ui/react'
import React, {useState} from 'react'


function ChatbotCompo (){

  let [chatKey, setchatKey] = useState(0)

    const steps = [
        {
        id: 'starter',
        message: 'Hi! Do you need some help?',
        trigger: "answer"
        },
        {
          id: 'answer',
          options:[
            {value: 'yes', label: "Yes", trigger: 'mid'},
          ]
        },
        {
          id:'mid',
          message: "Great!",
          trigger: 'high'
        },
        {
          id: 'high',
          message: 'Send us a message and our bot will reply instantly',
          trigger: 'prelude'
        },
        {
          id:'prelude',
          options: [
            {value: 'okay', label: "Okay, let's proceed", trigger: "intro" }
          ]
        },

        {
          id: 'intro',
          message: "Welcome to Little Lemon! How can I assist you today?",
          trigger: "topics",
        },

        {
          id: "topics",
          options: [
            {value: "reservation", label: "'Make a reservation", trigger: 'reservation'},
            {value: 'events', label: "Upcoming events", trigger: 'events'},
            {value: 'contact', label: "Contact Information", trigger: "contact"},
            {value: "checkout", label: "Checkout Issues", trigger: "checkout"},
            {value: "location", label: "Find your Location", trigger: "location" },
            {value: "feedback", label: "Feedback Info", trigger: "feedback"},,
            {value: 'dietary', label: 'Dietary Preferences', trigger: "dietaryRequests"},
            {value: 'eventCatering', label: "Event Catering", trigger: 'eventCatering'},
            {value: 'guess', label: "Guess what", trigger: '1'}
          ]
        },

        {
          id: "reservation",
          message: "Great! To make a reservation, you can visit our website or give us a call at (312) 593-2744.",
          trigger: 'resetButton',
        },

        {
          id: "events",
          message: "Explore our upcoming events on our Events page. Join us for excitiing experiences and special occasions!",
          trigger: "resetButton"
        },

        {
          id: "location",
          message: "Our restaurant is located at 976 Pisa Ave, Chicago, IL, 60611. Feel free to use the map on our Contact page for directions.",
          trigger: "resetButton"
        },

        {
          id: "contact",
          message: "You can contact us at info@littlelemon.com or (312) 593-2744. Our team is here to assist you!",
          trigger: "resetButton"
        },

        {
          id: "checkout",
          message: "Are you having trouble completing your order? Here are some common solutions:",
          trigger: "checkoutOptions"
        },

        {
          id: "checkoutOptions",
          options: [
            {value: 'payment', label: 'Payment Issues', trigger: "paymentHelp"},
            {value: 'cart', label: 'Issues with Shopping Cart', trigger: 'cartHelp'},
            {value: 'discount', label: "Applying Discounts", trigger: 'discountHelp'},
            {value: 'contactSupport', label: 'Contact Support', trigger: 'contactSupport'}
          ]
        },

        {
          id: 'paymentHelp',
          message: "If you're facing payment issues, please ensure your payment details are entered correctly. If the problem persists, contact our support team at support@littlelemon.com.",
          trigger: "resetButton"
        },

        {
          id: 'cartHelp',
          message: 'Having trouble with your shopping cart? Make sure you have selected the items you want to purchase. If issues persist, try clearing your browser cache or contact our support team.',
          trigger: "resetButton"
        },

        {
          id: "discountHelp",
          message: 'To apply discounts, enter the promo code during the checkout process. If you encounter any problem, feel free to reach out to our support team for assisstance.',
          trigger: "resetButton"
        },

        {
          id: 'contactSupport',
          message: "If none of the above solutions work, please contact our support team at support@littlelemon.com or call (312) 593-2744. We are here to help!",
          trigger: "resetButton"
        },

        {
          id: "feedback",
          message: "We value your feedback! Let us know about your experience. How can we improve?",
          trigger: 'feedbackOptions'
        },

        {
          id: 'feedbackOptions',
          options: [
            {value: 'service', label: 'Service Feedback', trigger: 'serviceFeedback'},
            {value: 'menu', label: 'Menu Suggestions', trigger: 'menuSuggestions'},
            {value: 'general', label: 'General Feedback', trigger: 'generalFeedback'},
          ]
        },

        {
          id:'serviceFeedback',
          message: "We appreciate your input on our services. Please share your thoughts, and we'll strive to make your next visit even better!",
          trigger: 'resetButton'
        },

        {
          id: 'menuSuggestions',
          message: "Have ideas for our menu? We'd love to hear them! Share your suggestions, and we'll consider adding exciting new dishes.",
          trigger: 'resetButton'
        },

        {
          id: 'generalFeedback',
          message: "For general feedback or other enquiries, feel free to email at info@littlelemon.com or call (312) 593-2744. You input is valuable!",
          trigger: "resetButton"
        },

        {
          id: 'dietaryRequests',
          message: "We're happy to accomodate special dietary requests. How can we assist you with your dietary preferences?",
          trigger: 'dietaryOptions'
        },

        {
          id: 'dietaryOptions',
          options: [
            {value: 'allergies', label: 'Allergies', trigger: 'allergyInfo'},
            {value: 'vegetarian', label: 'Vegetarian Options', trigger: 'vegetarianInfo'},
            {value: 'vegan', label: 'Vegan Options', trigger: 'veganInfo'}
          ]
        },

        {
          id: 'vegetarianInfo',
          message: "We offer a variety of delicious vegetarian options. Explore our menu to discover flavourful dishes that suit your preferences!",
          trigger: 'resetButton'
        },

        {
          id: 'allergyInfo',
          message: "If you have allergies, please inform our staff when placing your order. We'll provide information on allergens present in our dishes.",
          trigger: 'resetButton'
        },

        {
          id: "veganInfo",
          message: "Our menu includes delightful vegan options. Check out our plant-based dishes crafted for a satisfying dining experience!",
          trigger: "resetButton"
        },

        {
          id: 'eventCatering',
          message: "Planning an event? We provide catering services for special occasions. How can we assist you with event catering?",
          trigger: "eventcateringOptions"
        },

        {
          id: "eventcateringOptions",
          options: [
            {value: 'menu', label: 'Catering Menu', trigger:'cateringMenu'},
            {value: 'customization', label: 'Customization Options', trigger: 'customizationInfo'},
            {value: 'contact', label: 'Contact Catering team', trigger: 'contactCatering'},
          ]
        },

        {
          id: 'cateringMenu',
          message: 'Explore our catering menu for a variety of options suitable for events. Find the perfect dishes to make your event memorable!',
          trigger: "resetButton"
        },

        {
          id: 'customizationInfo',
          message: "We offer customization options for catering orders. Let us know your preferences, and our catering team will work with you to create a tailored experience.",
          trigger: 'resetButton'
        },

        {
          id: 'contactCatering',
          message: 'For catering inquiries, please contact our catering team at info@littlelemon.com or call (312) 593-2744. We look forward to catering your special event!',
          trigger: 'resetButton'
        },

        { 
        id: '1', 
        message: 'What number am I thinking?', 
        trigger: "2",
        },
    

        {
          id: '2',
          options: [
            {value: '1', label: 'Number 1', trigger: '4'},
            {value: '2', label: 'Number 2', trigger: '3'},
            {value: '3', label: "Number 3", trigger: '3'},
          ],
        },
    
        {
          id: '3',
          message: 'Wrong Answer, please try again',
          trigger:'2',
        },
        
        {
          id: '4',
          message: "Awesome! You're a telepath!",
          trigger: 'resetButton'
        },

        {
          id: 'resetButton',
          component: <Button size="sm" onClick={(e)=> setchatKey(prevValue => prevValue +1)}>Start new chat</Button>,
          asMessage: true,
          end: true
        }
    
    ]

    return(
        <div>
            <ChatBot key={chatKey} opened={chatKey && true} floating={true} headerTitle="Chatbot TL" steps={steps}/>

        </div>
    )
}


export default ChatbotCompo