import { Heading, Box, Button, Text, InputGroup, Center, InputLeftElement, InputRightElement, Icon,HStack, VStack, Image, Input, Select, Option, Container, useBreakpointValue, FormControl, FormLabel, FormErrorMessage, 
AlertDialog, Radio, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, Textarea, InputLeftAddon } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik' ;
import * as Yup from 'yup'
import PhoneInput from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import 'react-phone-number-input/style.css'

import { faPhone, faCalendar, faWineGlass, faUser, faClock} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


import kphoto from "./kphoto.jpg"


 
  
function OrderOnline() {

let [isSubmittinglocal, setIsSubmittinglocal] = useState(false)

let [alertMessage, setAlertmessage] = useState("")

let navigate = useNavigate()


//Creating Form validation

let initialValues= {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message:"",
    check: ""
  }



  let validationSchema = Yup.object({
    firstname:Yup.string().required("🚫 First Name Required"),
    lastname: Yup.string().required("🚫 Last Name Required"),
    email: Yup.string().email("Invalid email format").required("🚫 Email Required"),
    phone: Yup.string().required("🚫 Phone Number Required"),
    message:Yup.string().required("Special request required").min(25, "Minimum characters is 25"),
    check: Yup.string().required("Please check this box if you want to proceed.")

  })


  function onSubmit (values, actions) {

    //setIsSubmittinglocal(true)
    
      setTimeout((e)=> {
        console.log("Form Data:", values)
        actions.setSubmitting(false)
        //setIsSubmittinglocal(false)
        actions.resetForm()
       //isSubmittinglocal === false? setAlertmessage("") : setAlertmessage(`Your Reservation has been confirmed ${values.firstname}, check your email`)
       setAlertmessage(`Your Reservation has been confirmed ${values.firstname}, check your email`)
       

        },2000) 


    }


   function onClose(){
    
    setAlertmessage("")
   } 


return(
    <div>

            {/*//Form Validation*/}
        <Formik
        onSubmit={onSubmit} 
        initialValues={initialValues}
        validationSchema={validationSchema}
        >
             {(formik) => (

            <Form>

    <Container p={5} minHeight="50vh" maxWidth="full" background="#495E57" style={{display: "grid", gridTemplateColumns: "1fr 1fr"}} paddingBottom={10}>
            <Box paddingTop={8}>
                 <VStack mt={4}>
                <label for="firstname" style={{color:"white", marginLeft:-310}}>⭐ First Name</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.firstname && formik.touched.firstname}>
                <Field as={Input} name="firstname" id="firstname" color="#495E57" background="white" width="30vw" fontSize="2xl" placeholder="First Name" />
    
                <FormErrorMessage>
                <ErrorMessage name="firstname" />
                </FormErrorMessage>
                </FormControl>
                    
                    </Box>
                </VStack>

                <VStack mt={4}>
                <label for="lastname" style={{color:"white", marginLeft:-310}}>⭐ Last Name</label>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.lastname && formik.touched.lastname}>
                <Field as={Input} color="#495E57" background="white" id="lastname" name="lastname" fontSize="2xl" width="30vw" placeholder="Last Name" />

                <FormErrorMessage>
                    <ErrorMessage name="lastname"/>
                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                </VStack>



                <VStack mt={8} spacing={8} style={{color:"white", marginLeft:-10}}>
                
                <HStack mt={4} spacing={40}>

                    <HStack spacing={8}>
                    <FontAwesomeIcon icon={faCalendar} onClick={(e)=> navigate("/reservation")} size="2xl" cursor="pointer" />
                    <Text>June, 10</Text>
                    </HStack>

                    <HStack spacing={8}>
                    <FontAwesomeIcon icon={faUser} size="2xl"/>
                    <Text>10 Diners</Text>
                    </HStack>

                </HStack>


                <HStack mt={4} spacing={40}>

                    <HStack spacing={8}>
                    <FontAwesomeIcon icon={faClock} size="2xl"/>
                    <Text>6:00 pm</Text>
                    </HStack>

                    <HStack spacing={8}>
                    <FontAwesomeIcon icon={faWineGlass} size="2xl"/>
                    <Text>Birthday</Text>
                    </HStack>

                </HStack>
                    <Box textAlign="center">
                    <Heading as="h2" fontSize="2xl">Outdoor seating</Heading>


                    <HStack spacing={4} mt={5}>
                    
                    <FormControl isInvalid={formik.errors.check && formik.touched.check}>
                    <Field type="checkbox" id="check" name="check"/>
                    <label style={{marginLeft: 10}} for="check">You agree to our friendly privacy policy</label>
                    <FormErrorMessage>
                    <ErrorMessage name="check"/>
                    </FormErrorMessage>
                    
                    </FormControl>
                    </HStack>
                    
                    </Box>


                </VStack>



            </Box> 
        


            <Box paddingTop={8}>
                <VStack mt={4}>
                <label for="email" style={{color:"white", marginLeft:-350}}>⭐ Email</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
                <Field as={Input} name="email" id="email" color="#495E57" background="white" width="30vw" fontSize="2xl" placeholder="you@company.com" />
    
                <FormErrorMessage>
                <ErrorMessage name="email" />
                </FormErrorMessage>
                </FormControl>
                    
                    </Box>
                </VStack>

                
                <VStack mt={4}>
                <label for="phone" style={{color:"white", marginLeft:-280}}>⭐ Phone Number</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.phone && formik.touched.phone}>
                <InputGroup>
                <InputLeftAddon children={<FontAwesomeIcon icon={faPhone}/>} />
                <Field as={PhoneInput} flags={flags} defaultCountry="US" selected={formik.values.phone} onChange={(phone) => formik.setFieldValue("phone", phone)} name="phone" id="phone" color="#495E57" background="white" style={{width:360, marginLeft: 5}} fontSize="2xl" placeholder="Phone Number" />
                </InputGroup>
                <FormErrorMessage>
                <ErrorMessage name="phone" />
                </FormErrorMessage>
                </FormControl>
                    
                    </Box>
                </VStack>

                
                <VStack mt={4}>
                    <label for="message" style={{color:"white", marginLeft:-270}}>⭐ Special Requests</label>
                        <Box >
                    
                    <FormControl isInvalid={!!formik.errors.message && formik.touched.message}>
                    <Field as={Textarea} minHeight={150} name="message" id="message" color="#495E57" background="white" fontSize="2xl" width="30vw" placeholder="Comment" />
                
                    <FormErrorMessage>
                    <ErrorMessage name="message"/>
                    </FormErrorMessage>
                    </FormControl>
                        </Box>
                </VStack>
            </Box>



</Container>


    <Container mt={10} alignItems="start" marginLeft={30} paddingLeft={20} minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap:30,  paddingLeft: 100, paddingRight: 150} }>  
        <Image src={kphoto} borderRadius={10}  objectFit="cover" />
        <Image src={kphoto} borderRadius={10} objectFit="cover"/>
        <Image src={kphoto} borderRadius={10} objectFit="cover"/>

    </Container>
    <Button type="submit" isLoading={formik.isSubmitting} borderRadius={10} p={6} paddingLeft={20} paddingRight={20} marginLeft={540} marginBottom={20} marginTop={-10} background="#F4CE14" fontWeight="bold">Confirm Reservation</Button><br/>
</Form>
    )}
</Formik>
   {/*validation ends here */}



   
   <AlertDialog
   isOpen={alertMessage}
   onClose={onClose}>


    <AlertDialogOverlay>
        <AlertDialogContent>
            <AlertDialogHeader color="#495E57">
                All Good!
                <AlertDialogCloseButton />
            </AlertDialogHeader>
            <AlertDialogBody fontSize="x-large" color="#495E57">
                {alertMessage}
            </AlertDialogBody>
        </AlertDialogContent>

    </AlertDialogOverlay>
   </AlertDialog> 
          
    
    </div>

    )

}

export default OrderOnline