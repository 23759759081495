import { faStar, faWarning, faWineGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {VStack, Avatar, AvatarGroup, Alert, Slide, Center,HStack, Heading, Button, Image, Container, Box, Text, ButtonGroup, FormLabel, FormHelperText, FormErrorMessage, FormControl, Input, AlertIcon} from "@chakra-ui/react"
import {Link} from 'react-router-dom' 
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter} from '@chakra-ui/react'
import {Stat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup} from '@chakra-ui/react'
import {Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, PopoverFooter} from "@chakra-ui/react"
import {Field, Form, Formik, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import app from "./firebase";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import {getDatabase, ref, onValue} from 'firebase/database'
import Countup from 'react-countup'
import React, {useState, useEffect} from 'react'
import Signin from "./Signin Mod";


import lt1 from "./Assets/lt1.jpg"
import lt2 from "./Assets/lt2.jpg"
import lt3 from "./Assets/lt3.jpg"
import lm1 from "./Assets/lm1.jpg"
import lm2 from "./Assets/lm2.jpg"


import customer1 from "./Assets/customer1.jpg"
import customer2 from "./Assets/customer2.jpg"
import customer4 from "./Assets/customer4.jpg"
import deli5 from "./Assets/deli5.jpg"
import deli3 from "./Assets/deli3.jpg"
import deli4 from "./Assets/deli4.jpg"
import deli2 from "./Assets/deli2.jpg"
import shak from "./Assets/shak.jpg"
import mezze from "./Assets/mezze.jpg"
import logoPng from "./Assets/logo.png"







function Page404Mob({selected, setSelected, dashboard, setDashboard, successfulSignin, setsuccessfulSignin}){

  let database = getDatabase(app)
  let [online, setOnline] = useState()

  let [day, setDay] = useState()
  let [hours, setHours] = useState()
  let [minutes, setMinutes] = useState()
  let [seconds, setSeconds] = useState()

  let [countEnd, setCountEnd] = useState(false)

  //In javascript when you substract a date from a date, you get the 
  //result in milliseconds by default unless you write additional codes.
  //Also we need everything in milliseconds because setInterval operates in milliseconds.
 
 let countdown = ()=>{
  
  let endDate= new Date("2024-06-15T23:59:59")
  let startDate = new Date()
  let timeDifference = endDate - startDate


  if(timeDifference > 0){
  let dayMilliseconds = 1000*60*60*24
  let hoursMilliseconds = 1000*60*60
  let minutesMilliseconds = 1000*60
  let secondsMilliseconds = 1000

  //We're now breaking everything down to their appropriate days, hours, minutes and seconds.
  setDay(Math.floor(timeDifference/dayMilliseconds))
  setHours(Math.floor((timeDifference% dayMilliseconds) / hoursMilliseconds))
  setMinutes(Math.floor((timeDifference% hoursMilliseconds) / minutesMilliseconds))
  setSeconds(Math.floor((timeDifference% minutesMilliseconds) / secondsMilliseconds))
  }

  else{
    setDay(0)
    setHours(0)
    setMinutes(0)
    setSeconds(0)
    setCountEnd(true)
  }
 }

 useEffect((e)=>{
 
  setInterval((e)=>{
    countdown()
  }, 1000)

  return ()=>{
    clearInterval()
  }
 }, [])
  
  


  
  let [modalOpen, setModalOpen] = useState(false)
  let initialRef = useState(null)
  

  let onclose= ()=>{
    setModalOpen(false)
  }

  let [isOpen, setIsOpen] = useState(false)
  let [isOpen2, setIsOpen2] = useState(false)
  let [errorMessage, setErrorMessage] = useState(false)


  let popOnClose = ()=>{
    setIsOpen(false)
  }
  
  let initialValues ={
    firstName: "",
    email: ""
  }

  let validationSchema = Yup.object({
    firstName: Yup.string().required("First name required"),
    email: Yup.string().email("Invalid email").required("Email required")
  })

  
  useEffect((e)=>{

    let connectionStatus = ref(database, ".info/connected")
    // onValue means keep an eye on the connection status if it changes let me know and give me a picture/snapshot
    let connectionStatusListener = onValue(connectionStatus, (snapshot)=>{
      // val() means i'm interested in only the javascript excluding metadata
      setOnline(snapshot.val())
    })
  }, [])
  
  
  let onSubmit = async(values, actions)=>{
    setTimeout((e)=>{
      actions.setSubmitting(false)
    },30000)

    let dataB= getFirestore(app)

    if (online){

    try{
      console.log("Form Data: values")
      await addDoc(collection(dataB, "Newsletter"), values)
      setAlert(true)
      actions.resetForm()
      setIsOpen(false)

    }

    catch(error){
      console.error("Error submitting form:", error.message)
    }
  }

  else{
    setIsOpen2(true)
    setErrorMessage(true)
  }

    setTimeout((e)=>{
      actions.setSubmitting(false)
    }, 2000)

    setTimeout((e)=>{
      setAlert(false)
      setErrorMessage(false)

    }, 9000)

  }


  let [alert, setAlert] = useState(false)
  
  let onClose = ()=>{
    setAlert(false)
  }
    
    
 
  return(
        <div>
            <Container p={5} minHeight="40vh" maxW="full" background="#495E57" postion="relative" id="landing-section">
          
          <VStack spacing={5} alignItems="start"  marginLeft={0} paddingLeft={0}  >
          
          <Box>
          <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
            Order Online
          </Heading>
            </Box>
          
            <Box mt={16}>
              <Heading as= "h1" size="2xl" width="95vw" color="#F4CE14" mt={-30}>
              <span style={{color:"transparent", WebkitTextStroke: "2px #F4CE14"}}> Best your</span> Breakfast!
              </Heading>
              <Heading as="h2" color="white">We deliver delicious<br/>& healthy food</Heading>
            </Box>

          <Text color="white" fontSize="xl">
            From egg and sausage scrambles to oh-so-fluffy
            pancakes, thses are the best breakfast recipes of the year.
            <Text mt={4}>
            Order online or book a table. We are always ready 
            to fulfill your demand. We provide your order within 
            a short time. Keep with us to take our delicious food. 
            </Text>
          </Text>

          <HStack width="100%" justifyContent="space-between" mt={80}>
          <Button onClick={(e)=> setModalOpen(true)} borderRadius={15} marginTop={1} marginBottom={5} background="#F4CE14" fontWeight="bold"> <FontAwesomeIcon icon={faShoppingCart}/> <span style={{paddingLeft: 10}}>Order Now</span></Button>
          
          {/*Remember to use link from react-router not fake link from @chakra */}
          <Link to="/reservation">
          <Button onClick={(e)=> setSelected("Reservation")} borderRadius={15} marginTop={1} marginBottom={5} background="#495E57" border="3px solid #F4CE14" color="#F4CE14" fontWeight="bold"  _hover={{color: "black", background: "#EDF2F7"}}> <FontAwesomeIcon icon={faWineGlass}/> <span style={{paddingLeft: 10}}>Reserve a table</span></Button>
          </Link>
          </HStack>
          
          <HStack width="100%" justifyContent="space-between" >
          <Box postion="relative">
          <Avatar size="lg" name="Brandon" src={customer4}/>
          <Avatar size="lg" position="absolute" left={40} transform="translate(-150%, 0.5%)" name="Maria" src={customer1}/>
          <Avatar size="lg" name="Anthony"  position="absolute" left={40} transform="translate(-80%, 0.5%)" src={customer2}/>
          </Box>

          <Box >
            <Heading as="h3" marginBottom={-1}  color="white" fontSize="lg" fontWeight="bold">Customer Review</Heading>
            <HStack>
            <FontAwesomeIcon color="#F4CE14" icon={faStar}/>
            <Text fontSize="lg" mt={4}  color="white" fontWeight="bold"> <Countup end={4.8} duration={5} decimals={1}/></Text>
            <Text color="white" fontSize="sm" mt={4}>(<Countup end={5} duration={5} suffix="k reviews"/>)</Text>
            </HStack>
          </Box>
          </HStack>
          
    
          </VStack>

          <HStack width="90%" justifyContent="space-between">
            <VStack mt={-670} alignItems="start">
          <Slider style={{width:"150px", transform: "translate(0.5%, 0.5%"}} fade autoplay arrows={false} autoplaySpeed={5000} speed={2000} >
          <div >
          <Image src={deli3} width={150} height={150} objectFit="cover" borderRadius="50%" border="5px solid white" />
          </div>

          <div >
          <Image src={deli5} width={150} height={150} objectFit="cover" borderRadius="50%" border="5px solid white" />
          </div>

          </Slider>

          <Slider style={{width:"150px", transform:"translate(0.5%, -30.5%)"}} fade autoplay arrows={false} autoplaySpeed={5000} speed={2000} >
          <div >
          <Image src={deli2} width={150} height={150} objectFit="cover" borderRadius="50%" border="5px solid white"/>
          </div>

          <div >
          <Image src={deli4} width={150} height={150} objectFit="cover" borderRadius="50%" border="5px solid white"/>
          </div>

          </Slider>
          </VStack>

          <Slider style={{width:"150px", position:"absolute", top:"600px", right:"0px", transform:"translate(-20.5%, 90.5%)"}} fade autoplay arrows={false} autoplaySpeed={5000} speed={2000} >
          <div >
          <Image src={lm1} width={150} height={150} objectFit="cover" borderRadius="50%"/>
          </div>

          <div >
          <Image src={lm2} width={150} height={150} objectFit="cover" borderRadius="50%" />
          </div>

          </Slider>

          
          </HStack> 
                    
        </Container>


        
        <Box alignItems="start" mt={20} mb={20} paddingRight={0} paddingLeft={0}>
        <VStack  >
        
        <HStack alignItems="start" width="90%" justifyContent="space-between">
        <Stat>
          <StatLabel fontSize="xl">Delivered</StatLabel>
          <StatNumber fontSize="2xl">
            <Countup end={345670} duration={10}/> 
          </StatNumber>
          
          <StatHelpText >
            <StatArrow type="increase" />
          <Countup end={23.36} decimals={2} suffix="%" duration={10}/>
          </StatHelpText>
        </Stat>

          <Stat >
            <StatLabel fontSize="xl" >Customers Served</StatLabel>
            <StatNumber fontSize="2xl">
              <Countup end={150} duration={10} />
            </StatNumber>
            <StatHelpText>
              <StatArrow type="decrease" />
              <Countup end={9.05} decimals={2} duration={10} suffix="%"/>
            </StatHelpText>
          </Stat>
          </HStack>

          <HStack alignItems="start" width="90%" justifyContent="space-between">
          <Stat >
            <StatLabel fontSize="xl" >Subscribers</StatLabel>
            <StatNumber fontSize="2xl">
              <Countup end={71887} duration={10} />
            </StatNumber>

            <StatHelpText>
              <StatArrow type="increase"/>
              <Countup end={18.31} decimals={2} duration={10} suffix="%" />
            </StatHelpText>
          </Stat>

          <Stat>
            <StatLabel fontSize="xl" width="45vw">Avg. Order Value</StatLabel>
            <StatNumber fontSize="2xl">
              <Countup end={20.50} decimals={2} prefix="$" duration={10} />
            </StatNumber>

            <StatHelpText>
              <StatArrow type="decrease"/>
              <Countup end={5} suffix="%" duration={10}/>
            </StatHelpText>
          </Stat>
          </HStack>


          </VStack>

          </Box>


        
        <Container mb={10} paddingBottom={50} maxW="full" background="#EDEFEE" borderRadius={15}>
         
          
          <Center>
          <Heading as="h2" marginTop={5} mb={5} paddingTop={8} >Save Big on Your Next Order</Heading>
          </Center>

          <Center>
          <Heading as="h2" margin={5}>{countEnd? "Our 20% OFF sale has ended" : "20% OFF sale ends"}</Heading>
          </Center>
          <Center>
            <HStack spacing={10}>

              <VStack>
                <Heading>{day}</Heading>
                <Text mt={-4}>Days</Text>
              </VStack>

              <VStack>
                <Heading>{hours}</Heading>
                <Text mt={-4}>Hours</Text>
              </VStack>
              
              <VStack>
                <Heading>{minutes}</Heading>
                <Text mt={-4}>Minutes</Text>
              </VStack>
              
              <VStack>
                <Heading>{seconds}</Heading>
                <Text mt={-4}>Seconds</Text>
              </VStack>

            </HStack>
          </Center>

 {countEnd?     <Center>
            <Text fontSize="2xl">There's still plenty to enjoy! Explore our delicious menu and discover more exciting offers. <br/> Subscribe to our newsletter <b>for future promotions</b> + exclusive access to new menu items</Text>
          </Center>
          :
          <Center>
            <Text fontSize="2xl">Subscribe to our newsletter and <b>get 20% off your first order</b> + exclusive access to new menu</Text>
          </Center> }
         

         <Popover
         isOpen={isOpen}
         onClose = {popOnClose}
         arrowSize={15}
         closeOnBlur={false}
         >


          <PopoverTrigger>
          <Center>
            <Button mt={5} mb={-2} width="full" onClick={(e)=> setIsOpen(true)} fontWeight="bold" bg="#F4CE14"  paddingLeft={10} paddingRight={10}>{countEnd? "Join Now for Future Offers": "Get My 20% Off"}</Button>
          </Center>
          </PopoverTrigger>

          <PopoverContent bg="blue.800" color="white" borderColor="blue.800">
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}>

                {(formik)=> (
                  <Form>
            <PopoverArrow bg="blue.800" />
            <PopoverHeader fontWeight="semibold" >
              <PopoverCloseButton />
             Subscribe to our newsletter
            </PopoverHeader>

            <PopoverBody>
             
                    <FormControl isInvalid={!!formik.errors.firstName && formik.touched.firstName}>
                      <FormLabel htmlFor="firstName">First Name</FormLabel>
                      <Field as={Input} name= "firstName" id="firstName"  color="black" bg="white" placeholder="First Name" />
                      <FormErrorMessage>
                      <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                      <ErrorMessage name="firstName" />
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={4}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Field as={Input} name= "email" id="email"  color="black" bg="white" placeholder="Email" />
                      <FormHelperText color="white">We'll never share your email</FormHelperText>
                      <FormErrorMessage>
                      <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                      <ErrorMessage name="email" />
                      </FormErrorMessage>
                    </FormControl>

            </PopoverBody>
            <PopoverFooter>
             <FormControl>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Button size="sm" onClick={(e)=> setIsOpen(false)} >Cancel</Button>
                <Button type="submit" size="sm" isLoading={formik.isSubmitting} colorScheme="green">Submit</Button>
              </ButtonGroup>
              <FormHelperText mt={5} color="white">By providing your email address, you agree to our<Link to="/privacyPolicy" style={{color: "#F4CE14",fontWeight:"bold", textDecoration: "underline"}}> Privacy Policy </Link> and terms </FormHelperText>

              </FormControl>

            </PopoverFooter>
  
            </Form>
            )}
          </Formik>
          </PopoverContent>
         </Popover>
          
          <Slide in={alert} direction="right" style={{marginTop: 120}} >
         <Alert status="success">
          <AlertIcon />
          You've subscribed to our newsletter successfully.
         </Alert>
         </Slide>


         {errorMessage &&    
        <Slide in={isOpen2} direction="right" style={{marginTop: 120}}>
        <Alert status="error" marginBottom={5} mt={-10}>
            <AlertIcon />
            <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
            </Alert> 
            </Slide>}
         
        </Container>

        
        <Container mt={10} mb={-40} minHeight="50vh" maxWidth="full" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap:5,  paddingLeft: 1, paddingRight: 1} }>  
        <Box overflow="hidden" borderRadius={10}>
        <Image src={lt1} width={150} height={150} borderRadius={10}  objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>
        <Box overflow="hidden" borderRadius={10}>
        <Image src={lt2} width={150} height={150} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>
        <Box overflow="hidden" borderRadius={10}>
        <Image src={lt3} width={150} height={150} borderRadius={10} objectFit="cover" _hover={{transform: "scale(1.1)"}} transition="transform 0.3s ease-in-out" />
        </Box>

        </Container>


        <Modal 
        isOpen={modalOpen}
        onClose={onclose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        >
          <ModalOverlay>
            <ModalContent>
            <ModalCloseButton />
              <ModalHeader fontWeight="bold" fontSize="2xl">
              <Center>
                <Image src={logoPng} width={200} />
                </Center>
                <Heading marginBottom={8} fontSize="2xl">Welcome! Log in to your account </Heading>

              </ModalHeader>

                <ModalBody> <Signin successfulSignin={successfulSignin} setsuccessfulSignin={setsuccessfulSignin} /> </ModalBody>
                <ModalFooter display="flex" justifyContent="flex-end">
                  <ButtonGroup >
                  {/*  <Button colorScheme="blue">Save</Button> */}
                    <Button onClick={(e)=> setModalOpen(false)}>Close</Button>
                  </ButtonGroup>
                </ModalFooter>
            </ModalContent>
          </ModalOverlay>

        </Modal>
        
        </div>
    )
  }




export default Page404Mob
