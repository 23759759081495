import {Box, Text, Center,Heading, HStack, VStack} from "@chakra-ui/react"
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useBreakpointValue } from "@chakra-ui/react"


function Inbox(){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })



  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })


  if (mobileView){
    return(
          <div>
            
            <Box p={5} minHeight="100vh" background="#495E57" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={5} marginRight={5}  >
 
                      
                  <Heading as= "h1" size="2xl" color="#F4CE14" paddingTop={10} id="inbox">
                          Inbox
                   </Heading><br/>

                 
              </VStack>

              <Box alignItems="start" mt={10} color="white">
              <Center>
                <VStack>
                <FontAwesomeIcon size="10x" icon={faEnvelopeOpen}/>
    
                <Heading as="h2" mt={10} fontSize="2xl">You don't currently have</Heading>
                <Heading as="h2" fontSize="2xl">any items in your inbox.</Heading>
                <Heading as="h2" fontSize="2xl">Check back in soon!</Heading>

                </VStack>
            </Center>
            </Box>
                 
            </Box>


          </div>
    )
  }

  else{

    return(
        <div>

            <Box p={5} minHeight="100vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0:20} marginRight={tabletView? 10:30} paddingRight={tabletView? 0:20}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} >
                          Inbox
                   </Heading><br/>

               
              </VStack>

              <Box alignItems="start" mt={10} color="white">
            <Center>
                <VStack>
                <FontAwesomeIcon size="10x" icon={faEnvelopeOpen}/>
    
                <Heading as="h2" mt={10} fontSize="2xl">You don't currently have</Heading>
                <Heading as="h2" fontSize="2xl">any items in your inbox.</Heading>
                <Heading as="h2" fontSize="2xl">Check back in soon!</Heading>

                </VStack>
            </Center>
            </Box>
                 
            </Box>


        </div>
    )
  }
}

export default Inbox