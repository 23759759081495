import { Heading, Card, Image, Tooltip, Box, Text, Container, HStack, VStack } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

import React, {useState} from 'react'
import crab from "./Assets/crab.jpg"
import spinach from "./Assets/spinach.jpg"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import mezze from "./Assets/mezze.jpg"
import paella from "./Assets/paella.jpg"
import Ratato from "./Assets/Ratato.jpg"
import risotto from "./Assets/risotto.jpg"
import shak from "./Assets/shak.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import dessert from "./Assets/dessert.jpg"



function Desserts(){

    let [hover1D, sethover1D] = useState(false)
    let [hover2D, sethover2D] = useState(false)
    let [hover3D, sethover3D] = useState(false)

    return(
        <div>
             <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={20}>
     
             <Box>
    
          <Heading mt={10} as="h2">Desserts</Heading>
             </Box>

        </VStack>

              {/*Cards Here */}
      
        <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 40, paddingLeft: 100, paddingRight: 100, paddingBottom: 50}}>
          
        <Card style={{boxShadow: hover1D && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1D(true)} onMouseLeave={(e)=> sethover1D(false)} overflow="hidden" borderRadius={10} cursor="pointer">
       <Image src={greeksalad} borderRadius={10} transform= {hover1D && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
       <Box p={8} background="#EDEFEE">
         <HStack justifyContent="space-between">
       <Heading as="h3" size="md">Greek Salad</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
         </HStack><br/>
       <Text paddingBottom={5} textAlign="left">The famous greek salad of crispy
       lettuce, peppers,  olives and our Chicago style chesse,
       garnished with crunchy garlic and rosemary
       croutone.
       </Text><br/>
       <HStack spacing={4}>
       <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
       <Heading as="h3" size="md" >Order a delivery</Heading>
       </Tooltip>
       <FontAwesomeIcon icon={faMotorcycle}/>
       </HStack>

       </Box>
     </Card>

     <Card overflow="hidden" onMouseEnter={(e)=> sethover2D(true)} onMouseLeave={(e)=> sethover2D(false)} style={{boxShadow: hover2D && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
       <Image src={bruschetta} borderRadius={10} height={238} transform={hover2D && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
       <Box p={8} background="#EDEFEE">
         <HStack spacing={40}>
       <Heading as="h3" size="md">Bruchetta</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$5.99</Text>
         </HStack><br/>
       <Text textAlign="left">Our Bruchetta is made from grilled
       bread that has been smeared with garlic and seasoned with salt and olive
       oil.
       </Text><br/>
       <HStack spacing={4} paddingTop={10} marginTop={1} >
       <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
       <Heading as="h3" size="md">Order a delivery</Heading>
       </Tooltip>

       <FontAwesomeIcon icon={faMotorcycle}/>
       </HStack>
       </Box>
     </Card>

     <Card overflow="hidden" onMouseEnter={(e)=> sethover3D(true)} onMouseLeave={(e)=> sethover3D(false)} style={{boxShadow: hover3D && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
       <Image src={dessert} height={238} borderRadius={10} objectFit="cover" transform={hover3D && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
       <Box p={8} background="#EDEFEE">
         <HStack spacing={100}>
       <Heading as="h3" size="md">Lemon Dessert</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
         </HStack><br/>
       <Text paddingBottom={5} textAlign="left">This comes straight from grandma's recipe book,
       every last ingredient has been sourced and is as authentic
       as can be imagined.
       </Text><br/>
       <HStack spacing={4}>
       <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
       <Heading as="h3" size="md">Order a delivery</Heading>
       </Tooltip>
       <FontAwesomeIcon icon={faMotorcycle}/>
       </HStack>

       </Box>
     </Card>
          

        </Container>

        {/*Cards end here*/}


        </div>
    )
} 

export default Desserts