import React, { useState, useEffect } from 'react';
import {Button, UnorderedList, ListItem, useBreakpointValue, ButtonGroup, Progress, Switch, useToast,Checkbox,Text, Skeleton, SkeletonCircle,Alert, AlertIcon ,Avatar, Heading, InputLeftAddon, InputGroup,FormLabel, VStack, HStack, Container, Box, Select, InputRightElement, InputLeftElement, Textarea, Modal,ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormHelperText, Slide} from '@chakra-ui/react'
import PhoneInput from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import 'react-phone-number-input/style.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Link, useNavigate} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faAngleDown, faEye, faEyeSlash, useBreakPointValue,faLocationPinLock, faPencil, faPhone, faWarning } from "@fortawesome/free-solid-svg-icons";
import app from './firebase';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import {getAuth, reauthenticateWithCredential, updatePassword,EmailAuthProvider,onAuthStateChanged, deleteUser } from "firebase/auth"
import {getDownloadURL, getStorage, uploadBytes, ref} from 'firebase/storage'
import {getDatabase, ref as dataref, onValue} from 'firebase/database'
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'
import {ref as yupRef} from 'yup'


import Loadspinner from './Loadspinner';
import ProfileMob from './Profile Mob';





const Profile = ({avatarUrl, setAvatarUrl}) => {

let database = getDatabase(app)

let [online, setOnline] = useState()
let [isOpen2, setIsOpen2] = useState(false)
let [errorMessage, setErrorMessage] = useState(false)


let mobileView = useBreakpointValue({
  base: true,
  md: false
})



// This is to target the md or tablet/ipad view
let tabletView = useBreakpointValue({
  md: true,
  lg: false
})




const [selectedImage, setSelectedImage] = useState(null)
//const [avatarUrl, setAvatarUrl] = useState("");
let [spinner, setspinner] = useState(true)
let [successMessage, setSuccessMessage] = useState(false)

let [skeleton, setSkeleton] = useState(true)
let [progress, setProgress] = useState(false)
let [uploadingProgress, setUploadingProgress] = useState(1)

const auth = getAuth(app)

const dataB = getFirestore(app) 

let navigate = useNavigate()
let toast = useToast()

//This is what will happen after the page refresh/ Leave page and get back

useEffect((e)=> {
  const fetchAvatarUrl = async() => {
  const userDocRef = doc(dataB, 'users', auth.currentUser.uid)
  const getUserDoc = await getDoc(userDocRef)

  getUserDoc.exists() &&
    //const userData = userDocSnap.data();
    setAvatarUrl(getUserDoc.data().avatarUrl || "") //But the avatarUrl property hasn't been created yet. That's why you do it during submit
    //Use the avartar URL if it exists, otherwise an empty string
    // The OR || is there just incase the user hasn't selected an image yet
    
  }

  setSkeleton(false)  

  return () => {
    fetchAvatarUrl();
  } 

},[auth.currentUser.uid, dataB, setAvatarUrl])


const handleImageUpload = async() => {
//This is what will happen in the moment or currently

  if (selectedImage){
    setProgress(true)
    setUploadingProgress(prevUploadProgress => prevUploadProgress+ 1 )

    const storage = getStorage(app)
    const storageRef= ref(storage, "user_avatars", auth.currentUser.uid)
    await uploadBytes(storageRef, selectedImage)
    setUploadingProgress(prevUploadProgress => prevUploadProgress + 30)
    const imageUrl = await getDownloadURL(storageRef) //This is where i'll get the download web address URL/link for the image
    setUploadingProgress(prevUploadProgress => prevUploadProgress + 40)

    //Update Firestore with the new avatar URL
    const userDocRef = doc(dataB, "users", auth.currentUser.uid)
    setUploadingProgress(prevUploadProgress => prevUploadProgress + 10)
    // avartarUrl property now has the web address url/link of the image
    await updateDoc(userDocRef, { avatarUrl: imageUrl })// updateDoc add fields to existing ones but doesn't overwrite it
    setUploadingProgress(prevUploadProgress => prevUploadProgress + 18)

    setAvatarUrl(imageUrl); 
    //At this point the selectedImage has finished, it's time to set it back to null
 
    //Clear the selectedImage state to allow for future uploads
    setSelectedImage(null);
    
    setUploadingProgress(prevUploadProgress => prevUploadProgress * 0)
    setProgress(false)

  
  }
}



let [address, setAddress] = useState("")
let [addressIsOpen, setAddressIsOpen] = useState(false)
let [passwordIsOpen, setPasswordIsOpen] = useState(false)
let [deleteAccountIsOpen, setDeleteAccountIsOpen] = useState(false)
let [TermsAndConditionsISOpen, setTermsAndConditionsISOpen] = useState(false)
let [visiblePass, setVisiblePass] = useState(false)
let [visiblePass2, setVisiblePass2] = useState(false)
let [addressMessage, setAddressMessage] = useState(false)
let [updatePasswordMessage, setUpdatePasswordMessage] = useState(false)
let [updatePasswordError, setUpdatePasswordError] = useState(false)



let onClose= ()=>{
  setAddressIsOpen(false)
}

let onClosePass = ()=>{
  setPasswordIsOpen(false)
}

let onCloseDeleteAccount = ()=>{
  setDeleteAccountIsOpen(false)
}

let onCloseTermsAndConditions = () =>{
  setTermsAndConditionsISOpen(false)
}

let onSubmit2 = async(values, actions)=>{

  setTimeout((e)=>{
    actions.setSubmitting(false)
  }, 30000)

  if (online){

  try{
   let userDocumentRef = doc(dataB, "users", auth.currentUser.uid)
   await updateDoc(userDocumentRef, {Apt: values.Apt, addressLoc: values.addressLoc, addressName: values.addressName})
   let getUserDoc = await getDoc(userDocumentRef)
   getUserDoc.exists() && setAddress( `<b>${getUserDoc.data().addressName}</b><br/><br/> ${getUserDoc.data().addressLoc}, ${getUserDoc.data().Apt}` )
   console.log("Address submitted")
   setAddressMessage(true)
   setAddressIsOpen(false)

  }

  catch(error){
    console.error("Error sending data:", error.message)
    //actions.setSubmitting(false)
  }
}

else{
  setIsOpen2(true)
  setErrorMessage(true)
  setAddressIsOpen(false)
  actions.setSubmitting(false) 
  setTimeout(() => {
    setIsOpen2(false)
    setErrorMessage(false)
  }, 9000)
}

  setTimeout((e)=>{
    actions.setSubmitting(false)
  },2000)

  setTimeout((e) => {
    setAddressMessage(false)
  }, 9000)

}

let [initialValues3, setInitialValues3] = useState({
  deleteAccount:""
})

let validationSchema3 = Yup.object({
  deleteAccount: Yup.string()
})

let onSubmitDeleteAccount = async(values, actions)=>{
 
  setTimeout(() => {
    actions.setSubmitting(false)
  }, 30000)

  if (online){
  
  // auth.curentUser always refer to the current Useer signed in. That's what we use to get the user object which contian details about the email, user name, uid, etc
  try{
    await deleteUser(auth.currentUser)
    navigate("/login")
    console.log("Account deleted successfully")
    toast({
      title: "Account Deleted!",
      description: "You were logged out",
      duration: 9000,
      status: "info",
      position: "top-right",
      isClosable: true
    })   
  }

  catch(error){
    console.error("There was an error deleting account", error.message)
    setUpdatePasswordError(true)
    setDeleteAccountIsOpen(false)
  }
}

else{
  setIsOpen2(true)
  setErrorMessage(true)
  actions.setSubmitting(false)
  setDeleteAccountIsOpen(false)
  
  setTimeout(() => {
    setIsOpen2(false)
    setErrorMessage(false)
  }, 9000)
}

  setTimeout((e)=>{
    actions.setSubmitting(false)
  }, 2000)
  
  setTimeout((e)=>{
    setUpdatePasswordError(false)
  }, 9000)
}


let initialValues2 = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: ""
}

let validationSchema2 = Yup.object({
  currentPassword: Yup.string().required("This is a required field"),
  newPassword: Yup.string().required("You must specify a password").min(8, "Password should be at least 8 characters long").matches(/[0-9]/, "Password should have at least one number").matches(/[!@#$%^&*_+]/, "Password should have at least one special character (ie ?,!,#,@)").matches(/[A-Z]/, "Password should have at least one upper case letter").matches(/[a-z]/, "Password should have at least one lower case letter"),
  confirmPassword: Yup.string().required("This is a required field").oneOf([yupRef("newPassword"), null], "The passwords do not match")
})

let onSubmitPass = async(values, actions)=>{

  setTimeout(() => {
    actions.setSubmitting(false)
  }, 30000)
  
  if (online){

  let auth = getAuth(app)
  // credential() is a method provided by EmailAuthProvider. EmailAuthProvider provides many methods
  let credential = EmailAuthProvider.credential(auth.currentUser.email, values.currentPassword)

  try{
    //reauthenticateWithCredential() is a method provided by auth.currentUser for reauthenticating users
    await reauthenticateWithCredential(auth.currentUser, credential)
    await updatePassword( auth.currentUser, values.newPassword)
    console.log("Password reset successfully")
    setUpdatePasswordMessage(true)
    setPasswordIsOpen(false)
  }

  catch(error){
    let user = auth.currentUser
    console.error("Error resetting password", error.message )
    console.log("User object:",user)
    setUpdatePasswordError(true)
    setPasswordIsOpen(false)
  }
}

else{
  setIsOpen2(true)
  setErrorMessage(true)
  actions.setSubmitting(false)
  setPasswordIsOpen(false)

  setTimeout(() => {
    setIsOpen2(false)
    setErrorMessage(false)
  }, 9000)
}

 setTimeout((e) => {
   actions.setSubmitting(false)
  },3000)

  setTimeout((e) => {
    setUpdatePasswordMessage(false)
    setUpdatePasswordError(false)
  }, 9000)
}





let [initialValues, setInitialValues] = useState({
  name: "",
  lastName:"",
  gender: "",
  birtmonth: "",
  birthday: "",
  allergies:"",
  dietary: "",
  email: "",
  addressLoc: "",
  Apt: "",
  addressName: "",
  phoneNumber: "",
  marketing: true,
  smsOrder: true,
  sms: true,
  terms: true
})


let validationSchema = Yup.object({
  name: Yup.string(),
  lastname: Yup.string(),
  gender: Yup.string(),
  birthmonth: Yup.string(),
  birthday: Yup.string(),
  allergies: Yup.string(),
  dietary: Yup.string(),
  email: Yup.string().email("Invalid email format"),
  
  addressLoc: Yup.string(),
  Apt: Yup.string(),
  addressName: Yup.string(),
  phoneNumber: Yup.string(),
  marketing: Yup.string(),
  smsOrder: Yup.string(),
  sms: Yup.string(),
  terms: Yup.string()
})


useEffect((e)=>{

  let connectionStatus = dataref(database, ".info/connected")
  //onValue means keep an eye on the connection status if it changes let me know and give me a picture/snapshot
  let connectionStatusListener = onValue(connectionStatus, (snapshot)=>{
    setOnline(snapshot.val())
  })

  return()=>{
    connectionStatusListener()
  }

}, [])


let onSubmit = async(values, actions) => {
  
  setTimeout(() => {
    actions.setSubmitting(false)
  }, 30000)
  
  
  if (online){
    // This is so i could add AvatarUrl to initialValues
    const storage = getStorage(app) 
    const storageRef= ref(storage, "user_avatars", auth.currentUser.uid)
    const imageUrl = await getDownloadURL(storageRef) //This is where i'll get the download web address URL/link for the image

  let userDocRef = doc(dataB, "users", auth.currentUser.uid)

  await setDoc(userDocRef, initialValues)
  await updateDoc(userDocRef, {avatarUrl: imageUrl})

  actions.setSubmitting(false)
  setSuccessMessage(true)

  setTimeout((e)=> {
    setSuccessMessage(false)
  }, 5000)
}

else{
  setIsOpen2(true)
  setErrorMessage(true)
  actions.setSubmitting(false)
  
  setTimeout(() => {
    setIsOpen2(false)
    setErrorMessage(false)
  }, 9000)
}

}


useEffect((e, values) => {
// onauthStateChanged takes in callback function as an argument
let authStateChangedListener = onAuthStateChanged(auth, async (currentUser) =>{
  
  if(currentUser){
    let userDocRef = doc(dataB, "users", currentUser.uid)
    let getUserDoc = await getDoc(userDocRef)

    getUserDoc.exists() && setInitialValues(getUserDoc.data())  
    setAddress( `<b>${getUserDoc.data().addressName }</b><br/><br/> ${getUserDoc.data().addressLoc}, ${getUserDoc.data().Apt }` )
          
    setspinner(false)
  }
  else{
    setspinner(false)

  }

})

return ()=> {
  authStateChangedListener()
}

}, [auth, dataB])



//{...initialValues} creates a copy of the existing properties of the initialValues
let handleChange = (e) => {
  setInitialValues({...initialValues, [e.target.name]: e.target.value})
}

let style ={
  background: "transparent",
  borderBottom: "3px solid white",
  outline: "none",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  color: "#F4CE14",
  borderRadius: 0
}

let style2 ={
  background: "transparent",
  borderBottom: "2px solid #18181b",
  outline: "none",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  color:"black",
  borderRadius: 0
}

  
if(mobileView){
  return(
    <div>
        <ProfileMob avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl}/>
    </div>
  )
}


else{



  if (spinner) {
  return(
    <div> <Loadspinner/></div>
  )
 }

  

  return (

    <div>
      <Container p={5} minHeight="50vh" maxWidth="full" background="#495E57"  paddingBottom={10}>
      <Box >

      <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0:20}>
                
                <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10}>
                        Settings
                 </Heading><br/>
                       
      </VStack>


      {/*Nothing should interfere with the 3F's ie formik, formik sub and form */}
      <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>

        {(formik) => (

          <Form>

            
          <Container p={5} minHeight="50vh" maxWidth="full" background="#495E57" style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap:tabletView? 50:300,  paddingLeft: tabletView? 20:100, paddingRight: tabletView? 20:100}}  paddingBottom={40} >
            
            <Box>

      {skeleton? <SkeletonCircle size="129" /> :
              <Avatar name={initialValues.name} marginBottom={5} size="2xl" src={selectedImage? URL.createObjectURL(selectedImage) : avatarUrl } /> }
              <input type="file" onChange={(e) => setSelectedImage(e.target.files[0])}  accept="image/*" />
              <Button onClick={handleImageUpload}>Upload Image</Button>
      {progress && <Progress hasStripe max={100} value={uploadingProgress} mt={5} colorScheme="blue" /> }

                 <VStack mt={10}>
                <label for="name" style={{color:"white", marginLeft:tabletView? -210:-310}}>⭐ First Name</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
                <Field as={Input} style={style} color="#495E57" background="white" name="name" id="name" value={initialValues.name} onChange={handleChange} placeholder="First Name" required width={ tabletView? "40vw":"30vw"} fontSize="2xl"  />
    
                <FormErrorMessage>
                <ErrorMessage name="name" />
                </FormErrorMessage>
                </FormControl>
                    
                    </Box>
                </VStack>

                <VStack mt={10}>
                <label for="Email" style={{color:"white", marginLeft: tabletView? -250:-350}}>⭐ Email</label>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.email && formik.touched.email}>
                <Field as={Input} style={style} color="#495E57" value={initialValues.email} onChange={handleChange} background="white" placeholder="your@email.com" required id="email" name="email" fontSize="2xl" width={tabletView? "40vw":"30vw"}  />
                <FormErrorMessage>
                    <ErrorMessage name="email"/>
                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                </VStack>


                <HStack>

                <VStack mt={10}>
                <label for="birthmonth" style={{color:"white", marginLeft: tabletView? -50:-105}}>Birth Month</label>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.birthmonth && formik.touched.birthmonth}>
                <Field as={Input} type="month" style={style} color="#495E57" name="birthmonth" value={initialValues.birthmonth} onChange={handleChange} id="birthmonth" background="transparent" fontSize="2xl" width={tabletView? "20vw":"15vw"}  />
                <FormErrorMessage>
                    <ErrorMessage name="birthmonth"/>
                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                </VStack>

                <VStack mt={10}>
                <label for="birthday" style={{color:"white"}}>Birth Day</label>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.birthday && formik.touched.birthday}>
                <Field as={Input} type="number" style={style} color="#495E57" value={initialValues.birthday} onChange={handleChange} background="white" id="birthday" name="birthday" placeholder="Birth Day" fontSize="2xl" width={tabletView? "20vw":"15vw"}  />
                <FormErrorMessage>
                    <ErrorMessage name="birthday"/>
                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                </VStack>
 
                </HStack>


                <VStack mt={10}>
                <label for="allergies" style={{color:"white", marginLeft: tabletView? -230:-310}}>Allergies</label>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.allergies && formik.touched.allergies}>
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon color='white' icon={faAngleDown}/>} />
                <Select color="#495E57" style={style} value={initialValues.allergies} onChange={handleChange} background="white" id="allergies" name="allergies" fontSize="2xl" width={tabletView? "40vw":"30vw"}>  
                  <optgroup style={{color:"#495E57" }} label="Allergies will not be used when preparing your order.">
                  <option style={{fontStyle:"italic"}} disabled value=""> Select an option</option>
                  <option >Lactose</option>
                  <option>Shellfish</option>
                  <option>Peanuts</option>
                  <option>Nuts</option>
                  <option>Soy</option>
                  <option>Wheat (Gluten)</option>
                  <option>Eggs</option>
                  <option>Fish</option>

                  </optgroup>

                </Select>
                </InputGroup>
                <FormErrorMessage>
                    <ErrorMessage name="allergies"/>
                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                </VStack>

                <Heading as="h2" mt={10} color="white">My Address</Heading>

                {/*We use dangerouslySetinnerHtML to convert a string thus html string into html*/}
                <div style={{position: "relative"}}>
                  <Box fontSize="2xl" mt={10} style={{minHeight: "150px", border: "2px solid #ccc", padding: 20, background: "white", borderRadius: 10}} contentEditable={false} 
                  dangerouslySetInnerHTML={{__html: `${address}`}}>

                  </Box>

                {address===""?   <Button borderRadius={20} position="absolute" top={20} left={tabletView? 8:20} transform="translate(50.5%, -50.5%)" _hover={{bg: "#18181b", color:"white"}} onClick={(e)=> setAddressIsOpen(true)}>Add Address</Button>
                  :<HStack width="5vw" cursor="pointer" onClick={(e)=> setAddressIsOpen(true)}  _hover={{color: "blue.600"}} postion="absolute"  transform="translate(480%, -350.5%)">
                    <Heading textDecoration="underline" as="h3" fontSize="2xl" >Edit</Heading>
                    <FontAwesomeIcon  icon={faPencil}/>
                  </HStack> }
                  
                </div>

                <Box>
                <Heading as="h2" mt={10} color="white">Password</Heading>
                <Text color="white" mt={5}  onClick={(e)=> setPasswordIsOpen(true)} cursor="pointer" _hover={{color: "#F4CE14"}} textDecoration="underline" width={tabletView? "20vw": "10vw"}>Change Password</Text>
                </Box>

                
                <Box>
                <Heading as="h2" mt={10} color="white">Delete Account</Heading>
                <Text color="white"cursor="pointer" onClick={(e)=> setDeleteAccountIsOpen(true)} _hover={{color: "#F4CE14"}} textDecoration="underline" width={tabletView? "20vw":"10vw"}>Delete My Account</Text>
                </Box>




                <Modal
                isOpen={addressIsOpen}
                onClose={onClose}>
                  <ModalOverlay>
                    <ModalContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit2}>

                          {(formik)=> (
                            <Form>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading>{address === ""? "Add address": "Modify address"}</Heading>
                      </ModalHeader>

                      <ModalBody>

                        
                        <FormControl isInvalid={!!formik.errors.addressLoc && formik.touched.addressLoc}>
                          <FormLabel htmlFor='addressLoc'>Address<span style={{marginLeft: 4}}>*</span></FormLabel>
                          <InputGroup>
                          <InputRightElement children={<FontAwesomeIcon icon={faLocationPinLock} />}/>
                          <Field as={Input} style={style2} name="addressLoc"id="addressLoc" value={initialValues.addressLoc} onChange={handleChange} required placeholder="Enter your address" />
                          </InputGroup>
                          <FormErrorMessage>
                            <FontAwesomeIcon style={{marginRight: 5}} icon={faWarning} />
                          <ErrorMessage name="addressLoc" />
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl mt={8} isInvalid={!!formik.errors.Apt && formik.touched.Apt}>
                          <FormLabel htmlFor='Apt'>APT/Suite</FormLabel>
                          <Field as={Input} style={style2} name="Apt" value={initialValues.Apt} onChange={handleChange} id="Apt" placeholder="APT/Suite" />
                          <FormErrorMessage>
                          <ErrorMessage name="Apt" />
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl mt={8} isInvalid={!!formik.errors.addressName && formik.touched.addressName}>
                          <FormLabel htmlFor='addressName'>Address Name<span style={{marginLeft: 4}}>*</span></FormLabel>
                        <Field as={Input} style={style2} name="addressName"id="addressName" value={initialValues.addressName} onChange={handleChange} required placeholder="e.g. Home, Apartment, etc" />
                          <FormErrorMessage>
                          <FontAwesomeIcon style={{marginRight: 5}} icon={faWarning} />
                          <ErrorMessage name="addressName" />
                          </FormErrorMessage>
                        </FormControl>


                      </ModalBody>
                      <ModalFooter>
                        <Button width="full" type="submit" isLoading={formik.isSubmitting} fontWeight="bold" borderRadius={20} background="blue.600" color="white">Save</Button>

                      </ModalFooter>
                          
                      </Form>
                          )}
                        </Formik>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                {/*This Modal is for Password*/}

                <Modal
                isOpen={passwordIsOpen}
                onClose={onClosePass}>
                  <ModalOverlay>
                    <ModalContent>
                    <Formik
                        initialValues={initialValues2}
                        validationSchema={validationSchema2}
                        onSubmit={onSubmitPass}>

                          {(formik)=> (
                            <Form>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading>Change Password</Heading>
                      </ModalHeader>

                      <ModalBody>

                        
                        <FormControl isInvalid={!!formik.errors.currentPassword && formik.touched.currentPassword}>
                          <FormLabel htmlFor='currentPassword'>Current Password<span style={{marginLeft: 4}}>*</span></FormLabel>
                          <InputGroup>
                          <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye}  onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer"/>}/>
                          <Field as={Input} type={visiblePass? "string": "password"} style={style2} name="currentPassword"id="currentPassworde" placeholder="Current password" />
                          </InputGroup>
                          <FormErrorMessage>
                            <FontAwesomeIcon style={{marginRight: 5}} icon={faWarning} />
                          <ErrorMessage name="currentPassword" />
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl mt={8} isInvalid={!!formik.errors.newPassword && formik.touched.newPassword}>
                          <FormLabel htmlFor='newPassword'>New Password<span style={{marginLeft: 4}}>*</span></FormLabel>
                          <InputGroup>
                          <InputRightElement children={<FontAwesomeIcon icon={visiblePass2? faEyeSlash: faEye} onClick={(e)=> setVisiblePass2(!visiblePass2)} cursor="pointer"/>}/>
                          <Field as={Input} type={visiblePass2? "string": "password"} style={style2} name="newPassword" id="newPassword" placeholder="8+ characters" />
                          </InputGroup>
                          <FormErrorMessage>
                          <ErrorMessage name="newPassword" />
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl mt={8} isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}>
                          <FormLabel htmlFor='confirmPassword'>Confirm Password<span style={{marginLeft: 4}}>*</span></FormLabel>
                        <Field as={Input} type="password" style={style2} name="confirmPassword" id="confirmPassword"placeholder="8+ characters" />
                          <FormErrorMessage>
                          <FontAwesomeIcon style={{marginRight: 5}} icon={faWarning} />
                          <ErrorMessage name="confirmPassword" />
                          </FormErrorMessage>
                        </FormControl>


                      </ModalBody>
                      <ModalFooter>
                        <Button width="full" type="submit" isLoading={formik.isSubmitting} fontWeight="bold" borderRadius={20} background="blue.600" color="white">Save</Button>

                      </ModalFooter>
                          
                      </Form>
                          )}
                        </Formik>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                {/*This Modal is for Delete Account*/}

                 <Modal
                isOpen={deleteAccountIsOpen}
                onClose={onCloseDeleteAccount}
                size="lg">
                  <ModalOverlay>
                    <ModalContent>
                    <Formik
                        initialValues={initialValues3}
                        validationSchema={validationSchema3}
                        onSubmit={onSubmitDeleteAccount}>

                          {(formik)=> (
                            <Form>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading>Are You Sure You Want to Delete Your Account?</Heading>
                      </ModalHeader>

                      <ModalBody>

                        <Text>
                          If you want to delete your acccount, type "DELETE" into the box below and then click the "Delete Account" button. This will
                          delete your account and all of the personal information associated with it.
                        </Text>
                        <Text fontStyle="italic">
                          Note: Your Loyalty Points associated with this account will not be able to be recovered. Physical Gift Cards are still able to be used. 
                          Digital Gift Cards will only work if you have the gift card number.
                        </Text>

                        
                        <FormControl isInvalid={!!formik.errors.deleteAccount && formik.touched.deleteAccount}>
                          <Field as={Input} style={style2} name="deleteAccount"id="deleteAcount" placeholder="Confirm by typing DELETE"  value={initialValues3.deleteAccount} onChange={(e)=> setInitialValues3({...initialValues3, [e.target.name]: e.target.value})} />
                          <FormErrorMessage>
                            <FontAwesomeIcon style={{marginRight: 5}} icon={faWarning} />
                          <ErrorMessage name="deleteAccount" />
                          </FormErrorMessage>
                        </FormControl>


                      </ModalBody>
                      <ModalFooter>
                        <ButtonGroup width="full">
                        <Button width="full" fontWeight="bold" borderRadius={20} variant="outline" background="transparent">Cancel</Button>
                        <Button width="full" type="submit" isLoading={formik.isSubmitting} fontWeight="bold" isDisabled={initialValues3.deleteAccount === "DELETE"? false : true} borderRadius={20} background="blue.600" color="white">Delete Account</Button>
                        </ButtonGroup>
 
                      </ModalFooter>
                          
                      </Form>
                          )}
                        </Formik>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>


  


                <HStack mt={10}>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.smsOrder && formik.touched.smsOrder}>
                <Field as={Switch} defaultChecked size="lg" isChecked={initialValues.smsOrder} onChange={(e)=> setInitialValues({...initialValues, [e.target.name]: e.target.checked})} transform="scale(1.2)" id="smsOrder" name="smsOrder"  />
                <FormErrorMessage>
                    <ErrorMessage name="smsOrder"/>
                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                    <label htmlFor="smsOrder" style={{color:"white", marginLeft:20}}>SMS ORDER NOTIFICATIONS</label>

                </HStack>

                <HStack mt={10}>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.sms && formik.touched.sms}>
                <Field as={Switch} defaultChecked size="lg"  isChecked={initialValues.sms} onChange={(e)=> setInitialValues({...initialValues, [e.target.name]: e.target.checked})} transform="scale(1.2)" id="sms" name="sms"  />
                <FormErrorMessage>
                    <ErrorMessage name="sms"/>
                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                    <label htmlFor="sms" style={{color:"white", marginLeft:20}}>SMS MARKETING NOTIFICATIONS</label>

                </HStack>

                
                <HStack mt={10}>
                    <Box fontSize="2xl">
                
                <FormControl isInvalid= {!!formik.errors.marketing && formik.touched.marketing}>
                
                <Field as={Switch} defaultChecked size="lg" isChecked={initialValues.marketing} transform="scale(1.2)" value={initialValues.marketing} onChange={(e)=> setInitialValues({...initialValues, [e.target.name]: e.target.checked})} id="marketing" name="marketing"   />
                
                <FormErrorMessage>
                    <ErrorMessage name="marketing"/>

                    </FormErrorMessage>
                </FormControl>
                
                    </Box>
                    <label htmlFor="marketing" style={{color:"white", marginLeft:20, width:tabletView && "33vw"}} >EMAIL MARKETING NOTIFICATIONS</label>

                </HStack>


                <HStack mt={10}>
                    <Box>
                <FormControl isInvalid= {!!formik.errors.terms && formik.touched.terms}>
                
                <Checkbox size="lg" defaultChecked color="white" required isChecked={initialValues.terms} onChange={(e)=> setInitialValues({...initialValues, [e.target.name]: e.target.checked})} id="terms" name="terms">I agree to the <Link onClick={(e)=> setTermsAndConditionsISOpen(true)} style={{color: "#F4CE14",fontWeight:"bold", textDecoration: "underline"}} >Terms and Conditions</Link></Checkbox>
                <FormErrorMessage>
                  <ErrorMessage name="terms"/>
                </FormErrorMessage>
                </FormControl>
                
                    </Box>

                </HStack>


            </Box> 
        


            <Box mt={227}>

                <VStack mt={8}>
                <label for="lastname" style={{color:"white", marginLeft: tabletView? -210:-310}}>⭐ Last Name</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.lastname && formik.touched.lastname}>
                <Field as={Input} style={style} name="lastname" id="lastname" value={initialValues.lastname} onChange={handleChange} color="#495E57" placeholder="Last Name" required background="white" width={tabletView? "40vw":"30vw"} fontSize="2xl"  />
    
                <FormErrorMessage>
                <ErrorMessage name="lastname" />
                </FormErrorMessage>
                </FormControl>
                    
                    </Box>
                </VStack>

                <VStack mt={10}>
                <label htmlFor="phoneNumber" style={{color:"white", marginLeft: tabletView? -170:-270}}>⭐ Phone Number</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.phoneNumber && formik.touched.phoneNumber}>
                  <InputGroup>
                  <InputLeftAddon children={<FontAwesomeIcon icon={faPhone} />} />
                  <Field type="tel" as={Input} style={style} name="phoneNumber" id="phoneNumber" value={initialValues.phoneNumber} onChange={handleChange} color="#495E57" placeholder="xxx-xxx-xxxx" required background="white" width={tabletView? "35vw":"26vw"} fontSize="2xl" />
                  
                  </InputGroup>
                  <FormHelperText color="white">For loyalty rewards</FormHelperText>
                <FormErrorMessage>
                <ErrorMessage name="phoneNumber" />
                </FormErrorMessage>
                </FormControl>
                    
                    </Box>
                </VStack>
               


                <VStack mt={10}>
                <label for="gender" style={{color:"white", marginLeft: tabletView? -250:-350}}>Gender</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.gender && formik.touched.gender} >
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon color='white' icon={faAngleDown}/>} />
                <Select name="gender" style={style} value={initialValues.gender} onChange={handleChange} id="gender" color="#495E57" background="white" width={tabletView? "40vw":"30vw"} fontSize="2xl"> 
                  <option style={{fontStyle:"italic"}} value="" disabled>Select an option</option>
                  <option value="male" style={{color:"#495E57" }}>Male</option>
                  <option value="female" style={{color:"#495E57" }}>Female</option>
                
                </Select>
                </InputGroup>
    
                <FormErrorMessage>
                <ErrorMessage name="gender" />
                </FormErrorMessage> 
                </FormControl>
                    
                    </Box>
                </VStack>


                
                <VStack mt={10}>
                <label for="dietary" style={{color:"white", marginLeft: tabletView? -160:-250}}>Dietary Preferences</label>
                    <Box fontSize="2xl" >
                
                <FormControl isInvalid={!!formik.errors.dietary && formik.touched.dietary} >
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon color='white' icon={faAngleDown}/>} />
                <Select name="dietary" style={style} value={initialValues.dietary} onChange={handleChange} id="dietary" color="#495E57" background="white" width={tabletView? "40vw":"30vw"} fontSize="2xl"> 
                  <optgroup style={{color:"#495E57" }} label="Let us know about your dietary preference.">
                  <option style={{fontStyle:"italic"}} value="" disabled>Select an option</option>
                  <option>Vegan</option>
                  <option>Vegetarian</option>
                  <option>Kosher</option>
                  <option>Pescatarian</option>
                  </optgroup>
                </Select>
                </InputGroup>
    
                <FormErrorMessage>
                <ErrorMessage name="dietary" />
                </FormErrorMessage> 
                </FormControl>
                    
                    </Box>
                </VStack>


                
         
            </Box>

          </Container>

            <Box marginTop={100} marginLeft={tabletView? 50:100}  marginRight={tabletView? 50:100}  marginBottom={50} >
            <Button type="submit" width="full" background="#F4CE14" fontWeight="bold" isLoading={formik.isSubmitting} borderRadius={20} p={6} marginBottom={20} marginTop={-10}>Save</Button> 
            </Box>


          </Form>

        )}

      </Formik>
      </Box>

      
        {successMessage &&
      <Slide in={successMessage} direction="right" style={{marginTop: 120}}>
      <Alert status="success" mt={4}> 
        <AlertIcon />
        Your information is saved successfully!
      </Alert>
      </Slide>}


       {addressMessage &&
      <Slide in={addressMessage} direction="right" style={{marginTop: 120}}>
      <Alert status="success" mt={4}> 
        <AlertIcon />
        Address Updated Successfully!
      </Alert>
      </Slide>}


      {updatePasswordMessage &&
        <Slide in={updatePasswordMessage} direction="right" style={{marginTop: 120}}>
        <Alert status="success" mt={4}> 
          <AlertIcon />
          Password Updated Successfully!
        </Alert>
        </Slide>}
      </Container>


      {updatePasswordError &&
        <Slide in={updatePasswordError} direction="right" style={{marginTop: 120}}>
        <Alert status="error" mt={4}> 
          <AlertIcon />
          Looks like we encountered a problem. Please wait a few minutes and try again. If the problem persists, please contact support@littlelemon.com
        </Alert>
        </Slide>}



        {/*This Modal is for Terms and Conditions */}

        <Modal
        isOpen={TermsAndConditionsISOpen}
        onClose={onCloseTermsAndConditions}
        scrollBehavior="inside"
        size="lg">
          <ModalOverlay>
            <ModalContent>
          
              <ModalHeader>
              <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                <Heading>Terms and Conditions</Heading>
              </ModalHeader>

              <ModalBody>
              <Text fontWeight="bold" fontStyle="italic" fontSize="xl" mt={5}>
                    These terms and conditions outline the rules and regulations for the use of our website.
                    These guidelines ensure clarity and fairness in your interaction with our platform. Thank you 
                    for choosing Little Lemon - we're excited to have you join us on this culinary journey!
              </Text>

               <Box>

                    <Text
                     color="black"  >
                    By accessing this website, we assume you accept these terms and conditions. 
                    Do not continue to use Little Lemon if you do not agree to take all of the 
                    terms and conditions stated on this page.<br/><br/> 

                    The following terminology applies to these Terms and Conditions, Privacy Statement 
                    and Disclaimer Notice and all agreements: "Client", "You" and "Your" refers to you, 
                    the person log on this website and compliant to Little Lemon’s terms and conditions. 
                    "Little Lemon", "Ourselves", "We", "Our" and "Us", refers to our restaurant. "Party", 
                    "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, 
                    acceptance, and consideration of payment necessary to undertake the process of our assistance 
                    to the Client in the most appropriate manner for the express purpose of meeting the Client’s 
                    needs in respect of provision of Little Lemon’s stated services, in accordance with and subject to, 
                    prevailing law of United States. Any use of the above terminology or other words in the singular, 
                    plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.

                    </Text>

                    <Box mt={8}>
                    <Heading as= "h2"  >
                    Cookies
                    </Heading>
                    </Box>
                   
                    <Text color="black" mt={5}  >
                    We employ the use of cookies. By accessing Little Lemon, you agree to use cookies in agreement with Little Lemon's 
                    Privacy Policy.<br/><br/>
                    </Text>
 
                    
                    <Box >
                    <Heading as= "h2"  >
                    License
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    Unless otherwise stated, Little Lemon and/or its licensors own the intellectual property 
                    rights for all material on Little Lemon. All intellectual property rights are reserved. 
                    You may access this from Little Lemon for your own personal use subjected to restrictions 
                    set in these terms and conditions.
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  >
                    You must not:
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    <UnorderedList>
                    <ListItem>Republish material from Little Lemon</ListItem>
                    <ListItem>Sell, rent or sub-license material from Little Lemon</ListItem>
                    <ListItem>Reproduce, duplicate or copy material from Little Lemon</ListItem>
                    <ListItem>Redistribute content from Little Lemon</ListItem>
                    </UnorderedList><br/>

                    This Agreement shall begin on the date hereof.<br/><br/>
                    Parts of this website offer an opportunity for users to post and exchange opinions and information 
                    in certain areas of the website. Little Lemon does not filter, edit, publish or review Comments prior
                    to their presence on the website. Comments do not reflect the views and opinions of Little Lemon, 
                    its agents, and/or affiliates. Comments reflect the views and opinions of the person who post their 
                    views and opinions. To the extent permitted by applicable laws, Little Lemon shall not be liable for 
                    the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use 
                    of and/or posting of and/or appearance of the Comments on this website.<br/><br/>

                    Little Lemon reserves the right to monitor all Comments and to remove any Comments which can be 
                    considered inappropriate, offensive or causes breach of these Terms and Conditions.<br/><br/>
                    You warrant and represent that:<br/><br/>

                    <UnorderedList>
                    <ListItem>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</ListItem>
                    <ListItem>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</ListItem>
                    <ListItem>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</ListItem>
                    <ListItem>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</ListItem>
                    </UnorderedList><br/>
                    You hereby grant Little Lemon a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce
                    and edit any of your Comments in any and all forms, formats or media.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Hyperlinking to our Content
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    The following organizations may link to our Website without prior written approval:<br/><br/>

                    <UnorderedList>
                    <ListItem>Government agencies</ListItem>
                    <ListItem>Search engines</ListItem>
                    <ListItem>News organizations</ListItem>
                    <ListItem>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses</ListItem>
                    <ListItem>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</ListItem>
                    </UnorderedList><br/>

                    These organizations may link to our home page, to publications or to other Website 
                    information so long as the link: (a) is not in any way deceptive; (b) does not falsely 
                    imply sponsorship, endorsement or approval of the linking party and its products and/or 
                    services; and (c) fits within the context of the linking party’s site.<br/><br/>

                    We may consider and approve other link requests from the following types of organizations:<br/><br/>
                    <ul>
                    <li>commonly-known consumer and/or business information sources</li>
                    <li>dot.com community sites</li>
                    <li>associations or other groups representing charities</li>
                    <li>online directory distributors</li>
                    <li>internet portals</li>
                    <li>accounting, law and consulting firms</li>
                    <li>educational institutions and trade associations.</li>

                    </ul><br/>
                    We will approve link requests from these organizations if we decide that: (a) the link would not make us 
                    look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative 
                    records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Little 
                    Lemon; and (d) the link is in the context of general resource information.<br/><br/>
                    These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does 
                    not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) 
                    fits within the context of the linking party’s site.<br/><br/>
                    If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you 
                    must inform us by sending an e-mail to Little Lemon. Please include your name, your organization name, contact 
                    information as well as the URL of your site, a list of any URLs from which you intend to link to our Website,
                    and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.<br/><br/>

                    Approved organizations may hyperlink to our Website as follows:<br/><br/>

                    <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                    </ul><br/>

                    No use of Little Lemon's logo or other artwork will be allowed for linking absent a trademark license agreement.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    iFrames
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the 
                    visual presentation or appearance of our Website.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Content Liability
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us 
                    against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted 
                    as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other 
                    violation of, any third-party rights.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Reservation of Rights
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    We reserve the right to request that you remove all links or any particular link to our Website. You approve to 
                    immediately remove all links to our Website upon request. We also reserve the right to amend these terms and 
                    conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and 
                    follow these linking terms and conditions.
                    </Text><br/>

                    
                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Removal of links from our website
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    If you find any link on our Website that is offensive for any reason, you are free to contact and inform us 
                    at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.<br/><br/>
                    We do not ensure that the information on this website is correct; we do not warrant its completeness or accuracy;
                    nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Disclaimer
                    </Heading>
                    </Box>

                    <Text color="black" mt={5}  >
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties, 
                    and conditions relating to our website and the use of this website. Nothing in this disclaimer will:<br/><br/>

                   <ul>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>

                   </ul><br/>
                   The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: 
                   (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, 
                   including liabilities arising in contract, in tort, and for breach of statutory duty.<br/><br/>
                   As long as the website and the information and services on the website are provided free of charge, 
                   we will not be liable for any loss or damage of any nature.

                    </Text>

                </Box>              

               
              </ModalBody>
              

            </ModalContent>
          </ModalOverlay>
        </Modal>


        {errorMessage &&    
        <Slide in={isOpen2} direction="right" style={{marginTop: 120}}>
        <Alert status="error" marginBottom={5} mt={-10}>
                <AlertIcon />
            <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
            </Alert> 
            </Slide>}


    </div>

 
  );
  }
};

export default Profile;
