import { Heading,Flex,Box, Center, Button, Text, HStack, VStack, Card, Image, Container, useBreakpointValue, Collapse, chakra, Avatar, position, Divider } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom' // Don't just use any Link, use the Link from react-router-Doms
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, {useState} from 'react'
import restaurant from "./Assets/restaurant.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import grilledFish from "./Assets/grilledFish.jpg"
import paella from "./Assets/paella.jpg"
import Ratato from "./Assets/Ratato.jpg"


import dessert from "./Assets/dessert.jpg"
import spinach from "./Assets/spinach.webp"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import mezze from "./Assets/mezze.jpg"
import risotto from "./Assets/risotto.jpg"
import shak from "./Assets/shak.jpg"

import kphoto from "./kphoto.jpg"
import Mains from "./Mains"
import Appetizers from "./Appetizers"
import Desserts from "./Desserts";




function MainMob(){

   let tabletView = useBreakpointValue({
      md: true
   })


    let [collapse, setCollapse] = useState(true)
    let [collapse2, setCollapse2] = useState(true)
    let [collapse3, setCollapse3] = useState(true)
    let [collapse4, setCollapse4] = useState(true)
    let [collapse5, setCollapse5] = useState(true)
    let [collapse6, setCollapse6] = useState(true)
    let [collapse7, setCollapse7] = useState(true)



  
    
    let [hover, sethover] = useState(false)
    let [hover2, sethover2] = useState(false)
    let [hover3, sethover3] = useState(false)
    let [hover4, sethover4] = useState(false)
    let [hover5, sethover5] = useState(false)
    let [hover6, sethover6] = useState(false)
  
    let [mains, setMains ] = useState(false)
    let [appetizers, setAppetizers] = useState(false)
    let [desserts, setDesserts] = useState(false)
    let [carte, setCarte] = useState(true)
    
    
    return(
        <div>
             <Container maxWidth={tabletView && "full"} style={{overflowY: "auto" }} height="60vh"> 

         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse? (tabletView? 20: 0): 8}}>Couscous</h3>
         <Flex overflow="hidden" >
            <Text fontSize={tabletView && "2xl"} flex="1">Dive into a plate of our exquisite Couscous,
            infused with aromatic herbs and<Collapse in={!collapse}><Box paddingBottom={8}> spices that transports you 
            to the shores of the Mediterranean.</Box></Collapse>
            </Text>
            
            <Image src={Couscous} width={tabletView? 200: 100} height={tabletView? 200:100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>
              
         </Flex>
         <Text fontSize={tabletView && "2xl"} marginTop={collapse? (tabletView? "-20": "-3") : "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse(!collapse)}> 
          {collapse? "See More": "See Less"} {collapse? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse(!collapse)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"} marginTop="-3" fontWeight="bold">$10.99</Text>
         
         </VStack>
 

         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse2? (tabletView? -29: 0): 8}}>Mezze</h3>
         <HStack overflow="hidden">
            <Text fontSize={tabletView && "2xl"}>Embark on an adventure with our Mezze platter,
             an array of small bites that<Collapse in={!collapse2}><Box paddingBottom={8}> dance on your taste buds.
            Each dip and bite tell a story of flavors.</Box></Collapse>
            </Text>

            <Image src={mezze} width={tabletView?200: 100} height={tabletView? 200: 100}  _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>
         
         </HStack>
         <Text fontSize={tabletView && "2xl"} marginTop={collapse2? (tabletView? "-10": "-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse2(!collapse2)}> 
           {collapse2? "See More" : "See Less"} {collapse2? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse2(!collapse2)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse2)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"} marginTop="-3" fontWeight="bold">$10.99</Text>
         </VStack>


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse3?(tabletView? -10: 0) : 8}}>Paella</h3>
         <HStack overflow="hidden">
            <Text  fontSize={tabletView && "2xl"}>An authentic Spanish masterpiece that brings the vibrant spirit of Valencia to
            your table. Each spoon is<Collapse in={!collapse3}><Box paddingBottom={8}> a blend of saffron-infused rice,
            seafood and spices.</Box></Collapse>
            </Text>

            <Image src={paella} width={tabletView? 200: 100} height={tabletView? 200: 100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </HStack>
         <Text fontSize={tabletView && "2xl"} marginTop={collapse3? (tabletView? "-10":"-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse3(!collapse3)}> 
           {collapse3? "See More" : "See Less"} {collapse3? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse3(!collapse3)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse3(!collapse3)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"} marginTop="-3" fontWeight="bold">$14.99</Text>
         </VStack>


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse4? (tabletView? -20: 0) : 8}}>Ratatouille</h3>
         <HStack overflow="hidden">
            <Text fontSize={tabletView && "2xl"}>A vegetable medly that's as visually stunning
            as it's dekicious. Layers of <Collapse in={!collapse4}><Box paddingBottom={8}> perfectly-roasted vegetabkes, bathed
            in a tomato based sauce.</Box></Collapse>
            </Text>

            <Image src={Ratato} width={tabletView? 200: 100} height={tabletView? 200: 100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </HStack>
         <Text fontSize={tabletView && "2xl"} marginTop={collapse4? (tabletView? "-10": "-3") : "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse4(!collapse4)}> 
           {collapse4? "See More" : "See Less"} {collapse4? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse4(!collapse4)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse4(!collapse4)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"} marginTop="-3" fontWeight="bold">$11.99</Text>


         </VStack>    


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse5? (tabletView? -10: 0): 8}}>Risotto</h3>
         <HStack overflow="hidden">
            <Text fontSize={tabletView && "2xl"} >Wrap yourself in the embrace of our creamy italian classic 
            that takes you on a journey through<Collapse in={!collapse5}><Box paddingBottom={8}> Northern
            Italy. Arborio rice-slow cooked to perfection. </Box></Collapse>
            </Text>

            <Image src={risotto} width={tabletView? 200: 100} height={tabletView? 200: 100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </HStack>
         <Text fontSize={tabletView && "2xl"} marginTop={collapse5? (tabletView? "-10": "-3") : "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse5(!collapse5)}> 
           {collapse5? "See More" : "See Less"} {collapse5? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse5(!collapse5)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse5(!collapse5)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"} marginTop="-3" fontWeight="bold">$12.99</Text>
         </VStack>


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse6? (tabletView? 20: 0): 8}}>Shakshuka</h3>
         <Flex overflow="hidden" fontSize={tabletView && "2xl"}>
            <Text flex="1" fontSize={tabletView && "2xl"}>Energize your day with our North-African 
            delight that's a burst of flavours. Poached<Collapse in={!collapse6}><Box paddingBottom={8}> eggs in a tomato and
            pepper stew create a breakfast experience. </Box></Collapse>
            </Text>

            <Image src={shak} width={tabletView? 200: 100} height={tabletView? 200: 100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </Flex>
         <Text fontSize={tabletView && "2xl"} marginTop={collapse6? (tabletView? "-20": "-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse6(!collapse6)}> 
           {collapse6? "See More" : "See Less"} {collapse6? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse6(!collapse6)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse6(!collapse6)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"} marginTop="-3" fontWeight="bold">$12.99</Text>
         </VStack>


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse7? (tabletView? 20: 0) : 8}}>Grilled Fish</h3>
         <Flex overflow="hidden">
            <Text flex="1" fontSize={tabletView && "2xl"}>Our Grilled Fish adorned with a harmonious blend of herbs and spices, 
            is expertly grillled to achieve a<Collapse in={!collapse7}><Box paddingBottom={8}>succulent and flaky texture.
            The addition of citrusy notes from lemon and lime enhances the dish, providing a refreshing and vibrant touch.</Box></Collapse>
            </Text>

            <Image src={grilledFish} width={tabletView? 200: 100} height={tabletView? 200: 100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </Flex>
         <Text fontSize={tabletView && "2xl"} marginTop={collapse7? (tabletView? "-20": "-3") : "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse7(!collapse7)}> 
           {collapse7? "See More" : "See Less"} {collapse7? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse7(!collapse7)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse7(!collapse7)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"} marginTop="-3" fontWeight="bold">$6.00</Text>
         </VStack>    
        
    </Container>

        </div>
    )
}

export default MainMob