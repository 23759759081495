import {Box, Flex,Text,Center,Heading, Input, InputGroup, InputRightElement,FormLabel, FormControl, FormErrorMessage,HStack, Button,VStack, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, CircularProgress, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogCloseButton} from "@chakra-ui/react"
import { faArrowLeft, faEnvelopeOpen, faHistory, faQrcode, faTag } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Formik, formik, Field, Form,ErrorMessage} from 'formik'
import * as Yup from 'yup'
import QRCode from 'qrcode.react'

import { useBreakpointValue } from "@chakra-ui/react"
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons"
import { useState, useEffect } from "react"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import { getFirestore, doc, getDoc } from "firebase/firestore"
import app from "./firebase"


function Orders({loyalty, setLoyalty, loyaltyCard, setLoyaltyCard, promoCode, setPromoCode}){

  let [history, setHistory] = useState(false)
  //let [loyaltyCard, setLoyaltyCard] = useState(false)
  let [userName, setUsername] = useState("")
  let [lastName, setLastName] = useState("")
  let [dialogIsOpen, setDialogIsOpen] = useState(false)
  
  let [initialValues, setInitialValues] = useState({
    promo: ""
  })

  let validationSchema = Yup.object({
    promo: Yup.string()
  })

  let onSubmitPromo = (values, actions)=>{
    
    setTimeout((e)=>{
      actions.setSubmitting(false)
      setPromoCode(false)
      setDialogIsOpen(true)

    }, 2000)  
  }


  let qrData= "You've been awarded with 100 loyal points!"

  let auth = getAuth(app)
  let dataB =getFirestore(app)
  let user = auth.currentUser

 
  useEffect((e)=>{
    
    let onAuthStateChangListener = onAuthStateChanged(auth, async(currentUser)=>{

      if(currentUser){
    //let auth = getAuth(app)
    
    let userDocumentRef = doc(dataB, "users", auth.currentUser.uid)
    let getUserDoc = await getDoc(userDocumentRef)

    getUserDoc.exists() && setUsername(getUserDoc.data().name)
    getUserDoc.exists() && setLastName(getUserDoc.data().lastname)
    console.log("Loyalty Card fetched successfully")
      }
    })
  
  return ()=>{
    onAuthStateChangListener()
  }

  }, [auth.currentUser.uid])


  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })


  
  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  let onCloseloyalty =()=>{
    setLoyalty(false)
  }

  let onCloseHistory =()=>{
    setHistory(false)
  }

  let onCloseLoyaltyCard = ()=>{
    setLoyaltyCard(false)
  }

  let onClosePromoCode =()=>{
    setPromoCode(false)
  }

  let onCloseDialog = ()=>{
    setDialogIsOpen(false)
  }

  let style2 ={
    background: "transparent",
    borderBottom: "2px solid #18181b",
    outline: "none",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    color:"black",
    borderRadius: 0
  }



  if (mobileView){
    return(
          <div>
            
            <Box p={5} minHeight="100vh" background="#495E57" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={5} marginRight={5}  >
 
                      
                  <Heading as= "h1" size="2xl" color="#F4CE14" paddingTop={10} id="inbox">
                        Order History
                   </Heading><br/>

                   
                  <Text fontWeight="bold" color="white" fontSize="xl" mt={5}>
                    There are no any recent orders

                  </Text>

                 
              </VStack>

              <Box alignItems="start" mt={10} color="white">
              <Center>
              <VStack >
                <FontAwesomeIcon size="10x" icon={faHistory}/>
                   
              </VStack>
            </Center>
            </Box>
                 
            </Box>



            
              {/*This Modal is for Loyalty*/}

              <Modal
              isOpen={loyalty}
              onClose={onCloseloyalty}
              size="lg">
                  <ModalOverlay>
                    <ModalContent>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading textAlign="center" size="lg">Standard</Heading>
                    
                      </ModalHeader>

                      <ModalBody> 
                        <Heading pt={2} pb={2} borderRadius={10} textAlign="center" size="lg" width="full" bg="blue.600" color="white">0 Points</Heading>
                        <HStack mt={4} fontWeight="bold" justifyContent="space-between" >
                        <Text>Loyalty</Text> 
                        <Text cursor="pointer" textDecoration="underline" onClick={(e)=>{
                          setLoyalty(false)
                          setHistory(true)
                        }}>History</Text>
                        </HStack>
  
                        <Box alignItems="start" mt={5} >
                        <Center>
                          <VStack>
                          <Heading as="h2" mt={10} fontSize="xl">You don't currently have</Heading>
                          <Heading as="h2" fontSize="xl">any items in your inbox.</Heading>
                          <Heading as="h2" fontSize="xl">Check back in soon!</Heading>
                      
                          </VStack>
                        </Center>
                        </Box>

                      </ModalBody>

                      <ModalFooter mt={4}>

                      <VStack>
                      <Text >
                          Loyalty points expire in 365 days since the date of issue. You  may redeem Points
                          during mobile order or in store. Click the "Apply Offers" button in the Order
                          Summary screen during a mobile order, or click the button below to redeem in store.                       
                          </Text>

                      <Button fontWeight="bold" onClick={(e)=> setLoyaltyCard(true)} mb={5} _hover={{bg: "#18181b", color:"white"}} width="full" style={{border: "2px solid black"}} bg="transparent" borderRadius={20}>LOYALTY CARD</Button>
                      </VStack>
 
                      </ModalFooter>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>



             {/*This Modal is for History*/}

             <Modal
                isOpen={history}
                onClose={onCloseHistory}
                size="lg">
                  <ModalOverlay>
                    <ModalContent>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading textAlign="center" size="lg">Standard</Heading>

                      </ModalHeader>

                      <ModalBody> 

                      <HStack mt={-5} cursor="pointer" width="5vw" fontWeight="bold" onClick={(e)=>{
                         setHistory(false)
                         setLoyalty(true)
                      }} >
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <Text mt={4} textDecoration="underline">Loyalty</Text>
                        </HStack>

                        <Heading pt={2} pb={2} borderRadius={10} textAlign="center" size="lg" width="full" bg="blue.600" color="white">0 Points</Heading>
              
                        <Text mt={4} fontSize="2xl" fontWeight="bold">Recent Activity</Text> 
                        
                      
                        <Box mt={-5} alignItems="start"  >
                        <Center>
                          <VStack>
                          <Heading as="h2" mt={10} fontSize="xl">You don't have a loyalty history yet! If you have
                          just made a purchase, this section will be populated within 24 hours</Heading>
                      
                          </VStack>
                        </Center>
                        </Box>

                      </ModalBody>

                      <ModalFooter mt={4}>

                      <VStack>
                      <Text >
                          Loyalty points expire in 365 days since the date of issue. You  may redeem Points
                          during mobile order or in store. Click the "Apply Offers" button in the Order
                          Summary screen during a mobile order, or click the button below to redeem in store.                       
                          </Text>

                      <Button fontWeight="bold" onClick={(e)=> setLoyaltyCard(true)} mb={5} _hover={{bg: "#18181b", color:"white"}} width="full" style={{border: "2px solid black"}} bg="transparent" borderRadius={20}>LOYALTY CARD</Button>
                      </VStack>
        
                      </ModalFooter>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                 {/*This Modal is for Loyalty Card*/}

              <Modal
              isOpen={loyaltyCard}
              onClose={onCloseLoyaltyCard}
              size="lg">
                  <ModalOverlay>
                    <ModalContent position="relative" >
                    
          {userName=="" && <Center>
                      <CircularProgress value={80} size={120} transform="translate(0.5%, -20.0%)"  position="absolute" zIndex={2} top={60} isIndeterminate />
                      </Center> }                          
                          {/*Opacity is the visibilty of the thing */}
                      
                      <div style={{opacity: userName=="" && 0.5, pointerEvents: userName=="" && "none"}}>
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading textAlign="center">LOYALTY CARD</Heading>
                        <Heading textAlign="center" size="lg">Standard</Heading>
                    
                      </ModalHeader>

                      <ModalBody>
                        
                        <Box alignItems="start" mt={5} >
                        <Center>
                        <QRCode value={qrData} size={200} />
                        </Center>
                        </Box>

                        <VStack textAlign="center" mt={5} >
                        <Heading as="h2" size="lg">{ `${userName} ${lastName}`} </Heading>
                        <Heading as="h2" mt={5} size="lg">LIT001{user.uid.substring(0, 5)}</Heading>
                        </VStack>
                      

                      </ModalBody>

                      <ModalFooter >

                      <VStack>
                      <Text >
                          If you're completing an order with an associate, please
                          make sure they scan your ID so we can award you
                          loyal points!                       
                          </Text>

                      <Button fontWeight="bold" onClick={(e)=> setLoyaltyCard(false)} mb={5} width="full" bg="blue.600" color="white" borderRadius={20}>Close</Button>
                      </VStack>
 
                      </ModalFooter>
                      </div>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>



                  {/*This Modal is for Promo Code*/}

                  <Modal
                isOpen={promoCode}
                onClose={onClosePromoCode}>
                  <ModalOverlay>
                    <ModalContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitPromo}>

                          {(formik)=> (
                            <Form>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading>Enter Promo Code</Heading>
                      </ModalHeader>

                      <ModalBody>
                        
                        <FormControl isInvalid={!!formik.errors.promo && formik.touched.promo}>
                          <FormLabel htmlFor='promo'>Apply Promo Code</FormLabel>
                          <InputGroup>
                          <InputRightElement children={<FontAwesomeIcon icon={faTag} />}/>
                          <Field as={Input} style={style2} name="promo"id="promo" value={initialValues.promo} onChange={(e)=> setInitialValues({...initialValues, [e.target.name]: e.target.value})} placeholder="Promo Code" />
                          </InputGroup>
                          <FormErrorMessage>
                          <ErrorMessage name="promo" />
                          </FormErrorMessage>
                        </FormControl>

                      </ModalBody>
                      <ModalFooter>
                        <Button width="full" type="submit" isLoading={formik.isSubmitting} fontWeight="bold" isDisabled={initialValues.promo == ""? true: false} borderRadius={20} background="blue.600" color="white">Submit</Button>

                      </ModalFooter>
                          
                      </Form>
                          )}
                        </Formik>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>

                
                
                {/*This is for Alert Dialog*/}

                <AlertDialog
                isOpen={dialogIsOpen}
                onClose={onCloseDialog}
                size="lg">
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      
                      <AlertDialogHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                      <Heading>Content</Heading>
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        <Text fontSize="xl" textAlign="center">
                          There doesn't seem to be any content for checking in at this time.
                        </Text>

                        <Text fontSize="xl" textAlign="center">
                          Please feel free to check back soon!
                        </Text>

                      </AlertDialogBody>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>


          </div>
    )
  }

  else{

    return(
        <div>

            <Box p={5} minHeight="100vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0:20} marginRight={tabletView? 10:30} paddingRight={tabletView? 0:20}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} >
                        Order History
                   </Heading><br/>


                  <Text fontWeight="bold" color="white" fontSize="xl" mt={5}>
                    There are no any recent orders

                  </Text>

               
              </VStack>

              <Box alignItems="start" mt={10} color="white">
            <Center>
                <VStack >
                <FontAwesomeIcon size="10x" icon={faHistory}/>
                   
                </VStack>
            </Center>
            </Box>
                 
            </Box>


              {/*This Modal is for Loyalty*/}

              <Modal
              isOpen={loyalty}
              onClose={onCloseloyalty}
              size="lg">
                  <ModalOverlay>
                    <ModalContent>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading textAlign="center" size="lg">Standard</Heading>
                    
                      </ModalHeader>

                      <ModalBody> 
                        <Heading pt={2} pb={2} borderRadius={10} textAlign="center" size="lg" width="full" bg="blue.600" color="white">0 Points</Heading>
                        <HStack mt={4} fontWeight="bold" justifyContent="space-between" >
                        <Text>Loyalty</Text> 
                        <Text cursor="pointer" textDecoration="underline" onClick={(e)=>{
                          setLoyalty(false)
                          setHistory(true)
                        }}>History</Text>
                        </HStack>
  
                        <Box alignItems="start" mt={5} >
                        <Center>
                          <VStack>
                          <Heading as="h2" mt={10} fontSize="xl">You don't currently have</Heading>
                          <Heading as="h2" fontSize="xl">any items in your inbox.</Heading>
                          <Heading as="h2" fontSize="xl">Check back in soon!</Heading>
                      
                          </VStack>
                        </Center>
                        </Box>

                      </ModalBody>

                      <ModalFooter mt={4}>

                      <VStack>
                      <Text >
                          Loyalty points expire in 365 days since the date of issue. You  may redeem Points
                          during mobile order or in store. Click the "Apply Offers" button in the Order
                          Summary screen during a mobile order, or click the button below to redeem in store.                       
                          </Text>

                      <Button fontWeight="bold" onClick={(e)=> setLoyaltyCard(true)} mb={5} _hover={{bg: "#18181b", color:"white"}} width="full" style={{border: "2px solid black"}} bg="transparent" borderRadius={20}>LOYALTY CARD</Button>
                      </VStack>
 
                      </ModalFooter>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>



             {/*This Modal is for History*/}

             <Modal
                isOpen={history}
                onClose={onCloseHistory}
                size="lg">
                  <ModalOverlay>
                    <ModalContent>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading textAlign="center" size="lg">Standard</Heading>

                      </ModalHeader>

                      <ModalBody> 

                      <HStack mt={-5} cursor="pointer" width="5vw" fontWeight="bold" onClick={(e)=>{
                         setHistory(false)
                         setLoyalty(true)
                      }} >
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <Text mt={4} textDecoration="underline">Loyalty</Text>
                        </HStack>

                        <Heading pt={2} pb={2} borderRadius={10} textAlign="center" size="lg" width="full" bg="blue.600" color="white">0 Points</Heading>
              
                        <Text mt={4} fontSize="2xl" fontWeight="bold">Recent Activity</Text> 
                        
                      
                        <Box mt={-5} alignItems="start"  >
                        <Center>
                          <VStack>
                          <Heading as="h2" mt={10} fontSize="xl">You don't have a loyalty history yet! If you have</Heading>
                          <Heading as="h2" fontSize="xl"> just made a purchase, this section will be</Heading>
                          <Heading as="h2" fontSize="xl"> populated within 24 hours</Heading>
                      
                          </VStack>
                        </Center>
                        </Box>

                      </ModalBody>

                      <ModalFooter mt={4}>

                      <VStack>
                      <Text >
                          Loyalty points expire in 365 days since the date of issue. You  may redeem Points
                          during mobile order or in store. Click the "Apply Offers" button in the Order
                          Summary screen during a mobile order, or click the button below to redeem in store.                       
                          </Text>

                      <Button fontWeight="bold" onClick={(e)=> setLoyaltyCard(true)} mb={5} _hover={{bg: "#18181b", color:"white"}} width="full" style={{border: "2px solid black"}} bg="transparent" borderRadius={20}>LOYALTY CARD</Button>
                      </VStack>
        
                      </ModalFooter>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                 {/*This Modal is for Loyalty Card*/}

              <Modal
              isOpen={loyaltyCard}
              onClose={onCloseLoyaltyCard}
              size="lg">
                  <ModalOverlay>
                    <ModalContent position="relative" >
                    
          {userName=="" && <Center>
                      <CircularProgress value={80} size={120} transform="translate(0.5%, -20.0%)"  position="absolute" zIndex={2} top={60} isIndeterminate />
                      </Center> }

                      <div style={{opacity: userName=="" && 0.5, pointerEvents: userName=="" && "none"}}>
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading textAlign="center">LOYALTY CARD</Heading>
                        <Heading textAlign="center" size="lg">Standard</Heading>
                    
                      </ModalHeader>

                      <ModalBody>
                        
                        <Box alignItems="start" mt={5} >
                        <Center>
                        <QRCode value={qrData} size={200} />
                        </Center>
                        </Box>

                        <VStack textAlign="center" mt={5} >
                        <Heading as="h2" size="lg">{ `${userName} ${lastName}`} </Heading>
                        <Heading as="h2" mt={5} size="lg">LIT001{user.uid.substring(0, 5)}</Heading>
                        </VStack>
                      

                      </ModalBody>

                      <ModalFooter >

                      <VStack>
                      <Text >
                          If you're completing an order with an associate, please
                          make sure they scan your ID so we can award you
                          loyal points!                       
                          </Text>

                      <Button fontWeight="bold" onClick={(e)=> setLoyaltyCard(false)} mb={5} width="full" bg="blue.600" color="white" borderRadius={20}>Close</Button>
                      </VStack>
 
                      </ModalFooter>
                      </div>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>



                  {/*This Modal is for Promo Code*/}

                  <Modal
                isOpen={promoCode}
                onClose={onClosePromoCode}>
                  <ModalOverlay>
                    <ModalContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitPromo}>

                          {(formik)=> (
                            <Form>
                      
                      <ModalHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                        <Heading>Enter Promo Code</Heading>
                      </ModalHeader>

                      <ModalBody>
                        
                        <FormControl isInvalid={!!formik.errors.promo && formik.touched.promo}>
                          <FormLabel htmlFor='promo'>Apply Promo Code</FormLabel>
                          <InputGroup>
                          <InputRightElement children={<FontAwesomeIcon icon={faTag} />}/>
                          <Field as={Input} style={style2} name="promo"id="promo" value={initialValues.promo} onChange={(e)=> setInitialValues({...initialValues, [e.target.name]: e.target.value})} placeholder="Promo Code" />
                          </InputGroup>
                          <FormErrorMessage>
                          <ErrorMessage name="promo" />
                          </FormErrorMessage>
                        </FormControl>

                      </ModalBody>
                      <ModalFooter>
                        <Button width="full" type="submit" isLoading={formik.isSubmitting} fontWeight="bold" isDisabled={initialValues.promo == ""? true: false} borderRadius={20} background="blue.600" color="white">Submit</Button>

                      </ModalFooter>
                          
                      </Form>
                          )}
                        </Formik>

                    </ModalContent>
                  </ModalOverlay>
                </Modal>

                
                
                {/*This is for Alert Dialog*/}

                <AlertDialog
                isOpen={dialogIsOpen}
                onClose={onCloseDialog}
                size="lg">
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      
                      <AlertDialogHeader>
                      <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>
                      <Heading>Content</Heading>
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        <Text fontSize="xl" textAlign="center">
                          There doesn't seem to be any content for checking in at this time.
                        </Text>

                        <Text fontSize="xl" textAlign="center">
                          Please feel free to check back soon!
                        </Text>

                      </AlertDialogBody>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>




        </div>
    )
  }
}

export default Orders