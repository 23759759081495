import { Heading, Card, Tooltip, Image, Box,Text, Container, HStack, VStack } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React, {useState} from 'react'
import crab from "./Assets/crab.jpg"
import spinach from "./Assets/spinach.jpg"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import mezze from "./Assets/mezze.jpg"
import paella from "./Assets/paella.jpg"
import Ratato from "./Assets/Ratato.jpg"
import risotto from "./Assets/risotto.jpg"
import shak from "./Assets/shak.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import dessert from "./Assets/dessert.jpg"




function Appetizers(){

    let [hover1A, sethover1A] = useState(false)
    let [hover2A, sethover2A] = useState(false)
    let [hover3A, sethover3A] = useState(false)

    return(
        <div>
             <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={20}>
          
          <Box>
          
          <Heading  mt={20} as="h2" >Appetizers</Heading>
            </Box>

          </VStack>

             {/*Cards Here */}
      
        <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 40, paddingLeft: 100, paddingRight: 100, paddingBottom: 50}}>
          
          <Card style={{boxShadow: hover1A && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1A(true)} onMouseLeave={(e)=> sethover1A(false)} overflow="hidden" borderRadius={10} cursor="pointer">
            <Image src={crab} height={238} objectFit="cover" borderRadius={10} transform= {hover1A && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack justifyContent="space-between">
            <Heading as="h3" size="md">Crab Artichoke</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$9.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">Indulge in a culinary delight with our
            perfect blend of succulent crab meat and tender artichoke hearts, expertly crafted
            to tantalize your taste buds.            
            </Text><br/>
            <HStack spacing={4} mt={1}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>

          <Card overflow="hidden" onMouseEnter={(e)=> sethover2A(true)} onMouseLeave={(e)=> sethover2A(false)} style={{boxShadow: hover2A && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={spinach} objectFit="cover" borderRadius={10} height={238} transform={hover2A && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
            <Box p={8} background="#EDEFEE">
              <HStack spacing={114}>
            <Heading as="h3" size="md">Spinach Balls</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$5.99</Text>
              </HStack><br/>
            <Text textAlign="left"> These little bites are crafted with fresh spinach, 
            savory herbs and a hint of garlic creating a crispy exterior that gives way 
            to a soft, flavorful center.
            </Text><br/>
            <HStack spacing={4} paddingTop={5} marginTop={1} >
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
            </Box>
          </Card>

          <Card overflow="hidden" onMouseEnter={(e)=> sethover3A(true)} onMouseLeave={(e)=> sethover3A(false)} style={{boxShadow: hover3A && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={Nachos} height={238} borderRadius={10} objectFit="cover" transform={hover3A && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack spacing={100}>
            <Heading as="h3" size="md">Vegan Nachos</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$7.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">Dive into the acrumptious world of
            our Nachos layered with a mix of black beans, fresh vegetables and a dairy-free cheese
            that melts in every bite.
            </Text><br/>
            <HStack spacing={4}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md" mt={1}>Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>
          

        </Container>

        {/*Cards end here*/}

        </div>
    )
}


export default Appetizers