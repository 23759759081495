import { Heading, Card, Tooltip, Image, Box,Text, Container, HStack, VStack } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import React, {useState} from 'react'
import crab from "./Assets/crab.jpg"
import spinach from "./Assets/spinach.jpg"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import mezze from "./Assets/mezze.jpg"
import paella from "./Assets/paella.jpg"
import Ratato from "./Assets/Ratato.jpg"
import risotto from "./Assets/risotto.jpg"
import shak from "./Assets/shak.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import dessert from "./Assets/dessert.jpg"



function Mains(){

    let [hover1M, sethover1M] = useState(false)
    let [hover2M, sethover2M] = useState(false)
    let [hover3M, sethover3M] = useState(false)
    let [hover4M, sethover4M] = useState(false)
    let [hover5M, sethover5M] = useState(false)
    let [hover6M, sethover6M] = useState(false)

    return(
        <div>
             {/*Main starts here*/}
        <Container p={5} minHeight="40vh" maxWidth="full" postion="relative" id="landing-section">
          
          <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={20}>
          
          <Box>
         
          <Heading  mt={10} as="h2" >Main Dishes</Heading>
            </Box>
  
          </VStack>
  
  
           {/*Cards Here */}

           <Carousel infiniteLoop>
        <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 40, paddingLeft: 100, paddingRight: 100, paddingBottom: 100}}>
          
        <Card style={{boxShadow: hover1M && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1M(true)} onMouseLeave={(e)=> sethover1M(false)} overflow="hidden" borderRadius={10} cursor="pointer">
          <Image src={Couscous} height={238} objectFit="cover" borderRadius={10} transform= {hover1M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={130}>
          <Heading as="h3" size="md">Couscous</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$10.99</Text>
            </HStack><br/>
          <Text paddingBottom={5} textAlign="left">Dive into a plate of our exquisite Couscous, 
          infused with aromatic herbs and spices that transports you to the shores of the 
          Mediterranean.
          </Text><br/>
          <HStack spacing={4}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md" mt={2}>Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>

          </Box>
        </Card>

        
        <Card overflow="hidden" onMouseEnter={(e)=> sethover2M(true)} onMouseLeave={(e)=> sethover2M(false)} style={{boxShadow: hover2M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={mezze} borderRadius={10} height={238} transform={hover2M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
          <Box p={8} background="#EDEFEE">
            <HStack spacing={40}>
          <Heading as="h3" size="md">Mezze</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$10.99</Text>
            </HStack><br/>
          <Text textAlign="left">Embark on an adventure with our Mezze platter, an array of
          small bites that dance on your taste buds. Each dip and bite tell a story of flavors.
          
          </Text><br/>
          <HStack spacing={4} paddingTop={5} marginTop={1} >
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md" mt={1}>Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>
          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover3M(true)} onMouseLeave={(e)=> sethover3M(false)} style={{boxShadow: hover3M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={paella} height={238} borderRadius={10} objectFit="cover" transform={hover3M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={169}>
          <Heading as="h3" size="md">Paella</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$14.99</Text>
            </HStack><br/>
          <Text paddingBottom={5} textAlign="left">An authentic Spanish masterpiece that brings the vibrant spirit of Valencia
            to your table. Each spoonful is a blend of saffron-infused rice,seafood and spices.
          </Text><br/>
          <HStack spacing={4} mt={-4}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>

          </Box>
        </Card>
         

        </Container>

        {/*Cards end here*/}
      


        {/*Second section starts Here*/}
        <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 40, paddingLeft: 100, paddingRight: 100, paddingBottom: 100}}>
          
        <Card style={{boxShadow: hover4M && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover4M(true)} onMouseLeave={(e)=> sethover4M(false)} overflow="hidden" borderRadius={10} cursor="pointer">
          <Image src={Ratato} height={238} objectFit="cover" borderRadius={10} transform= {hover4M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={130}>
          <Heading as="h3" size="md">Ratatouille</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$11.99</Text>
            </HStack><br/>
          <Text paddingBottom={5} textAlign="left">A vegetable medley that's as visually stunning as it's delicious.
          Layers of perfectly-roasted vegetables, bathed in a tomato-based sauce.
          </Text><br/>
          <HStack spacing={4}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md" mt={1}>Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>

          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover5M(true)} onMouseLeave={(e)=> sethover5M(false)} style={{boxShadow: hover5M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={risotto} objectFit="cover" borderRadius={10} height={238} transform={hover5M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
          <Box p={8} background="#EDEFEE">
            <HStack spacing={40}>
          <Heading as="h3" size="md">Risotto</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
            </HStack><br/>
          <Text textAlign="left">Wrap yourself in the embrace of our creamy italian classic that takes you on
          a journey through Northern Italy. Arborio rice slow-cooked to perfection.
          
          </Text><br/>
          <HStack spacing={4} paddingTop={5} marginTop={1} >
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>
          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover6M(true)} onMouseLeave={(e)=> sethover6M(false)} style={{boxShadow: hover6M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={shak} height={238} borderRadius={10} objectFit="cover" transform={hover6M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={127}>
          <Heading as="h3" size="md">Shakshuka</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
            </HStack><br/>
          <Text paddingBottom={5} textAlign="left">Energize your day with our North-African delight that's a burst of flavors.
          Poached eggs in a tomato and pepper stew create a 
          breakfast experience.
          </Text><br/>
          <HStack spacing={4}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md" mt={1}>Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>

          </Box>
        </Card>

        </Container>



        </Carousel>
      
        
  
        {/*Cards end here*/}
        </Container>
  
  
        </div>
    )
}

export default Mains