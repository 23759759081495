import 'normalize.css'
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
//Above is from Auth0

import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ChakraProvider } from "@chakra-ui/react";
import app from "./Lemon Compo/firebase"
import reportWebVitals from "./reportWebVitals"

//Below is from Auth0
//const domain = 'dev-3liedp7wp58shlbz.us.auth0.com';
//const clientId = 'v8QsbnejE1hlemSQ9y6JQd8pKlIhCNdi';
//const redirectUri="http://localhost:3000/login"
/*

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
             
            <BrowserRouter>
            <React.StrictMode>
            <App />
            </React.StrictMode>
            </BrowserRouter>


);

*/

// Used this instead of ReactDom to prevent repetitions of react-simple-chatbot
import {render}from "react-dom"
render(<BrowserRouter><App /> </BrowserRouter>, document.getElementById("root"))
