import {Box, HStack, VStack, Text, Center, Heading} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faPersonDigging } from '@fortawesome/free-solid-svg-icons'

function EmptyCart (){

    return(
        <div>
            <Box alignItems="start" mt={20}>
            <Center>
                <VStack>
                <FontAwesomeIcon size="10x" icon={faCartShopping}/>
    
                <Heading as="h2" mt={10} fontSize="2xl">Your cart is empty.</Heading>
                <Heading as="h2" fontSize="2xl">Keep shopping to find a meal!</Heading>

                </VStack>
            </Center>
            </Box>


        </div>
    )
}

export default EmptyCart