import React, {useState, useEffect} from "react";
import {Box, Flex, Alert,Container,Button,Center, Heading,Input,FormErrorMessage,FormControl, HStack, Image, Text, VStack, FormLabel, AlertIcon, IconButton, useDisclosure, ScaleFade} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleUp, faAnglesUp, faComment, faEnvelope, faLocationPinLock, faPhone, faWarning } from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons'
import footLogo from "../Lemon Compo/footLogo.png"
import logofooter from "./Assets/logofooter.jpg"
import { useBreakpointValue } from "@chakra-ui/react";
import {Link, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {BeatLoader} from 'react-spinners'
import {addDoc, collection, getFirestore} from 'firebase/firestore'
import app from "./firebase"
import { SlideFade, Fade, Slide } from "@chakra-ui/react";
import { onValue, getDatabase, ref } from "firebase/database";




const Footer = ({setSelected}) => {

let [alert, setAlert] = useState(false)
let [alert2, setAlert2] = useState(false)
let [isOpen, setIsOpen] = useState()
let [isOpenError, setIsOpenError] = useState(false)

let [online, setOnline] = useState()

let navigate =useNavigate()

let database = getDatabase(app)


let mobileView = useBreakpointValue({
  base: true,
  lg: false
})


let tabletView = useBreakpointValue({
  md: true
})



// Creating a visible up icon upon scroll up
let [scrollingUp, setScrollingUp] = useState(true)
let [initialScroll, setInitialscroll] = useState(0)
  
    useEffect((e) => {

      let handleScroll = () => {
        let verticalScroll = window.scrollY
        
        setScrollingUp(verticalScroll < initialScroll)
        setInitialscroll(verticalScroll)
      }
      
      document.addEventListener("scroll", handleScroll)
      
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    })

  let style = {
    position: "fixed",
    bottom: "0",

  }

  let initialValues = {
    email: ""
  }

  
  useEffect((e)=>{

    let connectionStatus = ref(database, '.info/connected')
    // onValue means keep an eye on the connection status, if it changes then give me a picture/snapshot of it
    let connectionStatusListener = onValue(connectionStatus, (snapshot)=>{
      setOnline(snapshot.val())
    })

    return()=>{
      connectionStatusListener()
    }

  }, [])
  
  
  let onSubmit = async(values, actions)=>{

    setTimeout((e)=>{
      actions.setSubmitting(false)
    }, 30000)
  

    if (online){
   
    try{
      let dataB = getFirestore(app)

      console.log("FormData:", values.email)
      await addDoc(collection(dataB, "Newsletter"), values)
      setIsOpen(true)
      setAlert(true)
      actions.resetForm()
    }

    catch(error) {
      console.error("error Sending form:", error.message)
    }

  }

  else{
    setIsOpenError(true)
    setAlert2(true)
  }

    actions.setSubmitting(false)
    
    setTimeout((e)=>{
      setAlert(false)
      setAlert2(false)
    }, 5000)    
  }



  let validationSchema= Yup.object({
    email: Yup.string().email("Invalid email format").required("Email required")
  })






  if(mobileView){
    return(
      <div>
        
    <Box backgroundColor="#18181b">
      <footer>
        <Flex
          px={12}
          color="white"
          justifyContent="center"
          alignItems="center"
          minWidth="50vw" 
          height={16}
          paddingTop={5}
        >
          <p>Designed by <a style={{textDecoration: "underline"}} href="https://benasenso.site">Ben</a> </p>
 
        </Flex>
        
        <Center>
        
        <Container paddingBottom={5} paddingLeft={5} ml={tabletView && 5}  color="white" style={{display: "grid", gridTemplateColumns: tabletView? "1fr 1fr 1fr" : "1fr", gap: tabletView? 0: 40, width: tabletView && "100vw"}}  >
        <Box mt={10}>
        <Image src={logofooter} width={110} height={170} marginTop={-10} /><br/>
        </Box>

        <VStack spacing={0} alignItems= "start" >
          <Heading as="h3" fontSize="2xl" marginBottom={5}>SITEMAP</Heading>
          <Link to="/" onClick={(e)=> setSelected("Home")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Home</Text>
            </HStack>
          </Link>

          <Link to="/about" onClick={(e)=> setSelected("About")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>About</Text>
            </HStack>
          </Link>

          <Link to="/menu" onClick={(e)=> setSelected("Menu")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Menu</Text>
            </HStack>
          </Link>

          <Link to="/reservation" onClick={(e)=> setSelected("Reservation")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Reservations</Text>
            </HStack>
          </Link>

          <Link to="/orderOnline"  onClick={(e)=> setSelected("Order")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Order Online</Text>
            </HStack>
          </Link>

          <Link to="/login"  onClick={(e)=> setSelected("Login")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Login</Text>
            </HStack>
          </Link>

        </VStack>        

        <Box ml={tabletView && 20}>
          <Heading as="h3" fontSize="2xl" margin={0} mt={4} >CONTACT US</Heading>
          <HStack spacing={4}>
            <FontAwesomeIcon icon={faLocationPinLock}/>
          <Text width="full" mt={4}>976 Pisa Ave, Chicago, IL, 60611</Text>
          </HStack>

          <HStack spacing={4} mt={-4}>
            <FontAwesomeIcon icon={faPhone}/>
            <ChakraLink href="tel: 312 593-2744" isExternal style={{textDecoration: "none"}} >
            <Text mt={4}  _hover={{color:"#F4CE14"}}  >(312) 593-2744</Text>
            </ChakraLink>
          </HStack>

          <HStack spacing={4} mt={-4}>
            <FontAwesomeIcon icon={faEnvelope}/>
          <ChakraLink href="mailto: info@littlelemon.com" isExternal style={{textDecoration: "none"}} >
            <Text mt={4} _hover={{color:"#F4CE14"}}  >info@littlelemon.com</Text>
          </ChakraLink>
          </HStack>

          <HStack spacing={4} mt={-4}>
            <FontAwesomeIcon icon={faComment}/>
          <Link to="feedback" style={{textDecoration: "none"}} >
            <Text mt={4} _hover={{color:"#F4CE14"}}  >Feedback</Text>
          </Link>

          </HStack>


          <VStack alignItems="start" mt={10} marginBottom={10}>
          <Heading as="h3" fontSize="2xl"width={tabletView && "30vw"} >OPENING HOURS</Heading>
          <Text >Mon - Fri: 11am - 10pm</Text>
          <Text mt={-3}>Sat: 2pm - 11pm</Text>
          <Text mt={-3}>Sun: 2pm - 9pm</Text>

          </VStack>

        </Box>

        <VStack alignItems="start" mt={tabletView? -40 : -5} >
          <Heading as="h3" width={tabletView? "30vw": "60vw"} fontSize="2xl">CONNECT WITH US</Heading>
          
          <HStack spacing={2}>
            <ChakraLink href="https://www.facebook.com/littlelemon" isExternal _hover={{color:"#1877F2", background: "whitesmoke"}}>
            <FontAwesomeIcon icon={faFacebook} style={{border: "1px solid white", padding:5}}/>
            </ChakraLink>
            <ChakraLink href="https://www.twitter.com/littlelemon" isExternal _hover={{color:"#1DA1F2", background: "whitesmoke"}}>
            <FontAwesomeIcon icon={faTwitter} style={{border: "1px solid white", padding:5}}/>
            </ChakraLink>
            <ChakraLink href="https://www.instagram.com/littlelemon" isExternal  _hover={{color:"red", background: "whitesmoke"}}>
            <FontAwesomeIcon icon={faInstagram} style={{border: "1px solid white", padding:5}} />
            </ChakraLink>
            <ChakraLink href="https://wwww.youtube.com/littlelemon" isExternal _hover={{color:"#FF0000", background: "whitesmoke"}}>
            <FontAwesomeIcon style={{border: "1px solid white", padding:5}} icon={faYoutube} /> 
            </ChakraLink>
          
          </HStack>
        </VStack>

         <VStack alignItems="start" mt={5} marginBottom={10}  ml={tabletView && -60}>
          <Heading as="h3" fontSize="2xl">NEWSLETTER</Heading>
          <Text>Subscribe to our newsletters for exclusives</Text>
          
           <Formik
           initialValues={initialValues}
           validationSchema={validationSchema}
           onSubmit={onSubmit}>

            {(formik)=>(

              <Form>

              {/*You bind items with Flex as a single unit */} 
                <Flex spacing={0} position="relative">

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                  <Field as={Input} name="email" width={tabletView? "50vw": "80vw"} id="email" bg="whitesmoke" color="black" placeholder="Your email" />
                  <FormErrorMessage>
                  <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage  name="email" /> 
                  </FormErrorMessage>
                </FormControl>
                
                <Button type="submit" fontWeight="bold"  background="#F4CE14" position="absolute" right="0" style={{zIndex:1}} isLoading={formik.isSubmitting} spinner={<BeatLoader size={10}/>}>SignUp</Button>
              
                </Flex>
                

              </Form>
            )}
           </Formik>
           
          {alert &&
          <Slide in={isOpen} style={{marginTop: 40}}>
          <Alert status="success" mt={5}>
            <AlertIcon />
            <span style={{color:"black"}}>You have subscribed successfully to our newsletter</span>
          </Alert>
          </Slide> }


           {/*the "in" prop is what triggers or open the animation*/}
           {alert2 &&
          <Slide in={isOpenError} >
          <Alert status="error" mt={20}>
            <AlertIcon />
            <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
          </Alert> 
          </Slide>
         }
      
        </VStack>


        </Container>
        </Center>
        
        <div style={{borderTop: "2px solid #ccc", marginLeft: tabletView? 40:20, marginRight: tabletView? 40:20}}>
          <HStack justifyContent="space-between">
          <Text color="white" fontSize={mobileView &&"small"}> © 2024 Little Lemon, All Right Reserved.</Text>
          <Text as={Link} to="/privacyPolicy" color="white" fontSize={mobileView && "small"} mt={-4} _hover={{color:"#F4CE14"}}>Privacy Policy</Text>
          </HStack>

        </div>
        
        {scrollingUp && 
        <Box box-shadow="md" >
        <HStack justifyContent="end" paddingRight={20} >

        <IconButton icon={<FontAwesomeIcon icon={faAngleUp} cursor="pointer" size="2x" cplor="blue" />} border="3px solid blue" borderRadius="100%" zIndex={1} onClick={(e) => {
            let element = document.getElementById("landing-section")
            let element2 = document.getElementById("privacyPolicy")
            let element3 = document.getElementById("Top")

            if(element){
              return element.scrollIntoView({
                behaviour:"smooth",
                block: "start"
              })
            }
            else if(element2){
              element2.scrollIntoView({
              behaviour: "smooth"
              })
            }
            else if(element3){
              element3.scrollIntoView({
              behaviour: "smooth"
              })
            }
            else{
              return;
            }
            
          }} style={style} /> 
         
        </HStack>
        </Box>}
      </footer>
    </Box>
       

      </div>
    )
  }

  else{
    return(
      <div>
         <Box backgroundColor="#18181b">
      <footer>
        <Flex
          px={12}
          color="white"
          justifyContent="center"
          alignItems="center"
          minWidth="50vw" 
          height={16}
          paddingTop={5}
        >
          <p>Designed by <a style={{textDecoration: "underline"}} href="https://benasenso.site">Ben</a> </p>
 
        </Flex>
        
        
        <Container paddingBottom={5} marginLeft={10} color="white" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 140}} >
        <Box width={110} height={170}>
        <Image src={logofooter}  /><br/>
        </Box>
        <VStack spacing={0} alignItems="start">
          <Heading as="h3" fontSize="2xl" marginBottom={5}>SITEMAP</Heading>
          <Link to="/" onClick={(e)=> setSelected("Home")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Home</Text>
            </HStack>
          </Link>

          <Link to="/about" onClick={(e)=> setSelected("About")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>About</Text>
            </HStack>
          </Link>

          <Link to="/menu" onClick={(e)=> setSelected("Menu")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Menu</Text>
            </HStack>
          </Link>

          <Link to="/reservation" onClick={(e)=> setSelected("Reservation")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text >Reservations</Text>
            </HStack>
          </Link>

          <Link to="/orderOnline" onClick={(e)=> setSelected("Order")}>
            <HStack width="10vw" _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text >Order Online</Text>
            </HStack>
          </Link>

          <Link to="/login" onClick={(e)=> setSelected("Login")}>
            <HStack _hover={{textDecoration: "underline", color:"#F4CE14"}}>
          <span style={{marginTop: -10}}><FontAwesomeIcon icon={faAngleRight}/></span>
          <Text>Login</Text>
            </HStack>
          </Link>

        </VStack> 

       
        <Box >
          <Heading as="h3" fontSize="2xl" margin={0} >CONTACT US</Heading>
          <HStack spacing={4} width="20vw">
            <FontAwesomeIcon icon={faLocationPinLock}/>
          <Text mt={4}>976 Pisa Ave, Chicago, IL, 60611</Text>
          </HStack>

          <HStack spacing={4} mt={-4}>
            <FontAwesomeIcon icon={faPhone}/>
            <ChakraLink href="tel: 312 593-2744" isExternal style={{textDecoration: "none"}} >
            <Text mt={4}  _hover={{color:"#F4CE14"}}  >(312) 593-2744</Text>
            </ChakraLink>
          </HStack>

          <HStack spacing={4} mt={-4}>
            <FontAwesomeIcon icon={faEnvelope}/>
          <ChakraLink href="mailto: info@littlelemon.com" isExternal style={{textDecoration: "none"}} >
            <Text mt={4} _hover={{color:"#F4CE14"}}  >info@littlelemon.com</Text>
          </ChakraLink>

          </HStack>

          <HStack spacing={4} mt={-4}>
            <FontAwesomeIcon icon={faComment}/>
          <Link to="feedback" style={{textDecoration: "none"}} >
            <Text mt={4} _hover={{color:"#F4CE14"}}  >Feedback</Text>
          </Link>

          </HStack>

          <VStack alignItems="start" mt={10} marginBottom={10}>
          <Heading as="h3" fontSize="2xl" >OPENING HOURS</Heading>
          <Text >Mon - Fri: 11am - 10pm</Text>
          <Text mt={-3}>Sat: 2pm - 11pm</Text>
          <Text mt={-3}>Sun: 2pm - 9pm</Text>

          </VStack>



        </Box>

        <VStack alignItems="start">
          <Heading as="h3" width="20vw" fontSize="2xl">CONNECT WITH US</Heading>
          
          <HStack spacing={2}>
            <ChakraLink href="https://www.facebook.com/littlelemon" isExternal _hover={{color:"#1877F2", background: "whitesmoke"}}>
            <FontAwesomeIcon icon={faFacebook} style={{border: "1px solid white", padding:5}}/>
            </ChakraLink>
            <ChakraLink href="https://www.twitter.com/littlelemon" isExternal _hover={{color:"#1DA1F2", background: "whitesmoke"}}>
            <FontAwesomeIcon icon={faTwitter} style={{border: "1px solid white", padding:5}}/>
            </ChakraLink>
            <ChakraLink href="https://www.instagram.com/littlelemon" isExternal  _hover={{color:"red", background: "whitesmoke"}}>
            <FontAwesomeIcon icon={faInstagram} style={{border: "1px solid white", padding:5}} />
            </ChakraLink>
            <ChakraLink href="https://wwww.youtube.com/littlelemon" isExternal _hover={{color:"#FF0000", background: "whitesmoke"}}>
            <FontAwesomeIcon style={{border: "1px solid white", padding:5}} icon={faYoutube} /> 
            </ChakraLink>
          
          </HStack>

          <VStack alignItems="start" mt={10} marginBottom={10}>
          <Heading as="h3" fontSize="2xl">NEWSLETTER</Heading>
          <Text>Subscribe to our newsletters for exclusives</Text>
          
           <Formik
           initialValues={initialValues}
           validationSchema={validationSchema}
           onSubmit={onSubmit}>

            {(formik)=>(

              <Form>

              {/*You bind items with Flex as a single unit */} 
                <Flex spacing={0} position="relative">

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email}>
                  <Field as={Input} name="email" id="email" width="22vw" bg="whitesmoke" color="black" placeholder="Your email" />
                  <FormErrorMessage>
                  <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage  name="email" /> 
                  </FormErrorMessage>
                </FormControl>
                
                <Button type="submit" fontWeight="bold" isLoading={formik.isSubmitting} spinner={<BeatLoader size={10}/>} background="#F4CE14" position="absolute" right="0" style={{zIndex:1}} >SignUp</Button>
              
                </Flex>
                

              </Form>
            )}
           </Formik>

          {/*the "in" prop is what triggers or open the animation*/}
           {alert &&
          <Slide in={isOpen} >
          <Alert status="success" mt={20}>
            <AlertIcon />
            <span style={{color:"black"}}>You have subscribed successfully to our newsletter</span>
          </Alert> 
          </Slide>
         }


          {/*the "in" prop is what triggers or open the animation*/}
          {alert2 &&
          <Slide in={isOpenError} >
          <Alert status="error" mt={20}>
            <AlertIcon />
            <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
          </Alert> 
          </Slide>
         }

           </VStack>


        </VStack>

       

        </Container>
        
        
        <div style={{borderTop: "2px solid #ccc", marginLeft: 40, marginRight: 40}}>
          <HStack justifyContent="space-between">
          <Text color="white">© 2024 Little Lemon, All Rights Reserved.</Text>        
          <HStack spacing={2}>
          <Text as={Link} to="/termsAndConditions" color="white" mt={-4} _hover={{color:"#F4CE14"}}>Terms And Conditions</Text>
          <Text color="white">|</Text>
          <Text as={Link} to="/privacyPolicy" color="white" mt={-4} _hover={{color:"#F4CE14"}}>Privacy Policy</Text>
          </HStack>

          </HStack>
        </div>
        
        {scrollingUp && 
        <Box box-shadow="md" >
        <HStack justifyContent="end" paddingRight={20} >
     
        <IconButton icon={<FontAwesomeIcon icon={faAngleUp} cursor="pointer" size="2x" color="blue" />} border="3px solid blue" isRound={true} zIndex={1} onClick={(e) => {
            let element = document.getElementById("landing-section")
            let element2 = document.getElementById("privacyPolicy")
            let element3 = document.getElementById("Top")

            if(element){
              return element.scrollIntoView({
                behaviour:"smooth",
                block: "start"
              })
            }
            else if(element2){
              element2.scrollIntoView({
              behaviour: "smooth"
              })
            }

            else if(element3){
              element3.scrollIntoView({
              behaviour: "smooth"
              })
            }
            else{
              return;
            }
            
              
            
          }} style={style} /> 
          
          
        </HStack>
        </Box>}
      </footer>
    </Box>

      </div>
    )
     
    
    
   
  };
};
export default Footer;
