import { Spinner, Center, Text, VStack } from "@chakra-ui/react"

function Loadspinner(){

    return(
        <div>
            <Center minHeight="100vh">
                <VStack>
                <Spinner size="xl" width={70} height={70} borderWidth={5} color="blue.600" />
                <Text fontSize="xl">Loading...</Text>
                </VStack>

            </Center>
            

        </div>
    )
}

export default Loadspinner