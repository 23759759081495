import { Heading, Center, Drawer, Modal, DrawerOverlay,DrawerContent,DrawerHeader,DrawerCloseButton, DrawerBody, FormControl ,Card, Button, Image, Box,Text, Container, HStack, VStack, Input, Textarea, IconButton, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAdd, faMinus, faPersonBiking } from "@fortawesome/free-solid-svg-icons";
import Checkout from "./Checkout";
import {Formik, Form, Field, FormikConsumer, useFormikContext} from 'formik'
import * as Yup from 'yup'
import app from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDoc, doc, getFirestore } from "firebase/firestore";



import React, {useEffect, useState} from 'react'
import crab from "./Assets/crab.jpg"
import spinach from "./Assets/spinach.jpg"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import mezze from "./Assets/mezze.jpg"
import paella from "./Assets/paella.jpg"
import Ratato from "./Assets/Ratato.jpg"
import risotto from "./Assets/risotto.jpg"
import shak from "./Assets/shak.jpg"
import grilledFish from "./Assets/grilledFish.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import dessert from "./Assets/dessert.jpg"
import AppetizerMobPlaceholder from "./AppetizerMob Placeholder";
import MainMobPlaceholder from "./MainMob Placeholder";
import DessertMobPlaceholder from "./DessertMob Placeholder";

import { Flex,  Collapse, Divider } from "@chakra-ui/react"

import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";



function MenuDashMob ({count, setCount, addToCart, isOpenShop, setIsOpenShop, cart, setCart}){

//React documentation recommends the functional form of state update when new state value depends on previous state value. Otherwise the simpler form is used.
// It becomes crirical especially when async is involved
  let handleCount = ()=> {
    setCount(prevCount => prevCount  + addSub)
  }

  let handleCount2 = ()=> {
    setCount(prevCount => prevCount  + addSub2)
  }

  let handleCount3 = ()=> {
    setCount(prevCount => prevCount  + addSub3)
  }

  let handleCount4 = ()=> {
    setCount(prevCount => prevCount  + addSub4)
  }

  let handleCount5 = ()=> {
    setCount(prevCount => prevCount  + addSub5)
  }

  let handleCount6 = ()=> {
    setCount(prevCount => prevCount  + addSub6)
  }

  let handleCount7 = ()=> {
    setCount(prevCount => prevCount  + addSub7)
  }

  let handleCount8 = ()=> {
    setCount(prevCount => prevCount  + addSub8)
  }

  let handleCount9 = ()=> {
    setCount(prevCount => prevCount  + addSub9)
  }

  let handleCount10 = ()=> {
    setCount(prevCount => prevCount  + addSub10)
  }

  let handleCount11 = ()=> {
    setCount(prevCount => prevCount  + addSub11)
  }

  let handleCount12 = ()=> {
    setCount(prevCount => prevCount  + addSub12)
  }

  let handleCount13 = ()=> {
    setCount(prevCount => prevCount  + addSub13)
  }

  let [addSub, setAddSub] = useState(1)
  let [addSub2, setAddSub2] = useState(1)
  let [addSub3, setAddSub3] = useState(1)
  let [addSub4, setAddSub4] = useState(1)
  let [addSub5, setAddSub5] = useState(1)
  let [addSub6, setAddSub6] = useState(1)
  let [addSub7, setAddSub7] = useState(1)
  let [addSub8, setAddSub8] = useState(1)
  let [addSub9, setAddSub9] = useState(1)
  let [addSub10, setAddSub10] = useState(1)
  let [addSub11, setAddSub11] = useState(1)
  let [addSub12, setAddSub12] = useState(1)
  let [addSub13, setAddSub13] = useState(1)


// This is for Addition
  let handleAdd =()=> {
    setAddSub(prevValue=> prevValue + 1)
  }

  let handleAdd2 =()=> {
    setAddSub2(prevValue=> prevValue + 1)
  }

   let handleAdd3 =()=> {
    setAddSub3(prevValue=> prevValue + 1)
  }

  let handleAdd4 =()=> {
    setAddSub4(prevValue=> prevValue + 1)
  }

  let handleAdd5 =()=> {
    setAddSub5(prevValue=> prevValue + 1)
  }

  let handleAdd6 =()=> {
    setAddSub6(prevValue=> prevValue + 1)
  }

  let handleAdd7 =()=> {
    setAddSub7(prevValue=> prevValue + 1)
  }

  let handleAdd8 =()=> {
    setAddSub8(prevValue=> prevValue + 1)
  }

  let handleAdd9 =()=> {
    setAddSub9(prevValue=> prevValue + 1)
  }

  let handleAdd10 =()=> {
    setAddSub10(prevValue=> prevValue + 1)
  }

  let handleAdd11 =()=> {
    setAddSub11(prevValue=> prevValue + 1)
  }

  let handleAdd12 =()=> {
    setAddSub12(prevValue=> prevValue + 1)
  }

  
  let handleAdd13 =()=> {
    setAddSub13(prevValue=> prevValue + 1)
  }



  //This is for Subtraction
  
  let handleSub = ()=>{
    if(addSub > 1){
    setAddSub((prevValue) => prevValue -1)
    }
  }
  
  let handleSub2 = ()=>{
    if(addSub2 > 1){
    setAddSub2((prevValue) => prevValue -1)
    }
  }

  let handleSub3 = ()=>{
    if(addSub3 > 1){
    setAddSub3((prevValue) => prevValue -1)
    }
  }


  let handleSub4 = ()=>{
    if(addSub4 > 1){
    setAddSub4((prevValue) => prevValue -1)
    }
  }

  let handleSub5 = ()=>{
    if(addSub5 > 1){
    setAddSub5((prevValue) => prevValue -1)
    }
  }

  let handleSub6 = ()=>{
    if(addSub6 > 1){
    setAddSub6((prevValue) => prevValue -1)
    }
  }

  let handleSub7 = ()=>{
    if(addSub7 > 1){
    setAddSub7((prevValue) => prevValue -1)
    }
  }

  let handleSub8 = ()=>{
    if(addSub8 > 1){
    setAddSub8((prevValue) => prevValue -1)
    }
  }

  let handleSub9 = ()=>{
    if(addSub9 > 1){
    setAddSub9((prevValue) => prevValue -1)
    }
  }

  let handleSub10 = ()=>{
    if(addSub10 > 1){
    setAddSub10((prevValue) => prevValue -1)
    }
  }

  let handleSub11 = ()=>{
    if(addSub11 > 1){
    setAddSub11((prevValue) => prevValue -1)
    }
  }

  let handleSub12 = ()=>{
    if(addSub12 > 1){
    setAddSub12((prevValue) => prevValue -1)
    }
  }

  let handleSub13 = ()=>{
    if(addSub13 > 1){
    setAddSub13((prevValue) => prevValue -1)
    }
  }
  //let {values} = useFormikContext()

  let auth = getAuth(app)
  let dataB = getFirestore(app)


    let [hover1A, sethover1A] = useState(false)
    let [hover2A, sethover2A] = useState(false)
    let [hover3A, sethover3A] = useState(false)
    let [hover1M, sethover1M] = useState(false)
    let [hover2M, sethover2M] = useState(false)
    let [hover3M, sethover3M] = useState(false)
    let [hover4M, sethover4M] = useState(false)
    let [hover5M, sethover5M] = useState(false)
    let [hover6M, sethover6M] = useState(false)
    let [hover7M, sethover7M] = useState(false)
    let [hover1D, sethover1D] = useState(false)
    let [hover2D, sethover2D] = useState(false)
    let [hover3D, sethover3D] = useState(false)
    
    let [initialValues, setInitialvalues] = useState({
      name: ""
    })

    let validationSchema = Yup.object({
      name: Yup.string()
    })



    useEffect((e)=> {
     

      let authStateChangeListener = onAuthStateChanged(auth, async(currentUser) => {

        if (currentUser){
          let userDoc = doc(dataB, "users", currentUser.uid)
          let getUserDoc = await getDoc(userDoc)

          getUserDoc.exists() && setInitialvalues(getUserDoc.data())
        }

        else{
          return;
        }

      })
      
      return()=> {
        authStateChangeListener()
      }

    }, [auth, dataB])

    
    
    let handleChange = (e)=> {
      setInitialvalues({...initialValues, [e.target.name]: e.target.value })
    }


    let style ={
      background: "transparent",
      
      outline: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
      color: "#F4CE14",
      borderRadius: 0
    }

    let [collapse, setCollapse] = useState(true)
    let [collapse2, setCollapse2] = useState(true)
    let [collapse3, setCollapse3] = useState(true)

    
    let [isOpen, setIsOpen] = useState(false)
    let [isOpen2, setIsOpen2] = useState(false)
    let [isOpen3, setIsOpen3] = useState(false)
    
    let [isOpen4, setIsOpen4] = useState(false)
    let [isOpen5, setIsOpen5] = useState(false)
    let [isOpen6, setIsOpen6] = useState(false)
    let [isOpen7, setIsOpen7] = useState(false)
    let [isOpen8, setIsOpen8] = useState(false)
    let [isOpen9, setIsOpen9] = useState(false)
    let [isOpen10, setIsOpen10] = useState(false)

    let [isOpen11, setIsOpen11] = useState(false)
    let [isOpen12, setIsOpen12] = useState(false)
    let [isOpen13, setIsOpen13] = useState(false)


    let onClose =()=>{
      setIsOpen(false)
    }
    let onClose2 =()=>{
      setIsOpen2(false)
    }
    let onClose3 =()=>{
      setIsOpen3(false)
    }
    let onClose4 =()=>{
      setIsOpen4(false)
    }
    let onClose5 =()=>{
      setIsOpen5(false)
    }
    let onClose6 =()=>{
      setIsOpen6(false)
    }
    let onClose7 =()=>{
      setIsOpen7(false)
    }
    let onClose8 =()=>{
      setIsOpen8(false)
    }
    let onClose9 =()=>{
      setIsOpen9(false)
    }
    let onClose10 =()=>{
      setIsOpen10(false)
    }
    let onClose11 =()=>{
      setIsOpen11(false)
    }
    let onClose12 =()=>{
      setIsOpen12(false)
    }
    let onClose13 =()=>{
      setIsOpen13(false)
    }



    return(
        <div>
            

           {/*Cards Here */}


            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start"  marginLeft={1} marginRight={1}>

              <Box>
              
              <HStack spacing={4}>
              <Heading as= "h1" size="2xl" color="#F4CE14" paddingTop={10}>
                Welcome, 
              </Heading>

              <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}>

                {(formik)=> (

                <Form>
              <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
              <Field as={Input} fontWeight="bold" size="xl" mt={10} readOnly style={style} name="name" value={initialValues.name} fontSize="3xl" onChange={handleChange}/>
              </FormControl>
                      
              </Form>

                )}

    
              </Formik>
          
              </HStack>
              <Text color="white" fontSize="2xl" mt={4}>Continue to order online today, tomorrow and beyond!</Text>
                </Box>

                <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} >
                      Our Menu
                   </Heading><br/>

                <Box>
                   <Box mt={-8}> 
                    <Heading as="h2" color="white">Appetizers</Heading>
                  </Box>
                    
                </Box>

               
                <Modal 
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover1A(true)} onMouseLeave={(e)=> sethover1A(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={crab} objectFit="cover" borderRadius={10} height={238} transform={hover1A && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Crab Artichoke</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">Indulge in a culinary delight with our
                          perfect blend of succulent crab meat and tender artichoke hearts, expertly crafted
                          to tantalize your taste buds.            
                          </Text><br/>
                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub} isDisabled={addSub == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                           addToCart({id: 1, name: "Crab Artichoke", image: crab,descp1:"Crab meat", descp2:"Tender artichoke hearts", price: 9.99 * addSub, qty: addSub})
                           handleCount()
                           }}>Add To Cart $9.99
                        </Button>
                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen2}
                onClose={onClose2}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover2A(true)} onMouseLeave={(e)=> sethover2A(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={spinach} objectFit="cover" borderRadius={10} height={238} transform={hover2A && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Spinach Balls</Heading>
                        
                          </HStack><br/>
                        <Text textAlign="left" paddingBottom={2}> These little bites are crafted with fresh spinach, 
                        savory herbs and a hint of garlic creating a crispy exterior that gives way 
                        to a soft, flavorful center.
                        </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd2} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub2}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub2} isDisabled={addSub2 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 2, name: "Spinach Balls", image: spinach,descp1:"Fresh spinach", descp2:"Savory herbs", price: 5.99 * addSub2, qty: addSub2})
                          handleCount2()

                          }}>Add To Cart $5.99</Button>

                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen3}
                onClose={onClose3}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover3A(true)} onMouseLeave={(e)=> sethover3A(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={Nachos} objectFit="cover" borderRadius={10} height={238} transform={hover2A && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Vegan Nachos</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">Dive into the acrumptious world of
                          our Nachos layered with a mix of black beans, fresh vegetables and a dairy-free cheese
                          that melts in every bite.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd3} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub3}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub3} isDisabled={addSub3 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 3, name: "Vegan Nachos", image: Nachos,descp1:"Black beans", descp2:"Fresh vegetables", price: 7.99 * addSub3, qty: addSub3})
                          handleCount3()
                        }}>Add To Cart $7.99
                        </Button>

                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>
                        

                <AppetizerMobPlaceholder setIsOpen={setIsOpen} setIsOpen2={setIsOpen2} setIsOpen3={setIsOpen3}/>

                <Box>
                   <Box mt={8}>
                    <Heading as="h2" color="white">Main Dishes</Heading>
                    </Box>
                </Box>

                <Modal 
                isOpen={isOpen4}
                onClose={onClose4}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover1M(true)} onMouseLeave={(e)=> sethover1M(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={Couscous} objectFit="cover" borderRadius={10} height={238} transform={hover1M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Couscous</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">Dive into a plate of our exquisite Couscous, 
                          infused with aromatic herbs and spices that transports you to the shores of the 
                          Mediterranean.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd4} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub4}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub4} isDisabled={addSub4 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 4, name: "Couscous", image: Couscous,descp1:"Aromatic herbs", descp2:"Spices", price: 10.99 * addSub4, qty: addSub4})
                          handleCount4()
                        }}>Add To Cart $10.99
                        </Button>

                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen5}
                onClose={onClose5}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover2M(true)} onMouseLeave={(e)=> sethover2M(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={mezze} objectFit="cover" borderRadius={10} height={238} transform={hover2M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Mezze</Heading>
                        
                          </HStack><br/>
                          <Text textAlign="left" paddingBottom={2}>Embark on an adventure with our Mezze platter, an array of
                          small bites that dance on your taste buds. Each dip and bite tell a story of flavors.
                          </Text><br/>
                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd5} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub5}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub5} isDisabled={addSub5 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                            addToCart({id: 5, name: "Mezze", image: mezze,descp1:"Small bites", descp2:"Flavors", price: 10.99 * addSub5, qty: addSub5})
                            handleCount5()
                          }}>Add To Cart $10.99</Button>

                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen6}
                onClose={onClose6}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover3M(true)} onMouseLeave={(e)=> sethover3M(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={paella} objectFit="cover" borderRadius={10} height={238} transform={hover3M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Paella</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">An authentic masterpiece that brings the spirit of Valencia
                          to your table. Each spoonful is a blend of saffron-infused rice, seafood and spices.
                        </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd6} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub6}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub6} isDisabled={addSub6 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 6, name: "Paella", image: paella,descp1:"Saffron-infused rice", descp2:"Seafood", price: 14.99 * addSub6, qty: addSub6})
                          handleCount6()
                        }}>Add To Cart $14.99
                        </Button>

                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen7}
                onClose={onClose7}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover4M(true)} onMouseLeave={(e)=> sethover4M(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={Ratato} objectFit="cover" borderRadius={10} height={238} transform={hover4M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Ratatouille</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">A vegetable medley that's as visually stunning as it's delicious.
                          Layers of perfectly-roasted vegetables, bathed in a tomato-based sauce.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd7} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub7}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub7} isDisabled={addSub7 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                           addToCart({id: 7, name: "Ratatouille", image: Ratato,descp1:"Roasted vegetables", descp2:"Tomato-based sauce", price: 11.99 * addSub7, qty: addSub7})
                           handleCount7()
                         }}>Add To Cart $11.99</Button>

                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen8}
                onClose={onClose8}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover5M(true)} onMouseLeave={(e)=> sethover5M(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={risotto} objectFit="cover" borderRadius={10} height={238} transform={hover5M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Risotto</Heading>
                        
                          </HStack><br/>
                          <Text textAlign="left" paddingBottom={2}>Wrap yourself in the embrace of our creamy italian classic that takes you on
                          a journey through Northern Italy. Arborio rice slow-cooked to perfection.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd8} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub8}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub8} isDisabled={addSub8 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 8, name: "Risotto", image: risotto,descp1:"Creamy italian classic", descp2:"Arborio rice", price: 12.99 * addSub8, qty: addSub8})
                          handleCount8()
                        }}>Add To Cart $12.99
                        </Button>
                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen9}
                onClose={onClose9}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover6M(true)} onMouseLeave={(e)=> sethover6M(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={shak} objectFit="cover" borderRadius={10} height={238} transform={hover6M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Shakshuka</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">Energize your day with our North-African delight that's a burst of flavors.
                          Poached eggs in a tomato and pepper stew create a 
                          breakfast experience.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd9} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub9}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub9} isDisabled={addSub9 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 9, name: "Shakshuka", image: shak,descp1:"Poached eggs", descp2:"Tomato and pepper stew", price: 12.99 * addSub9, qty: addSub9})
                          handleCount9()
                        }}>Add To Cart $12.99
                        </Button>
                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen10}
                onClose={onClose10}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover7M(true)} onMouseLeave={(e)=> sethover7M(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={grilledFish} objectFit="cover" borderRadius={10} height={238} transform={hover7M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Grilled Fish</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">Our Grilled Fish adorned with a harmonious blend of herbs and spices, 
                          is expertly grillled to achieve a succulent and flaky texture.
                          The addition of citrusy notes from lemon and lime enhances the dish, providing a refreshing and vibrant touch.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd10} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub10}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub10} isDisabled={addSub10 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 10, name: "Grilled Fish", image: grilledFish,descp1:"Herbs", descp2:"Spices and lime", price: 6.99 * addSub10, qty: addSub10})
                          handleCount10()
                        }}>Add To Cart $6.99
                        </Button>
                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>

                <MainMobPlaceholder setIsOpen4={setIsOpen4} setIsOpen5={setIsOpen5} setIsOpen6={setIsOpen6} setIsOpen7={setIsOpen7} setIsOpen8={setIsOpen8} setIsOpen9={setIsOpen9} setIsOpen10={setIsOpen10}/>

                <Box>
                   <Box mt={8}>
                    <Heading as="h2" color="white">Dessert</Heading>
                    </Box>
                </Box>


                <Modal 
                isOpen={isOpen11}
                onClose={onClose11}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover1D(true)} onMouseLeave={(e)=> sethover1D(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={greeksalad} objectFit="cover" borderRadius={10} height={238} transform={hover1D && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Greek Salad</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={4} textAlign="left">The famous greek salad of crispy
                          lettuce, peppers,  olives and our Chicago style chesse,
                          garnished with crunchy garlic and rosemary
                          croutone.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd11} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub11}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub11} isDisabled={addSub11 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                          addToCart({id: 11, name: "Greek Salad", image: greeksalad,descp1:"Crispy lettuce", descp2:"Crunchy garlic", price: 12.99 * addSub11, qty: addSub11})
                          handleCount11()
                        }}>Add To Cart $12.99</Button>
                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen12}
                onClose={onClose12}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover2D(true)} onMouseLeave={(e)=> sethover2D(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={bruschetta} objectFit="cover" borderRadius={10} height={238} transform={hover1D && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Bruchetta</Heading>
                        
                          </HStack><br/>
                          <Text textAlign="left" paddingBottom={2}>Our Bruchetta is made from grilled
                          bread that has been smeared with garlic and seasoned with salt and olive
                          oil.
                          </Text><br/>

                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd12} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub12}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub12} isDisabled={addSub12 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                           addToCart({id: 12, name: "Bruchetta", image: bruschetta, descp1:"Grilled bread", descp2:"Salt and olive oil", price: 7.99 * addSub12, qty: addSub12})
                           handleCount12()
                         }}>Add To Cart $7.99</Button>
                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>


                <Modal 
                isOpen={isOpen13}
                onClose={onClose13}
                closeOnOverlayClick={false}>
                  <ModalOverlay>
                    <ModalContent>
                    <ModalHeader mt={-10}>
                    </ModalHeader>

                      <ModalBody >
                      
                      <Card overflow="hidden" marginRight={-6} marginLeft={-6} marginBottom={-10} onMouseEnter={(e)=> sethover3D(true)} onMouseLeave={(e)=> sethover3D(false)} borderRadius={10} cursor="pointer">
                       
                        <Image src={dessert} objectFit="cover" borderRadius={10} height={238} transform={hover3D && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
                        <ModalCloseButton _hover={{bg: "#18181b", color:"white"}} fontWeight="bold" borderRadius="50%" bg="blue.600" color="white" mt={4}/>

                        <Box p={8} background="#EDEFEE">
                          <HStack spacing={110}>
                        <Heading as="h3" size="2xl">Lemon Dessert</Heading>
                        
                          </HStack><br/>
                          <Text paddingBottom={2} textAlign="left">This comes straight from grandma's recipe book,
                          every last ingredient has been sourced and is as authentic
                          as can be imagined.
                          </Text><br/>
                        <HStack mt={-6} alignItems="start">
                        <FontAwesomeIcon icon={faPersonBiking}/>
                        <Heading as="h3" size="sm"><span style={{fontWeight: "normal"}}>Delivery time:</span> 20 minutes</Heading>
                        </HStack>
                        <Divider bg="blue.600" h={1} width="100vw" ml={-8} />

                        <HStack spacing={4} justifyContent="center" mt={10}>
                        <Heading as="h4" fontSize="lg">Qty.</Heading>
                        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
                          <IconButton margin={1} size="sm" onClick={handleAdd13} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
                          <Text fontWeight="bold" marginBottom={0}>{addSub13}</Text>
                          <IconButton margin={1} size="sm" onClick={handleSub13} isDisabled={addSub13 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
                        </HStack>
                         
                        </HStack>
                        <Button mt={5} as="h3" width="full" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                           addToCart({id: 13, name: "Bruchetta", image: bruschetta, descp1:"Grilled bread", descp2:"Salt and olive oil", price: 6.00 * addSub13, qty: addSub13})
                           handleCount13()
                         }}>Add To Cart $7.99</Button>
                        </Box>
                      </Card>
                         
                      </ModalBody>
                      <ModalFooter>
                      </ModalFooter>
                    </ModalContent>
                  </ModalOverlay>
                </Modal>

                <DessertMobPlaceholder setIsOpen11={setIsOpen11} setIsOpen12={setIsOpen12} setIsOpen13={setIsOpen13} />

  
              </VStack>
                 
            </Box>
      


      </div>
    )
}

export default MenuDashMob