import { Heading,Box, Tooltip, Flex, Center, Button, Text, HStack, VStack, Card, Image, Container, useBreakpointValue, Collapse, chakra, Avatar, position, Divider, AvatarGroup } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom' // Don't just use any Link, use the Link from react-router-Doms
import {Link as ChakraLink} from "@chakra-ui/react"
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Accordion, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon } from "@chakra-ui/react";
import CookieConsent from "react-cookie-consent"


import React, {useState, useRef} from 'react'
import restaurant from "./Assets/restaurant.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import grilledFish from "./Assets/grilledFish.jpg"
import dessert from "./Assets/dessert.jpg"
import spinach from "./Assets/spinach.webp"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import customer1 from "./Assets/customer1.jpg"
import customer2 from "./Assets/customer2.jpg"
import customer3 from "./Assets/customer3.jpg"
import customer4 from "./Assets/customer4.jpg"
import profile1 from "./Assets/profile1.jpeg"
import profile2 from "./Assets/profile2.jpg"

import istock from "./Assets/istock.jpg"
import photo from "./Assets/photo.jpg"

import Chef from "./Assets/Chef.jpg"

import kphoto from "./kphoto.jpg"
import Mains from "./Mains"
import Appetizers from "./Appetizers"
import Desserts from "./Desserts";
import MainMob from "./MainMob";
import AppetizerMob from "./AppetizerMob";
import DessertMob from "./DessertMob ";

import ChatbotCompo from "./ChatbotCompo";

function Landing({selected, setSelected}){

  let [faqCollapse, setfaqCollapse] = useState(true)
 

  let faqs = [
    
    {
      question: "What are your opening hours?",
      answer: "We are open from 11:00 AM to 10:00 PM every weekday."
    },

    {
      question: "Do you offer delivery services?",
      answer: "Yes we provide delivery services within a 5-mile radius."
    },

    {
      question: "Are reservations required?",
      answer: "Reservations are recommended, especially during peak hours."
    },

    {
      question: "How can I make a reservation for a large group?",
      answer: "To make a reservation for a large group, please contact our reservations team at least one week in advance. You can reach them at [reservation@littlelemon.com] or call (312) 593-2744."
    },

    {
      question: "What payment methods do you accept?",
      answer: "We accept various payment methods, including credit cards (Visa, MasterCard, American Express), debit cards, paypal, and cash. Unfortunately, we do not accept personal checks."
    },

    {
      question:"Is your restaurant pet-friendly?",
      answer:"While we love pets, we do not allow them in the dining area. However, we have outdoor seating available where you can enjoy your meal with your furry friends."
    },

    {
      question: "Can I customize my dish based on dietary restrictions?",
      answer: "Absolutely! We understand the importance of dietary restrictions. Please inform your server of any allergies or dietary preferences, and our kitchen will do their best to accomodate your needs."
    },

    {
      question: "Do you offer gift cards?",
      answer: "Yes we offer gift cards in various denominations. You can purchsae them at our restaurant or through our online store. Gift cards make for perfect gifts for family and friends."
    },

    {
      question: "What is your corkage fee for bringing our own wine?",
      answer: "We allow guests to bring their own wine. Our corkage fee is $15 per bottle. Please note that we also offer an extensive wine list for you to choose from. "
    },

    {
      question: "Are you open on holidays?",
      answer: "Yes, we are open on most holidays. However, it's advisable to check our website or contact us for specific holiday hours and special menus."
    },

    {
      question: "Can I host a private event at your restaurant?",
      answer: "Certainly! We have a private event spaces available for various occasion. Contact our events team to discuss your requirements and to plan a memorable event with us."
    },

    {
      question: "Is there parking available?",
      answer: "Yes, we provide complimentary parking for our guests. Our parking lot is conveniently located near the restaurant entrance for your convenience."
    },

    {
      question: "How do I provide feedback about my dining experience?",
      answer: "We appreciate your feedback! You can share your thoughts with us by filling out our online feedback form on our website. Alternatively, feel free to speak with our manager on duty during your visit. We value your input as we strive to enhance our guest experience. "
    }

  ]


    
let faq2 = [
    {
      question: "How long is the average wait time for a table during peak hours?",
      answer: "Our wait times vary, but we strive to seat quests as quickly as possible. You can also make a reservation to avoid waiting. Check our real-time waitlist on our website or make a reservation to secure your table in advance."
    },
    {
      question: "What is your reservation cancellation policy?",
      answer: "We understand plans can change. Our reservation cancellation policy allows for cancellation up to 24 to 48 hours before your scheduled reservation. Please refer to our website or contact us for more information on our cancellation policy and any associated fees."
    },
    {
      question: "I think I left something at your restaurant. Do you have a lost and found?",
      answer: "Absolutely, we have a dedicated lost and found service to assist you. Please contact our team at [lostandfound@littlelemon.com] or call (312) 593-2744, providing a detailed description of the lost item. We'll do our best to locate and return it to you."
    },
    {
      question: "Is there a dress code for dining at your restaurant?",
      answer: "While we maintain a relaxed atmosphere, we recommend smart-casual attire to enhance the overall dining experience. This typically includes well-groomed, neat, and comfortable clothing.",
    },
    {
      question: "Is Wi-Fi available for quests at your restaurant?",
      answer: "Certainly! We provide complimentary Wi-Fi to ensure you stay connected during your visit. Simply ask your server for the network name and password, and enjoy seamless connectivity."
    },
    {
      question: "Do you have a loyalty program for regular customers?",
      answer: "Yes, we value our loyal customers. Our loyalty program rewards you for your continued patronage. To learn more and sign up, visit our website or inquire with our staff duriong your next visit."
    },
    {
      question: "How can I contact your restaurant for additional inquiries or assistance?",
      answer: "For any further questions or assistance, you can reach us at [support@littlelemon.com] or call our main line at (312) 593-2744. Our team is ready to assist you during our operating hours."
    },
    {
      question: "How can I stay updated on the latest news, promotions, and events at your restaurant?",
      answer: "Stay in the loop by subscribing to our newsletter on our website. You'll receive regular updates on special promotions, new menu items, and upcoming events. Additionally, follow us on social media platforms for real-time announcements and behind-the-scenes content."
    }
  ]


  let [collapse, setCollapse] = useState(true)
  let [collapse2, setCollapse2] = useState(true)
  let [collapse3, setCollapse3] = useState(true)
  let [collapse4, setCollapse4] = useState(true)

  
  let [hover, sethover] = useState(false)
  let [hover2, sethover2] = useState(false)
  let [hover3, sethover3] = useState(false)
  let [hover4, sethover4] = useState(false)
  let [hover5, sethover5] = useState(false)
  let [hover6, sethover6] = useState(false)

  let [mains, setMains ] = useState(false)
  let [appetizers, setAppetizers] = useState(false)
  let [desserts, setDesserts] = useState(false)
  let [carte, setCarte] = useState(true)


 
let mobileView = useBreakpointValue({
    base: true,
    lg: false
})


let tabletView = useBreakpointValue({
  md: true
})


if (mobileView){
    return(
        <div>
        <Container p={5} maxWidth={tabletView && "full"} minHeight="40vh" background="#495E57" postion="relative" id="landing-section">
          
          <VStack spacing={5} alignItems="start">
          
          <Box>
          <Heading as= "h1" size="3xl" color="#F4CE14" >
            Little Lemon
          </Heading>
          <Heading as="h2" color="white">Chicago</Heading>
            </Box>

          <Text color="white" fontSize={tabletView && "2xl"}>We are a family owned <br/>
            Mediterranean restaurant, <br/>
            focused on traditional <br/>
            recipes served with a <br/>
            modern twist.

          </Text>

          <Link to="/reservation">
          <Button  onClick={(e)=> setSelected("Reservation")} style={{background: selected == "Reservation"? "#495E57" : "", color: selected == "Reservation"? "white": "" }} borderRadius={15} marginTop={5} marginBottom={5} background="#F4CE14" fontWeight="bold">Reserve a Table</Button>
          </Link>
    
          </VStack>
          
        
          <Slider style={{width: tabletView? "350px": "160px", position:"absolute", top:tabletView? "560px": "360px", right:tabletView? "20px": "10px", transform:"translate(0.5%, -97%)"}} arrows={false} fade autoplay autoplaySpeed={5000} speed={2000} >
          <div >
          <Image src={restaurant} width={tabletView? 350: 160} height={tabletView? 350: 160} objectFit="cover" borderRadius={10}/>
          </div>

          <div >
          <Image src={kphoto} width={tabletView? 350: 160} height={tabletView? 350: 160}  objectFit="cover" borderRadius={10}/>
          </div>

          <div>
          <Image src={photo} width={tabletView? 350: 160} height={tabletView? 350: 160}  objectFit="cover" borderRadius={10}/>
          </div>

          <div >
          <Image src={istock} width={tabletView? 350: 160} height={tabletView? 350: 160}  objectFit="cover" borderRadius={10}/>
          </div>

          <div >
          <Image src={Chef} width={tabletView? 350: 160} height={tabletView? 350: 160}  objectFit="cover" borderRadius={10}/>
          </div>

          </Slider>
        

          

        </Container><br/>
        
        <h2 style={{margin: 5, paddingLeft: 20, fontWeight:"bold"}}> {carte? "THIS WEEKS SPECIALS!" : "ORDER FOR DELIVERY!"}</h2>
       
        <Box style={{overflowX: "auto"}}>
        <VStack alignItems="start" margin={5} >
            <HStack spacing={4}>
            
            <Button fontWeight="bold">Lunch</Button>
            <Button fontWeight="bold" onClick={(e) => {
              setAppetizers(true) 
              setMains(false)
              setDesserts(false)
              setCarte(false)
              }}>
              Starters</Button>
            <Button fontWeight="bold" onClick={(e) => {
              setMains(true)
              setAppetizers(false)
              setDesserts(false)
              setCarte(false)
              }}>
                Mains</Button>
            <Button fontWeight="bold" onClick={(e) => {
              setDesserts(true)
              setMains(false)
              setAppetizers(false)
              setCarte(false)
            }}>Desserts</Button>
            <Button fontWeight="bold" onClick={(e)=> {
              setCarte(true)
              setMains(false)
              setAppetizers(false)
              setDesserts(false)

            }}>A La Carte</Button>

            <Button fontWeight="bold">Sides</Button>

            </HStack>
         </VStack>
         </Box>

         {mains && 
        <MainMob />
        
        }

        {appetizers && 
        <AppetizerMob />
        
        }
         
        {desserts && 
        <DessertMob />
    
        }
         
   
         {carte &&     <Container maxWidth={tabletView && "full"} style={{overflowY: "auto" }} height="60vh"> 
         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse? (tabletView? 20:0): 8}}>Greek Salad</h3>
         <Flex  fontSize={tabletView && "2xl"}  overflow="hidden" >
            <Text flex="1" >The famous greek salad of crispy
            lettuce, peppers, olives and our Chicago<Collapse in={!collapse}><Box paddingBottom={8}> style chesse,
            garnished with crunchy garlic and rosemary
            croutone.</Box></Collapse>
            </Text>
            
            <Image src={greeksalad} width={tabletView? 200:100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}} height={tabletView? 200:100}/>
              
         </Flex>
         <Text  fontSize={tabletView && "2xl"}  marginTop={collapse? (tabletView? "-20": "-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse(!collapse)}> 
          {collapse? "See More": "See Less"} {collapse? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse(!collapse)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse)}/>}
         </Text>
         <Text  fontSize={tabletView && "2xl"}  marginTop="-3" fontWeight="bold">$12.99</Text>
         
         </VStack>
 

         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse2? (tabletView? 20:0): 8}}>Bruchetta</h3>
         <Flex  fontSize={tabletView && "2xl"}  overflow="hidden">
            <Text  fontSize={tabletView && "2xl"}  flex="1">Our Bruchetta is made from grilled
            bread that has been smeared with garlic and<Collapse in={!collapse2}><Box paddingBottom={8}> seasoned with salt and olive
            oil.</Box></Collapse>
            </Text>

            <Image src={bruschetta} width={tabletView? 200:100} height={tabletView? 200:100}  _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>
         
         </Flex>
         <Text  fontSize={tabletView && "2xl"}  marginTop={collapse2? (tabletView? "-20":"-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse2(!collapse2)}> 
           {collapse2? "See More" : "See Less"} {collapse2? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse2(!collapse2)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse2)}/>}
         </Text>
         <Text  fontSize={tabletView && "2xl"}  marginTop="-3" fontWeight="bold">$7.99</Text>
         </VStack>


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse3? (tabletView? 20:0): 8}}>Lemon Dessert</h3>
         <Flex  fontSize={tabletView && "2xl"}  overflow="hidden">
            <Text flex="1" >This comes straight from grandma's recipe book,
            every last ingredient has <Collapse in={!collapse3}><Box paddingBottom={8}> been sourced and is as authentic
            as can be imagined.</Box></Collapse>
            </Text>

            <Image src={dessert} width={tabletView? 200:100} height={tabletView? 200:100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </Flex>
         <Text  fontSize={tabletView && "2xl"}  marginTop={collapse3? (tabletView? "-20": "-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse3(!collapse3)}> 
           {collapse3? "See More" : "See Less"} {collapse3? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse3(!collapse3)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse3(!collapse3)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"}  marginTop="-3" fontWeight="bold">$6.00</Text>
         </VStack>


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse4? (tabletView? 20:0) : 8}}>Grilled Fish</h3>
         <Flex overflow="hidden">
            <Text fontSize={tabletView && "2xl"}  flex="1" >Our Grilled Fish adorned with a harmonious blend of herbs and spices, 
            is expertly grillled to achieve a<Collapse in={!collapse4}><Box paddingBottom={8}>succulent and flaky texture.
            The addition of citrusy notes from lemon and lime enhances the dish, providing a refreshing and vibrant touch.</Box></Collapse>
            </Text>

            <Image src={grilledFish} width={tabletView? 200:100} height={tabletView? 200:100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </Flex>
         <Text fontSize={tabletView && "2xl"}  marginTop={collapse4? (tabletView? "-20":"-3") : "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse4(!collapse4)}> 
           {collapse4? "See More" : "See Less"} {collapse4? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse4(!collapse4)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse4(!collapse4)}/>}
         </Text>
         <Text fontSize={tabletView && "2xl"}  marginTop="-3" fontWeight="bold">$6.00</Text>
         </VStack>    
        
    </Container> }


   
    <Container background= '#495E57' maxWidth="full" p={15}>
        <h2 style={{margin: 5, paddingLeft: 20, paddingBottom: 60, paddingTop: 20, fontWeight:"bold", color: "white"}}>What people say about us!</h2>

          <Box style={{display: "grid", gridTemplateColumns: tabletView? "1fr 1fr" :"1fr", gap: 20, paddingLeft: 20, paddingRight: 20, paddingBottom: 50}}>

          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer4} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Brandon Ming</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>A</span> delightful dining experience! The attention to dietary preferences was a pleasant surprise. The flavors were exquisite and 
            the service were top-notch. I can't wait to return and savor more culinary delights. Highly<span style={{marginRight: 5}}> recommend!</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>


          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer1} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Maria Sanchez</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Absolutely</span> impressed! The dessrt selection was divine; I couldn't resist trying multiple selection. The ambiance, the menu and the attention to detail 
            exceeded my expectations. Each dish felt like a work of art. Kudos to the chef
            and the wonderful <span style={{marginRight: 5}}>staff!.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>


          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer2} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Anthony Clifton</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>An</span> unforgettable feast for the senses! The quality of ingredients 
            paired with creative presentation, made for a dining experience like no other. The staff's passion for 
            hospitality truly shines. Can't wait for my next <span style={{marginRight: 5}}>visit!.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>


          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer3} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Melodie</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>A</span> hidden gem! From the moment I walked in, I felt warmly welcomed. The food was a 
            was a dymphony of tastes and the staff went above and beyond to make our evening memorable. Will be spreading the word about this 
            fantastic<span style={{marginRight: 5}}> place!.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>
          </Box>
          

        </Container>


        <Container paddingBottom= {50} maxWidth={tabletView && "full"}>

            
        <Center>
        <h4 style={{margin: 5, paddingTop: 20, fontWeight:"bold", color: "#F4CE14"}}>Support</h4>
          </Center>

        <Center>
        <h2 style={{margin: 5, paddingTop: 10, fontWeight:"bold"}}>Frequently Asked Questions (FAQs)</h2>
          </Center>

          <Center>
        <Text fontSize="xl" style={{margin: 5, paddingBottom: 60, paddingTop: 20}}> Everything you need to know about the menu and billing. For questions about privacy policy, please see our <Link to="/privacyPolicy" style={{color: "#F4CE14", textDecoration: "underline", justifyContent: "center"}}>privacy policy page</Link>.</Text>
          </Center>


          <Accordion maxW="full" allowMultiple={false} allowToggle={true}>
          {faqs.map((faq, index)=> {
          return <AccordionItem key={index} >
             
            <AccordionButton mt={5} _expanded={{background: "tomato", color:"white"}} fontSize="xl" justifyContent="space-between" bg="#EDEFEE" fontWeight="bold">
              {faq.question}
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={5} fontSize="xl">{faq.answer}</AccordionPanel>

          </AccordionItem>
          })}


           <Collapse in={!faqCollapse}>
            {faq2.map((f, index)=>{
            return <AccordionItem key={index}>
                
                <AccordionButton mt={5} _expanded={{bg: "tomato", color: "white"}} fontSize="xl" justifyContent="space-between" bg="#EDEFEE" fontWeight="bold">
                  {f.question}
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={5} fontSize="xl"> {f.answer} </AccordionPanel>

              </AccordionItem>
            })}
            </Collapse>
        
        </Accordion>
          
          <Center>
          <Button mt={10} onClick={(e)=> setfaqCollapse(!faqCollapse)} fontWeight="bold" variant="outline">{faqCollapse? "Show more FAQs": "Show less FAQs"}</Button>
          </Center>

        </Container>

       
        <Container mb={20} paddingBottom={50} maxW="full" background="#EDEFEE" borderRadius={15}>
          <Center>
            <AvatarGroup mt={10}>
              <Avatar name="Schroter" size="2xl" src={profile1}/>
              <Avatar name="Tim" size="2xl" src={profile2}/>
            </AvatarGroup>
          </Center>

          <Center>
          <Heading as="h2" margin={5} paddingTop={8} >Still have questions?</Heading>
          </Center>
          <Center>
          <Text fontSize="xl" marginLeft={5} mt={-2} >Can't find the answer you're looking for? Our team is happy to answer your questions.</Text>
          </Center>

           <Center>
            <Button as={ChakraLink} href="mailto: support@littlelemon.com" isExternal style={{textDecoration: "none"}} mt={5} mb={-2} width={!tabletView && "full"} fontWeight="bold" bg="#F4CE14"  paddingLeft={10} paddingRight={10}> Send Us an Email</Button>
          </Center>
      
        </Container>

 
        <Container maxWidth={tabletView && "full"} paddingBottom={50}  background="#495E57">

            <Center>
              <Heading as="h2" color="white" margin={5} paddingTop={8}>Ready to get started?</Heading>
            </Center>

            <Center>
              <Text fontSize="2xl" color="white" marginLeft={5} >We are always ready to fulfill your demand.</Text>
            </Center>

            <Link to="/menu">
            <Center>
              <Button width={!tabletView && "full"} fontWeight="bold" background="transparent" border="2px solid #F4CE14" color="#F4CE14" _hover={{color: "black", background: "#EDF2F7"}} mt={5} borderRadius={20}>Explore Online Menu</Button>
            </Center>
            </Link>


        </Container>

        <ChatbotCompo  />


        <CookieConsent expires={150} cookieName="Little Lemon Cookie">This website uses cookies to enhance the user experience. We use cookies to improve site functionality and deliver personalized<br/> 
        content. By continuing to browse the site, you consent to our use of cookies. Read our <Link to="/privacyPolicy" style={{color: "#F4CE14",fontWeight:"bold", textDecoration: "underline"}}>Privacy Policy</Link> for more information.
        </CookieConsent>
            
            
        </div>
    )
    
}

else{
  return(
    <div>
       <Container p={5} minHeight="40vh" maxWidth="full" background="#495E57" postion="relative" id="landing-section">
          
          <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={20}>
          
          <Box>
          <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10}>
            Little Lemon
          </Heading>
          <Heading as="h2" color="white">Chicago</Heading>
            </Box>

          <Text color="white" fontSize="2xl">We are a family owned <br/>
            Mediterranean restaurant, <br/>
            focused on traditional <br/>
            recipes served with a modern <br/>
            twist.

          </Text>

          <Link to="/reservation" >
          <Button onClick={(e) => setSelected("Reservation")} borderRadius={15} marginTop={1} marginBottom={5} background="#F4CE14" fontWeight="bold">Reserve a Table</Button>
          </Link>
    
          </VStack>

          <Slider style={{width:"450px", position:"absolute", top:"510px", right:"20px", transform:"translate(-10%, -78%)"}} fade autoplay autoplaySpeed={5000} speed={2000} >
          <div >
          <Image src={restaurant} width={450} height={480} objectFit="cover" borderRadius={10}/>
          </div>

          <div >
          <Image src={kphoto} width={450} height={480} objectFit="cover" borderRadius={10}/>
          </div>

          <div>
          <Image src={photo} width={450} height={480} objectFit="cover" borderRadius={10}/>
          </div>

          <div >
          <Image src={istock} width={450} height={480} objectFit="cover" borderRadius={10}/>
          </div>

          <div >
          <Image src={Chef} width={450} height={480} objectFit="cover" borderRadius={10}/>
          </div>

          </Slider>

          

        </Container>

        <HStack spacing={10} marginTop={35} paddingTop={20}>
        <h2 style={{margin: 5, paddingLeft: 40, fontWeight:"bold", marginLeft: 80}}> {carte? "THIS WEEKS SPECIALS!" : "ORDER FOR DELIVERY!"}</h2>
        <Button onClick={(e) => setSelected("Menu")} borderRadius={10} p={6} marginLeft={550} background="#F4CE14" fontWeight="bold" as={Link} to="/menu">Online Menu</Button>
        </HStack>
        
        <VStack alignItems="start" margin={5} marginLeft={30} paddingLeft={20} style={{overflowX: "auto"}}>
         
            <HStack spacing={4}>
            <Button fontWeight="bold">Lunch</Button>
            <Button fontWeight="bold" onClick={(e) => {
              setAppetizers(true) 
              setMains(false)
              setDesserts(false)
              setCarte(false)
              }}>
              Starters</Button>
            <Button fontWeight="bold" onClick={(e) => {
              setMains(true)
              setAppetizers(false)
              setDesserts(false)
              setCarte(false)
              }}>
                Mains</Button>
            <Button fontWeight="bold" onClick={(e) => {
              setDesserts(true)
              setMains(false)
              setAppetizers(false)
              setCarte(false)
            }}>Desserts</Button>
            <Button fontWeight="bold" onClick={(e)=> {
              setCarte(true)
              setMains(false)
              setAppetizers(false)
              setDesserts(false)

            }}>A La Carte</Button>

            <Button fontWeight="bold">Sides</Button>

            </HStack>
        </VStack><br/>


        {mains && 
        <Mains />
        
        }

        {appetizers && 
        <Appetizers />
        
        }
         
        {desserts && 
        <Desserts />
        
        }
        
        
        
        
        
        
      {/*Cards Here */}
 {carte &&     <Carousel infiniteLoop>
        <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 40, paddingLeft: 100, paddingRight: 100, paddingBottom: 100}}>
          
          <Card style={{boxShadow: hover4 && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)} overflow="hidden" borderRadius={10} cursor="pointer">
            <Image src={greeksalad} borderRadius={10} transform= {hover4 && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack justifyContent="space-between" >
            <Heading as="h3" size="md">Greek Salad</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">The famous greek salad of crispy
            lettuce, peppers,  olives and our Chicago style chesse,
            garnished with crunchy garlic and rosemary
            croutone.
            </Text><br/>
            <HStack spacing={4}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>


          <Card overflow="hidden" onMouseEnter={(e)=> sethover5(true)} onMouseLeave={(e)=> sethover5(false)} style={{boxShadow: hover5 && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={bruschetta} borderRadius={10} height={238} transform={hover5 && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
            <Box p={8} background="#EDEFEE">
              <HStack spacing={40}>
            <Heading as="h3" size="md">Bruchetta</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$5.99</Text>
              </HStack><br/>
            <Text textAlign="left">Our Bruchetta is made from grilled
            bread that has been smeared with garlic and seasoned with salt and olive
            oil.
            </Text><br/>
            <HStack spacing={4} paddingTop={10} marginTop={1} >
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
            </Box>
          </Card>


          <Card overflow="hidden" onMouseEnter={(e)=> sethover6(true)} onMouseLeave={(e)=> sethover6(false)} style={{boxShadow: hover6 && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={dessert} height={238} borderRadius={10} objectFit="cover" transform={hover6 && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack spacing={100}>
            <Heading as="h3" size="md">Lemon Dessert</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">This comes straight from grandma's recipe book,
            every last ingredient has been sourced and is as authentic
            as can be imagined.
            </Text><br/>
            <HStack spacing={4}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>

        </Container>

        {/*Cards end here*/}
      


        {/*Second section starts Here*/}
        <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 40, paddingLeft: 100, paddingRight: 100, paddingBottom: 100}}>
          
          <Card overflow="hidden" style={{boxShadow: hover && "0px 0px 50px rgba(0, 0, 0, 0.5"}} borderRadius={10} cursor="pointer" onMouseEnter={(e)=> sethover(true)} onMouseLeave={(e)=> sethover(false)} >
            <Image src={spinach} borderRadius={10} height={238} objectFit="cover" transform= {hover && "scale(1.1)"}  transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack spacing={165}>
            <Heading as="h3" size="md">Spinach</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">Experience the vibrant flavors of our spinach dish,
            a tantalizing blend of fresh, nutrient-packed spinach leaves expertly sauteed to perfection. 
            Complemented by a medley of aromatic herbs and spices.
            </Text><br/>
            <HStack spacing={4} marginTop={-6}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>


          <Card style={{boxShadow: hover2 && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover2(true)} onMouseLeave={(e)=>sethover2(false)} borderRadius={10} overflow="hidden" cursor="pointer">
            <Image src={Nachos} borderRadius={10} height={238} objectFit="cover" transform= {hover2 && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
            <Box p={8} background="#EDEFEE">
              <HStack justifyContent="space-between">
            <Heading as="h3" size="md">Vegan Nachos</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$7.99</Text>
              </HStack><br/>
            <Text textAlign="left">Savor the bold flavors of our vegan Nachos, a delightful
            medley of crispy tortilla chips generously topped with plant based cheese, fresh 
             and guacamole
            </Text><br/>
            <HStack spacing={4} paddingTop={5} marginTop={-6} >
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
            </Box>
          </Card>


          <Card overflow="hidden" onMouseEnter={(e)=> sethover3(true)} onMouseLeave={(e)=> sethover3(false)} style={{boxShadow: hover3 && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={Couscous} height={238} borderRadius={10} objectFit="cover" transform= {hover3 && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
            <Box p={8} background="#EDEFEE">
              <HStack spacing={150}>
            <Heading as="h3" size="md">Couscous</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$10.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">Indulge in the delightful blend of tradition and flavor with our 
            Couscous dish. Each tender grain is meticulously steamed to perfection, creating a light and fluffy texture.
            </Text><br/>
            <HStack spacing={4} marginTop={-6}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>

        </Container>



        </Carousel>
          }
      

        <Container background="#495E57" maxWidth="full" p={15}>
        <h2 style={{margin: 5, paddingLeft: 30, paddingBottom: 60, paddingTop: 20, fontWeight:"bold", marginLeft: 80, color: "white"}}>What people say about us!</h2>

          <Box style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, paddingLeft: 100, paddingRight: 100, paddingBottom: 50}}>

          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer4} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Brandon Ming</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>A</span> delightful dining experience! The attention to dietary preferences was a pleasant surprise. The flavors were exquisite and 
            the service were top-notch. I can't wait to return and savor more culinary delights. Highly<span style={{marginRight: 5}}> recommend!</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>


          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer1} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Maria Sanchez</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>Absolutely</span> impressed! The dessrt selection was divine; I couldn't resist trying multiple selection. The ambiance, the menu and the attention to detail 
            exceeded my expectations. Each dish felt like a work of art. Kudos to the chef
            and the wonderful <span style={{marginRight: 5}}>staff!.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>


          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer2} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Anthony Clifton</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStarHalf} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>An</span> unforgettable feast for the senses! The quality of ingredients 
            paired with creative presentation, made for a dining experience like no other. The staff's passion for 
            hospitality truly shines. Can't wait for my next <span style={{marginRight: 5}}>visit!.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>


          <Card borderRadius={15}>
            <Center>
              <VStack>
            <Avatar src={customer3} name="Brandon" mt={5} size="2xl"/>
            <Heading as="h3" size="md" mt={2}>Melodie</Heading> 
            
            <HStack spacing={0} mt={5} marginRight="auto" paddingLeft={5}>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>
            <FontAwesomeIcon icon={faStar} color="#F4CE14"/>

            </HStack>
            <Text margin={5} marginTop={0}>
            <FontAwesomeIcon icon={faQuoteLeft} color="#EE9972"/>
            <span style={{marginLeft: 5}}>A</span> hidden gem! From the moment I walked in, I felt warmly welcomed. The food was a 
            was a dymphony of tastes and the staff went above and beyond to make our evening memorable. Will be spreading the word about this 
            fantastic<span style={{marginRight: 5}}> place!.</span>
            <FontAwesomeIcon icon={faQuoteRight} color="#EE9972"/>
            </Text>

              </VStack>
            </Center>
          </Card>
          </Box>
          

        </Container>


        <Container maxW="full"  paddingLeft= {100} paddingRight= {100} paddingBottom= {50}>

        <Center>
        <h4 style={{margin: 5, paddingTop: 20, fontWeight:"bold", color: "#F4CE14"}}>Support</h4>
          </Center>

        <Center>
        <h2 style={{margin: 5, paddingTop: 10, fontWeight:"bold"}}>Frequently Asked Questions (FAQs)</h2>
          </Center>

          <Center>
        <Text fontSize="xl" style={{margin: 5, paddingBottom: 60, paddingTop: 20}}> We have provided everything you need to know about the menu and billing. For questions about privacy policy, please see our <Center><Link to="/privacyPolicy" style={{color: "#F4CE14", textDecoration: "underline", justifyContent: "center"}}>privacy policy page</Link>.</Center></Text>
          </Center>


          <Accordion allowMultiple={false} allowToggle={true}>
            {faqs.map((faq, index) =>{
          return <AccordionItem key={index}>
              
              <AccordionButton mt={5} _expanded={{bg: "tomato", color: "white"}} fontSize="xl" justifyContent="space-between" bg="#EDEFEE" fontWeight="bold">              
                  {faq.question}
                <AccordionIcon />
              </AccordionButton>
              

            <AccordionPanel pb={5} fontSize="xl"> {faq.answer} </AccordionPanel>

              </AccordionItem>
            })}

            
            <Collapse in={!faqCollapse}>
            {faq2.map((f, index)=>{
            return <AccordionItem key={index}>
                
                <AccordionButton mt={5} _expanded={{bg: "tomato", color: "white"}} fontSize="xl" justifyContent="space-between" bg="#EDEFEE" fontWeight="bold">
                  {f.question}
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={5} fontSize="xl"> {f.answer} </AccordionPanel>

              </AccordionItem>
            })}
            </Collapse>

          </Accordion>
          
          <Center>
          <Button mt={10} onClick={(e)=> setfaqCollapse(!faqCollapse)} fontWeight="bold" variant="outline">{faqCollapse? "Show more FAQs": "Show less FAQs"}</Button>
          </Center>

        </Container>


        <Container mb={20} paddingBottom={50} maxW="full" background="#EDEFEE" borderRadius={15}>
          <Center>
            <AvatarGroup mt={10}>
              <Avatar name="Schroter" size="2xl" src={profile1}/>
              <Avatar name="Tim" size="2xl" src={profile2}/>
            </AvatarGroup>
          </Center>

          <Center>
          <Heading as="h2" margin={5} paddingTop={8} >Still have questions?</Heading>
          </Center>
          <Center>
          <Text fontSize="2xl" marginLeft={5} mt={-2} >Can't find the answer you're looking for? Our team is happy to answer your questions.</Text>
          </Center>
          <Center>
            <ChakraLink href="mailto: support@littlelemon.com" isExternal style={{textDecoration: "none"}}>
            <Button mt={5} mb={-2} fontWeight="bold" bg="#F4CE14" paddingLeft={10} paddingRight={10}>Send Us an Email</Button>
            </ChakraLink>
          </Center>
      
        </Container>

        
        <Container paddingBottom={50} maxW="full" paddingLeft={120} paddingRight={120} background="#495E57">

        <HStack justifyContent="space-between">
          <VStack alignItems="start">
          <Heading as="h2" color="white" margin={5} paddingTop={8}>Ready to get started?</Heading>
  
          <Text fontSize="2xl" color="white" marginLeft={5} >We are always ready to fulfill your demand.</Text>
          </VStack>

        <Link to="/orderOnline">
        <Center>
          <Button width="full" fontWeight="bold" onClick={(e)=> setSelected("Order")} background="transparent" border="2px solid #F4CE14" color="#F4CE14" _hover={{color: "black", background: "#EDF2F7"}} mt={5} borderRadius={20} paddingRight={10} paddingLeft={10}>Explore Delivery</Button>
        </Center>
        </Link>

        </HStack>

      
        </Container>

        <ChatbotCompo  />

        <CookieConsent expires={150} cookieName="Little Lemon Cookie">This website uses cookies to enhance the user experience. We use cookies to improve site functionality and deliver personalized<br/> 
        content. By continuing to browse the site, you consent to our use of cookies. Read our <Link to="/privacyPolicy" style={{color: "#F4CE14",fontWeight:"bold", textDecoration: "underline"}}>Privacy Policy</Link> for more information.
        </CookieConsent>


    </div>
  )
}


}

export default Landing