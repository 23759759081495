import { Heading,Flex,Box, Tooltip,Center, Button, Text, HStack, VStack, Card, Image, Container, useBreakpointValue, Collapse, chakra, Avatar, position, Divider } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom' // Don't just use any Link, use the Link from react-router-Doms
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, {useState} from 'react'


import crab from "./Assets/crab.jpg"
import spinach from "./Assets/spinach.jpg"
import Nachos from "./Assets/Nachos.jpg"




function AppetizerMob(){

    let [collapse, setCollapse] = useState(true)
    let [collapse2, setCollapse2] = useState(true)
    let [collapse3, setCollapse3] = useState(true)
    let [collapse4, setCollapse4] = useState(true)
  
    
    let [hover, sethover] = useState(false)
    let [hover2, sethover2] = useState(false)
    let [hover3, sethover3] = useState(false)
    let [hover4, sethover4] = useState(false)
    let [hover5, sethover5] = useState(false)
    let [hover6, sethover6] = useState(false)
  
    let [mains, setMains ] = useState(false)
    let [appetizers, setAppetizers] = useState(false)
    let [desserts, setDesserts] = useState(false)
    let [carte, setCarte] = useState(true)
    
    
    return(
        <div>

         <Card mb={5} >
         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse? 0: 8}}>Crab Artichoke</h3>
         <Flex overflow="hidden" >
            <Text flex="1" >Indulge in a culinary delight with our 
            perfect blend of succulent crab<Collapse in={!collapse}><Box paddingBottom={8}>meat and and tender
            artichoke hearts, expertly crafted to tantalize your
            taste buds.</Box></Collapse>
            </Text>
            
            <Image src={crab} width={100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}} height={100}/>
              
         </Flex>
         <Text marginTop={collapse? "-3": "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse(!collapse)}> 
          {collapse? "See More": "See Less"} {collapse? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse(!collapse)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse)}/>}
         </Text>
         <HStack width="100%" justifyContent="space-between">
         <Text marginTop="-3" fontWeight="bold">$9.99</Text>
         <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <HStack mt={-6}>
         <Heading as="h3" size="sm">Order a delivery</Heading>
         <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
         </Tooltip>

         </HStack>
         
         </VStack>
         </Card>
      

          <Card mb={5}>
         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse2? 0: 8}}>Spinach Balls</h3>
         <Flex overflow="hidden">
            <Text flex="1">These little bites are crafted with fresh spinach,
            savory herbs and a hint of<Collapse in={!collapse2}><Box paddingBottom={8}>garlic creating a crispy exterior that
            gives way to a soft, flavourful center.</Box></Collapse>
            </Text>

            <Image src={spinach} width={100} height={100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>
         
         </Flex>
         <Text marginTop={collapse2? "-3": "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse2(!collapse2)}> 
           {collapse2? "See More" : "See Less"} {collapse2? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse2(!collapse2)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse2)}/>}
         </Text>
         <HStack width="100%" justifyContent="space-between">
         <Text marginTop="-3" fontWeight="bold">$5.99</Text>
         <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <HStack mt={-6}>
         <Heading as="h3" size="sm">Order a delivery</Heading>
         <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
         </Tooltip>

         </HStack>
         </VStack>
         </Card>


          <Card mb={5}>
         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse3? 0: 8}}>Vegan Nachos</h3>
         <Flex overflow="hidden" flex="1">
            <Text  >Dive into the acrumptiuos world of our Nachos
            layered with a mix of black beans, fresh vegetables and a<Collapse in={!collapse3}><Box paddingBottom={8}>dairy-free chesse that
            melts in every bite,</Box></Collapse>
            </Text>

            <Image src={Nachos} width={400} height={100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </Flex>
         <Text marginTop={collapse3? "-3": "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse3(!collapse3)}> 
           {collapse3? "See More" : "See Less"} {collapse3? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse3(!collapse3)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse3(!collapse3)}/>}
         </Text>
         <HStack width="100%" justifyContent="space-between">
         <Text marginTop="-3" fontWeight="bold">$7.99</Text>
         <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <HStack mt={-6}>
         <Heading as="h3" size="sm">Order a delivery</Heading>
         <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
         </Tooltip>

         </HStack>
          </VStack>
          </Card>

      
        </div>
    )
}

export default AppetizerMob