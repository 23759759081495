import { Heading, Card, Flex, Tooltip, useBreakpointValue, Image, Box,Text, Container, HStack, VStack, SimpleGrid } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import AppetizerMob from './AppetizerMob copy'
import MainMob from "./MainMob copy";
import DessertMob from "./DessertMob copy";

import React, {useState} from 'react'
import crab from "./Assets/crab.jpg"
import spinach from "./Assets/spinach.jpg"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import mezze from "./Assets/mezze.jpg"
import paella from "./Assets/paella.jpg"
import Ratato from "./Assets/Ratato.jpg"
import risotto from "./Assets/risotto.jpg"
import shak from "./Assets/shak.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import dessert from "./Assets/dessert.jpg"


function Menu (){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })


  //This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })


  

    let [hover1A, sethover1A] = useState(false)
    let [hover2A, sethover2A] = useState(false)
    let [hover3A, sethover3A] = useState(false)
    let [hover1M, sethover1M] = useState(false)
    let [hover2M, sethover2M] = useState(false)
    let [hover3M, sethover3M] = useState(false)
    let [hover4M, sethover4M] = useState(false)
    let [hover5M, sethover5M] = useState(false)
    let [hover6M, sethover6M] = useState(false)
    let [hover1D, sethover1D] = useState(false)
    let [hover2D, sethover2D] = useState(false)
    let [hover3D, sethover3D] = useState(false)
    

    if (mobileView){
      return(
        <div>
          
            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start"  marginLeft={1} marginRight={1}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                        Menu
                   </Heading><br/>

                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Appetizers</Heading>
                    </Box>
                    
                </Box>

                <AppetizerMob />

                <Box>
                   <Box mt={8}>
                    <Heading as="h2" color="white">Main Dishes</Heading>
                    </Box>
                </Box>

                <MainMob />

                <Box>
                   <Box mt={8}>
                    <Heading as="h2" color="white">Dessert</Heading>
                    </Box>
                </Box>

                <DessertMob />

  
              </VStack>
                 
            </Box>

        </div>
      )
    }
  

  else{   
 
    return(
        <div>
            <Container p={5} minHeight="40vh" maxWidth="full" background="#495E57" postion="relative" id="landing-section">
          
          <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0:20}>
          
          <Box>
          <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
            Our Menu
          </Heading>
          <Heading  mt={20} as="h2" color="white">Appetizers</Heading>
            </Box>

          </VStack>


           {/*Cards Here */}

       <SimpleGrid columns={{md:2, lg: 3}} minHeight="40vh" maxWidth="full" p={15} style={{ gap: 40, paddingLeft: tabletView? 20: 100, paddingRight: tabletView? 20: 100, paddingBottom: 50}}    > 
          
          <Card style={{boxShadow: hover1A && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1A(true)} onMouseLeave={(e)=> sethover1A(false)} overflow="hidden" borderRadius={10} cursor="pointer">
            <Image src={crab} height={238} objectFit="cover" borderRadius={10} transform= {hover1A && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack justifyContent="space-between">
            <Heading as="h3" size="md">Crab Artichoke</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$9.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">Indulge in a culinary delight with our
            perfect blend of succulent crab meat and tender artichoke hearts, expertly crafted
            to tantalize your taste buds.            
            </Text><br/>
            <HStack spacing={4}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>

          <Card overflow="hidden" onMouseEnter={(e)=> sethover2A(true)} onMouseLeave={(e)=> sethover2A(false)} style={{boxShadow: hover2A && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={spinach} objectFit="cover" borderRadius={10} height={238} transform={hover2A && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
            <Box p={8} background="#EDEFEE">
              <HStack justifyContent="space-between" >
            <Heading as="h3" size="md">Spinach Balls</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$5.99</Text>
              </HStack><br/>
            <Text textAlign="left"> These little bites are crafted with fresh spinach, 
            savory herbs and a hint of garlic creating a crispy exterior that gives way 
            to a soft, flavorful center.
            </Text><br/>
            <HStack spacing={4} paddingTop={5} >
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
            </Box>
          </Card>

          <Card overflow="hidden" onMouseEnter={(e)=> sethover3A(true)} onMouseLeave={(e)=> sethover3A(false)} style={{boxShadow: hover3A && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={Nachos} height={238} borderRadius={10} objectFit="cover" transform={hover3A && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack justifyContent="space-between">
            <Heading as="h3" size="md">Vegan Nachos</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$7.99</Text>
              </HStack><br/>
            <Text paddingBottom={5} textAlign="left">Dive into the acrumptious world of
            our Nachos layered with a mix of black beans, fresh vegetables and a dairy-free cheese
            that melts in every bite.
            </Text><br/>
            <HStack spacing={4}>
            <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
            <Heading as="h3" size="md">Order a delivery</Heading>
            </Tooltip>
            <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>

            </Box>
          </Card>
          
        
        </SimpleGrid>

        {/*Cards end here*/}
       </Container> 



        
        {/*Main starts here*/}
        <Container p={5} minHeight="40vh" maxWidth="full" postion="relative" >
          
        <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0: 20}>
        
        <Box>
       
        <Heading  mt={10} as="h2" >Main Dishes</Heading>
          </Box>

        </VStack>


         {/*Cards Here */}
    
      <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: tabletView? "1fr 1fr": "1fr 1fr 1fr", gap: 40, paddingLeft: tabletView? 20: 100, paddingRight: tabletView? 20: 100, paddingBottom: 50}}>
        
        <Card style={{boxShadow: hover1M && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1M(true)} onMouseLeave={(e)=> sethover1M(false)} overflow="hidden" borderRadius={10} cursor="pointer">
          <Image src={Couscous} height={238} objectFit="cover" borderRadius={10} transform= {hover1M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack justifyContent="space-between">
          <Heading as="h3" size="md">Couscous</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$10.99</Text>
            </HStack><br/>
          <Text paddingBottom={5} textAlign="left">Dive into a plate of our exquisite Couscous, 
          infused with aromatic herbs and spices that transports you to the shores of the 
          Mediterranean.
          </Text><br/>
          <HStack spacing={4} mt={2}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>

          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover2M(true)} onMouseLeave={(e)=> sethover2M(false)} style={{boxShadow: hover2M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={mezze} borderRadius={10} height={238} transform={hover2M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
          <Box p={8} background="#EDEFEE">
            <HStack justifyContent="space-between">
          <Heading as="h3" size="md">Mezze</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$10.99</Text>
            </HStack><br/>
          <Text textAlign="left" paddingBottom={5}>Embark on an adventure with our Mezze platter, an array of
          small bites that dance on your taste buds. Each dip and bite tell a story of flavors.
          
          </Text><br/>
          <HStack spacing={4} mt={2} >
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>
          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover3M(true)} onMouseLeave={(e)=> sethover3M(false)} style={{boxShadow: hover3M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={paella} height={238} borderRadius={10} objectFit="cover" transform={hover3M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack justifyContent="space-between">
          <Heading as="h3" size="md">Paella</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$14.99</Text>
            </HStack><br/>
          
          <Text textAlign="left" paddingBottom={5}>An authentic Spanish masterpiece that brings the vibrant spirit of Valencia
            to your table. Each spoonful is a blend of saffron-infused rice,seafood and spices.
          </Text><br/>
          <HStack spacing={4} mt={-4}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>
          

          
          

          </Box>
        </Card>


        {/*Second set for Main starts here */}
        <Card style={{boxShadow: hover4M && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover4M(true)} onMouseLeave={(e)=> sethover4M(false)} overflow="hidden" borderRadius={10} cursor="pointer">
          <Image src={Ratato} height={238} objectFit="cover" borderRadius={10} transform= {hover4M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack justifyContent="space-between">
          <Heading as="h3" size="md">Ratatouille</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$11.99</Text>
            </HStack><br/>
          <Text paddingBottom={5} textAlign="left">A vegetable medley that's as visually stunning as it's delicious.
          Layers of perfectly-roasted vegetables, bathed in a tomato-based sauce.
          </Text><br/>
          <HStack spacing={4}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>

          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover5M(true)} onMouseLeave={(e)=> sethover5M(false)} style={{boxShadow: hover5M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={risotto} objectFit="cover" borderRadius={10} height={238} transform={hover5M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
          <Box p={8} background="#EDEFEE">
            <HStack justifyContent="space-between">
          <Heading as="h3" size="md">Risotto</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
            </HStack><br/>
          <Text textAlign="left">Wrap yourself in the embrace of our creamy italian classic that takes you on
          a journey through Northern Italy. Arborio rice slow-cooked to perfection.
          
          </Text><br/>
          <HStack spacing={4} paddingTop={5}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>
          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover6M(true)} onMouseLeave={(e)=> sethover6M(false)} style={{boxShadow: hover6M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={shak} height={238} borderRadius={10} objectFit="cover" transform={hover6M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack justifyContent="space-between">
          <Heading as="h3" size="md">Shakshuka</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
            </HStack><br/>
          <Text paddingBottom={5} textAlign="left">Energize your day with our North-African delight that's a burst of flavors.
          Poached eggs in a tomato and pepper stew create a 
          breakfast experience.
          </Text><br/>
          <HStack spacing={4}>
          <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
          <Heading as="h3" size="md">Order a delivery</Heading>
          </Tooltip>
          <FontAwesomeIcon icon={faMotorcycle}/>
          </HStack>

          </Box>
        </Card>
        

      </Container>

      {/*Cards end here*/}
      </Container>



     {/*Desserts starts here*/}
     <Container p={5}  background="#495E57" minHeight="40vh" maxWidth="full" postion="relative" >
          
     <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0: 20}>
     
     <Box>
    
     <Heading mt={10} as="h2" color="white">Desserts</Heading>
       </Box>

     </VStack>


      {/*Cards Here */}
 
   <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: tabletView? "1fr 1fr": "1fr 1fr 1fr", gap: 40, paddingLeft: tabletView? 20: 100, paddingRight: tabletView? 20: 100, paddingBottom: 50}}>
     
     <Card style={{boxShadow: hover1D && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1D(true)} onMouseLeave={(e)=> sethover1D(false)} overflow="hidden" borderRadius={10} cursor="pointer">
       <Image src={greeksalad} borderRadius={10} transform= {hover1D && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
       <Box p={8} background="#EDEFEE">
         <HStack justifyContent="space-between">
       <Heading as="h3" size="md">Greek Salad</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
         </HStack><br/>
       <Text paddingBottom={5} textAlign="left">The famous greek salad of crispy
       lettuce, peppers,  olives and our Chicago style chesse,
       garnished with crunchy garlic and rosemary
       croutone.
       </Text><br/>
       <HStack spacing={4} mt={3}>
       <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
       <Heading as="h3" size="md">Order a delivery</Heading>
       </Tooltip>
       <FontAwesomeIcon icon={faMotorcycle}/>
       </HStack>

       </Box>
     </Card>

     <Card overflow="hidden" onMouseEnter={(e)=> sethover2D(true)} onMouseLeave={(e)=> sethover2D(false)} style={{boxShadow: hover2D && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
       <Image src={bruschetta} borderRadius={10} height={238} transform={hover2D && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
       <Box p={8} background="#EDEFEE">
         <HStack justifyContent="space-between">
       <Heading as="h3" size="md">Bruchetta</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$5.99</Text>
         </HStack><br/>
       <Text textAlign="left">Our Bruchetta is made from grilled
       bread that has been smeared with garlic and seasoned with salt and olive
       oil.
       </Text><br/>
       <HStack spacing={4} mt={5}>
       <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
       <Heading as="h3" size="md">Order a delivery</Heading>
       </Tooltip>
       <FontAwesomeIcon icon={faMotorcycle}/>
       </HStack>
       </Box>
     </Card>

     <Card overflow="hidden" onMouseEnter={(e)=> sethover3D(true)} onMouseLeave={(e)=> sethover3D(false)} style={{boxShadow: hover3D && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
       <Image src={dessert} height={238} borderRadius={10} objectFit="cover" transform={hover3D && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
       <Box p={8} background="#EDEFEE">
         <HStack justifyContent="space-between">
       <Heading as="h3" size="md">Lemon Dessert</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
         </HStack><br/>
       <Text paddingBottom={5} textAlign="left">This comes straight from grandma's recipe book,
       every last ingredient has been sourced and is as authentic
       as can be imagined.
       </Text><br/>
       <HStack spacing={4}>
       <Tooltip hasArrow arrowSize={15} label="Signin to order a delivery today, tomorrow and beyond">
       <Heading as="h3" size="md">Order a delivery</Heading>
       </Tooltip>
       <FontAwesomeIcon icon={faMotorcycle}/>
       </HStack>

       </Box>
     </Card>

    </Container>

    </Container>


      </div>
    )
  }
}


export default Menu