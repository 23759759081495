import Header from './Lemon Compo/Header'
import Landing from './Lemon Compo/LandingSection'
import Footer from './Lemon Compo/Footer'
import { ChakraProvider, Box } from '@chakra-ui/react'
import Reservation from './Lemon Compo/Reservation'
import OrderOnline from './Lemon Compo/OrderOnline'
import About from "./Lemon Compo/About"
import Login from "./Lemon Compo/Login"
import { Routes, Route, Link } from "react-router-dom";
import Menu from "./Lemon Compo/Menu"
import React,{useState, useEffect} from 'react'
import SignUpComponent from './Lemon Compo/SignUpComponent'
import Signin from './Lemon Compo/Signin'
import Page404 from "./Lemon Compo/Page404"
import Profile from "./Lemon Compo/Profile"
import Inbox from './Lemon Compo/Inbox'
import Cards from './Lemon Compo/Cards'
import Orders from './Lemon Compo/Orders'
import Dashboard from './Lemon Compo/Dashboard'
import Checkout from "./Lemon Compo/Checkout"
import app from './Lemon Compo/firebase'
import { getAuth } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import PrivacyPolicy from './Lemon Compo/PrivacyPolicy'
import Feedback from './Lemon Compo/Feedback'
import TermsAndConditions from './Lemon Compo/TermsAndConditions'
import {v4 as uuidv4} from 'uuid'


function LemonApp (){

let navigate = useNavigate()

//if you want to write functions in the JSX, then useEffect is the way to go.
useEffect((e)=> {
     
if (!successfulSignin){
    navigate("./login")
    setSelected("Login")
} 

},[])




let auth = getAuth(app)


let [selected, setSelected] = useState("Home")
let [successfulSignin, setsuccessfulSignin]= useState(false)
let [dashboard, setDashboard] = useState(false)
let [count, setCount] = useState(0)
let [profileSettings, setProfileSettings] = useState(false)
const [avatarUrl, setAvatarUrl] = useState("");
let [name, setName] = useState()
let [loyalty, setLoyalty] = useState(false)
let [loyaltyCard, setLoyaltyCard] = useState(false)
let [promoCode, setPromoCode] = useState(false)


let [cart, setCart] = useState([])
let [isOpenShop, setIsOpenShop]= useState(false)

//let shoppingRender = <Checkout />



//NB: By the way anything passed as an argument to a function can be renamed to anything I want so far as it's in the function's scope
// This suppose to catch items added to cart
// This act as a tunnel that receives something that is suppose to be used elsewhere
function addToCart(itemsReceived){
// Use uuid to uniquely identify each item instead of depending on traditional id
// This ensures no two items added to the cart have the same id
let itemsReceivedPlusUuid = {...itemsReceived, uuid: uuidv4()}
setCart(prevCart => [...prevCart, itemsReceivedPlusUuid])
}


    return(
        <div>
            <ChakraProvider>

            <Header selected={selected} setSelected={setSelected} dashboard={dashboard} setDashboard={setDashboard} profileSettings={profileSettings} setProfileSettings = {setProfileSettings} successfulSignin={successfulSignin} setsuccessfulSignin={setsuccessfulSignin} avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl} count={count} setCount={setCount} isOpenShop={isOpenShop} setIsOpenShop={setIsOpenShop} name={name} setName={setName} loyalty={loyalty} setLoyalty={setLoyalty} loyaltyCard={loyaltyCard} setLoyaltyCard={setLoyaltyCard} promoCode={promoCode} setPromoCode={setPromoCode}/><br/><br/>
            
 
            <Routes>
            <Route path="/" element={<Landing  selected={selected} setSelected={setSelected}/>} />
            <Route path ="/reservation" element = {<Reservation />} />
            <Route path ="/about" element ={ <About />} />
            <Route path="/orderOnline" element={<Page404 selected={selected} setSelected={setSelected} successfulSignin={successfulSignin} setsuccessfulSignin={setsuccessfulSignin} dashboard={dashboard} setDashboard={setDashboard}/>} />
            <Route path="/login" element={<Signin successfulSignin={successfulSignin} setsuccessfulSignin={setsuccessfulSignin} />} />
            <Route path="/menu" element={<Menu cart={cart} setCart={setCart} addToCart={addToCart} isOpenShop={isOpenShop} setIsOpenShop={setIsOpenShop} />} />
            <Route path="/checkout" element={<Checkout cart={cart} setCart={setCart} count={count} setCount={setCount}/>} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsAndConditions" element={<TermsAndConditions />} />
            <Route path="/feedback" element={<Feedback />} />
            
            {successfulSignin &&
            <Route path= "/dashboard" element={<Dashboard profileSettings={profileSettings} setProfileSettings={setProfileSettings} avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl} name={name} setName={setName} count={count} setCount={setCount} addToCart={addToCart} isOpenShop={isOpenShop} setIsOpenShop={setIsOpenShop} cart={cart} setCart={setCart} /> } />  
            }

            {successfulSignin &&
            <>
            <Route path='/settings' element={<Profile avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl}/>} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/my-cards" element={<Cards />} />
            <Route path="/order-history" element={<Orders loyalty={loyalty} setLoyalty={setLoyalty} loyaltyCard={loyaltyCard} setLoyaltyCard={setLoyaltyCard} promoCode={promoCode} setPromoCode={setPromoCode}  />} />

            </>
            } 

        </Routes>   
        
            {!successfulSignin &&
            <Footer setSelected={setSelected}/>
            }

            </ChakraProvider>

        </div>
    )
}

export default LemonApp