import { Heading,Flex,Box, Center, Button, Text, HStack, VStack, Card, Image, Container, useBreakpointValue, Collapse, chakra, Avatar, position, Divider } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom' // Don't just use any Link, use the Link from react-router-Doms
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, {useState} from 'react'
import restaurant from "./Assets/restaurant.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"


import dessert from "./Assets/dessert.jpg"





function DessertMob(){

  let tabletView = useBreakpointValue({
    md: true
  })

    let [collapse, setCollapse] = useState(true)
    let [collapse2, setCollapse2] = useState(true)
    let [collapse3, setCollapse3] = useState(true)
    let [collapse4, setCollapse4] = useState(true)
  
    
    let [hover, sethover] = useState(false)
    let [hover2, sethover2] = useState(false)
    let [hover3, sethover3] = useState(false)
    let [hover4, sethover4] = useState(false)
    let [hover5, sethover5] = useState(false)
    let [hover6, sethover6] = useState(false)
  
    let [mains, setMains ] = useState(false)
    let [appetizers, setAppetizers] = useState(false)
    let [desserts, setDesserts] = useState(false)
    let [carte, setCarte] = useState(true)
    
    
    return(
        <div>
            <Container maxWidth={tabletView && "full"} style={{overflowY: "auto" }} height="60vh"> 
         
            <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
            <h3 style={{fontWeight: "bold", marginBottom: collapse? (tabletView? 20:0): 8}}>Greek Salad</h3>
            <Flex overflow="hidden" >
            <Text fontSize={tabletView && "2xl"} flex="1" >The famous greek salad of crispy
            lettuce, peppers, olives and our Chicago<Collapse in={!collapse}><Box paddingBottom={8}> style chesse,
            garnished with crunchy garlic and rosemary
            croutone.</Box></Collapse>
            </Text>
            
            <Image src={greeksalad} width={tabletView? 200: 100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}} height={tabletView? 200: 100}/>
              
            </Flex>
            <Text fontSize={tabletView && "2xl"} marginTop={collapse? (tabletView? "-20" :"-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse(!collapse)}> 
            {collapse? "See More": "See Less"} {collapse? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse(!collapse)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse)}/>}
            </Text>
            <Text  fontSize={tabletView && "2xl"}  marginTop="-3" fontWeight="bold">$12.99</Text>
         
         </VStack>
 

         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse2? (tabletView? 20:0): 8}}>Bruchetta</h3>
         <Flex overflow="hidden">
            <Text  fontSize={tabletView && "2xl"}  flex="1" >Our Bruchetta is made from grilled
            bread that has been smeared with garlic and <Collapse in={!collapse2}><Box paddingBottom={8}> seasoned with salt and olive
            oil.</Box></Collapse>
            </Text>

            <Image src={bruschetta} width={tabletView? 200:100} height={tabletView? 200:100}  _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>
         
         </Flex>
         <Text  fontSize={tabletView && "2xl"}  marginTop={collapse2? (tabletView? "-20":"-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse2(!collapse2)}> 
           {collapse2? "See More" : "See Less"} {collapse2? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse2(!collapse2)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse2)}/>}
         </Text>
         <Text  fontSize={tabletView && "2xl"}  marginTop="-3" fontWeight="bold">$7.99</Text>
         </VStack>


         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse3? (tabletView? -20:0): 8}}>Lemon Dessert</h3>
         <HStack overflow="hidden">
            <Text  fontSize={tabletView && "2xl"}  >This comes straight from grandma's recipe book,
            every last ingredient has <Collapse in={!collapse3}><Box paddingBottom={8}> been sourced and is as authentic
            as can be imagined.</Box></Collapse>
            </Text>

            <Image src={dessert} width={tabletView? 200:100} height={tabletView? 200:100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </HStack>
         <Text  fontSize={tabletView && "2xl"}  marginTop={collapse3? (tabletView? "-10":"-3"): "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse3(!collapse3)}> 
           {collapse3? "See More" : "See Less"} {collapse3? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse3(!collapse3)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse3(!collapse3)}/>}
         </Text>
         <Text  fontSize={tabletView && "2xl"}  marginTop="-3" fontWeight="bold">$6.00</Text>
         </VStack>
 
        
            </Container>

        </div>
    )
}

export default DessertMob