import { Heading,Flex,Box, Tooltip,Center, Button, Text, HStack, VStack, Card, Image, Container, useBreakpointValue, Collapse, chakra, Avatar, position, Divider } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faMotorcycle, faStar, faStarHalf, faExclamation, faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom' // Don't just use any Link, use the Link from react-router-Doms
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React, {useState} from 'react'
import restaurant from "./Assets/restaurant.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"


import dessert from "./Assets/dessert.jpg"
 




function DessertMobPlaceholder({setIsOpen11, setIsOpen12, setIsOpen13}){

    let [collapse, setCollapse] = useState(true)
    let [collapse2, setCollapse2] = useState(true)
    let [collapse3, setCollapse3] = useState(true)
    let [collapse4, setCollapse4] = useState(true)
  
    
    let [hover, sethover] = useState(false)
    let [hover2, sethover2] = useState(false)
    let [hover3, sethover3] = useState(false)
    let [hover4, sethover4] = useState(false)
    let [hover5, sethover5] = useState(false)
    let [hover6, sethover6] = useState(false)
  
    let [mains, setMains ] = useState(false)
    let [appetizers, setAppetizers] = useState(false)
    let [desserts, setDesserts] = useState(false)
    let [carte, setCarte] = useState(true)
    
    
    return(
        <div>
            
            <Card mb={5}>
            <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
            <h3 style={{fontWeight: "bold", marginBottom: collapse? 0: 8}}>Greek Salad</h3>
            <Flex overflow="hidden" >
            <Text flex="1">The famous greek salad of crispy
            lettuce, peppers,  olives and our Chicago<Collapse in={!collapse}><Box paddingBottom={8}> style chesse,
            garnished with crunchy garlic and rosemary
            croutone.</Box></Collapse>
            </Text>
            
            <Image src={greeksalad} onClick={(e)=> setIsOpen11(true)} width={100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}} height={100}/>
              
         </Flex>
         <Text marginTop={collapse? "-3": "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse(!collapse)}> 
          {collapse? "See More": "See Less"} {collapse? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse(!collapse)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse)}/>}
         </Text>
         <HStack width="100%" justifyContent="space-between">
         <Text marginTop="-3" fontWeight="bold">$12.99</Text>
         <Tooltip hasArrow arrowSize={15} label="Continue to order a delivery today, tomorrow and beyond">
            <HStack mt={-6}>
         <Heading as="h3" size="sm">Order a delivery</Heading>
         <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
         </Tooltip>

         </HStack>
         
         </VStack>
         </Card>
 

         <Card mb={5}>
         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse2? 0: 8}}>Bruchetta</h3>
         <Flex overflow="hidden">
            <Text flex="1">Our Bruchetta is made from grilled
            bread that has been smeared with <Collapse in={!collapse2}><Box paddingBottom={8}> garlic and seasoned with salt and olive
            oil.</Box></Collapse>
            </Text>

            <Image src={bruschetta} onClick={(e)=> setIsOpen12(true)} width={100} height={100}  _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>
         
         </Flex>
         <Text marginTop={collapse2? "-3": "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse2(!collapse2)}> 
           {collapse2? "See More" : "See Less"} {collapse2? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse2(!collapse2)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse(!collapse2)}/>}
         </Text>
         <HStack width="100%" justifyContent="space-between">
         <Text marginTop="-3" fontWeight="bold">$7.99</Text>
         <Tooltip hasArrow arrowSize={15} label="Continue to order a delivery today, tomorrow and beyond">
            <HStack mt={-6}>
         <Heading as="h3" size="sm">Order a delivery</Heading>
         <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
         </Tooltip>

         </HStack>
         </VStack>
         </Card>


         <Card mb={5}>
         <VStack alignItems="start" margin={5} style={{borderBottom: "2px solid #EDEFEE"}} >
         <h3 style={{fontWeight: "bold", marginBottom: collapse3? 0: 8}}>Lemon Dessert</h3>
         <Flex overflow="hidden">
            <Text flex="1" >This comes straight from grandma's recipe book,
            every last ingredient has <Collapse in={!collapse3}><Box paddingBottom={8}> been sourced and is as authentic
            as can be imagined.</Box></Collapse>
            </Text>

            <Image src={dessert} onClick={(e)=> setIsOpen13(true)} width={100} height={100} _hover={{transform: "scale(1.1)", transition: "transform ease-in-out 0.3s"}}/>

         </Flex>
         <Text marginTop={collapse3? "-3": "-10"}  style={{cursor: "pointer"}} onClick={(e)=> setCollapse3(!collapse3)}> 
           {collapse3? "See More" : "See Less"} {collapse3? <FontAwesomeIcon icon={faArrowRight} onClick={(e)=> setCollapse3(!collapse3)} style={{cursor: "pointer"}}/> : <FontAwesomeIcon icon={faArrowLeft} onClick={(e)=> setCollapse3(!collapse3)}/>}
         </Text>
         <HStack width="100%" justifyContent="space-between">
         <Text marginTop="-3" fontWeight="bold">$6.00</Text>
         <Tooltip hasArrow arrowSize={15} label="Continue to order a delivery today, tomorrow and beyond">
            <HStack mt={-6}>
         <Heading as="h3" size="sm">Order a delivery</Heading>
         <FontAwesomeIcon icon={faMotorcycle}/>
            </HStack>
         </Tooltip>

         </HStack>
         </VStack>
         </Card>


        </div>
    )
}

export default DessertMobPlaceholder