import React, {useState} from "react"
import { Heading, Center,AlertDialogOverlay, AlertDialog, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, Card, HStack, Container, Text, VStack, Button, Box, Image, InputRightElement, InputGroup, FormLabel } from "@chakra-ui/react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'


import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import app from './firebase';




function Forgotpass(){

let [alertMessage, setalertMessage] = useState("")
let [alerterrorMessage, setalerterrorMessage] = useState("")


let onClose =()=> {
  setalertMessage("")
}

let onClose2 =()=> {
  setalerterrorMessage("")
}






  let initialValues = {
    email: "",
  }


  let validationSchema = Yup.object({
    email: Yup.string().required("Email required").email("Invalid email format"),
    
  })

  let onSubmit= async (values, actions) => {

// try this code which might bring an error
    try{
      let auth = getAuth(app)
      await sendPasswordResetEmail(auth, values.email)
      console.log("Password reset email sent successfully")
      setalertMessage(`Instructions for your Password reset has been sent successfully to ${values.email}, check your email`)
    }

// When the errors occurs execute this
    catch(error) {
      console.error("There was an error:", error.message)
      setalerterrorMessage("Something went wrong. please try again!")
    }


    setTimeout((e)=> {
      actions.setSubmitting(false)
      actions.resetForm()
    }, 500)

  }
  
    
    return(
        <div>
                                      
                <Card p={10} borderRadius={20} position="absolute" top={60} right={50} transform="translate(-8%, -50%)">
                <Heading marginBottom={5} fontSize="2xl">Forgot Password. </Heading>
                <Text marginBottom={8}>Enter your email address, and we'll send you an email <br/>with instructions for how to reset your password</Text>
                
                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>
                


                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} >
                <FormLabel htmlFor="email">Email</FormLabel>
                <Field as={Input} type="email" id="email" placeholder="Email address" name="email"  />
                <FormErrorMessage>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>

                <Button type="submit" isLoading={formik.isSubmitting} mt={8} width="full" background="#F4CE14" fontWeight="bold" >Send Instructions</Button>

                </Form>
                )}
                </Formik>

               
                </Card> 


                

                <AlertDialog
                isOpen={alertMessage}
                onClose={onClose}
                >

                  <AlertDialogOverlay>
                    <AlertDialogContent background= "#81C784">
                      <AlertDialogHeader fontWeight="bold" paddingTop={5}>
                        <AlertDialogCloseButton />
                          All good!

                      </AlertDialogHeader>
                      <AlertDialogBody paddingBottom={5} >
                      {alertMessage}

                      </AlertDialogBody>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>


                 <AlertDialog
                isOpen={alerterrorMessage}
                onClose={onClose2}
                >

                  <AlertDialogOverlay>
                    <AlertDialogContent background= "#FF8A65">
                      <AlertDialogHeader fontWeight="bold" paddingTop={5}>
                        <AlertDialogCloseButton />
                          Oops!

                      </AlertDialogHeader>
                      <AlertDialogBody paddingBottom={5} >
                      {alerterrorMessage}

                      </AlertDialogBody>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>


        </div>
    )
}

export default Forgotpass