import React, {useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBuildingColumns,faGear, faCreditCard, faSignOut,faShoppingCart, faUser, faInbox, faDashboard, faClock, faMotorcycle, faPersonBiking, faBookBible, faTruckPickup, faBiking, faEnvelope, faRefresh, faHistory, faGift, faCode, faTicket, faTicketSimple, faTag, faTags, faUserTag, faQrcode, faMobileAlt, faChartLine, faDatabase, faThLarge, faGrip,  } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faStackOverflow, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {Avatar,Badge, useToast, ButtonGroup, AvatarBadge,Menu, MenuList, MenuItem, MenuButton,Box, HStack, Text, VStack, Container, Image, useBreakpointValue,IconButton, Button, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerBody, Flex} from "@chakra-ui/react";
import { Popover, PopoverTrigger,PopoverContent, PopoverBody, PopoverHeader, PopoverFooter, PopoverArrow, PopoverCloseButton, PopoverAnchor } from "@chakra-ui/react";
import lemonLogo from "./lemonLogo.png"
import logo from "./Assets/logo.png"
import { Link } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import app from "./firebase";
import { useNavigate } from "react-router-dom";
import {getDatabase, ref, onValue} from 'firebase/database'

import { doc, getDoc,updateDoc, getFirestore, } from "firebase/firestore";




function Header ({toggleProp,selected, setSelected, dashboard, setDashboard, profileSettings, setProfileSettings, successfulSignin, setsuccessfulSignin, avatarUrl, setAvatarUrl, name, setName, count, setCount, setIsOpenShop, loyalty, setLoyalty, loyaltyCard, setLoyaltyCard, promoCode, setPromoCode} ){

let [openPop, setOpenPop] = useState(false)
let navigate = useNavigate()
let [isOnline, setIsOnline] = useState()
let [userAvatar, setUserAvatar] = useState()

let toast = useToast()



let database = getDatabase(app)

let auth = getAuth(app)
let dataB = getFirestore(app)



let logOut = async() => {

  // try this code which might bring an error
  try{
    let auth = getAuth(app)
  // Waiting period here
  await signOut(auth)
    console.log("You've signed out successfully")
    localStorage.removeItem("UserLoggedIn")
    console.log("You've removed stored data")
    setsuccessfulSignin(false)
    navigate("/login")
    setSelected("Login")
    toast({
      title: "Logged Out",
      description: "You were logged out of your session!",
      status: "info",
      duration: 5000,
      position: "top-right",
      isClosable: true
    })


  }
  // when the error occures execute this
  catch(error) {
    console.error("There was an error signing out", error.message)
  }

}

  let [selectedImage, setSelectedImage] = useState(null)

 //Creating visibility of the menu depending on Scroll
 let [scrollUp, setscrollUp] = useState(true) 
 let [prevScroll, setprevScroll] = useState(0)

 //Creating a live timeOut
 let [currentTime, setCurrentTime] = useState(new Date())

 // The setInterval unlike setTimeout is used to repeatedly execute a functions at a specified intervals
 useEffect((e)=>{

  let timeId = setInterval((e)=>{
  setCurrentTime(new Date())
  },1000)

  return()=> clearInterval(timeId)

 })


 useEffect((e) => {
  let  handleScroll=() => {
  let verticalScroll = window.scrollY

  setscrollUp(verticalScroll < prevScroll) // This is comparing the previous scroll with the current scroll ie verticalScroll
  setprevScroll(verticalScroll) //this now update the prevSroll(0) with the vertical scroll position number & the comparison continues 


}

  window.addEventListener("scroll", handleScroll)
//Remove Event listener to save Memory when the component is unmounted/Or no longer rendered in the current view/Or user navigates away from page
//The return is a cleanup function for all your cleanup activities in useEffect
return () => {
  window.removeEventListener("scroll", handleScroll)
}
 }, [ prevScroll]) 


 let Hstyle = {

  position: "fixed",
  background: "#EDEFEE",
  color: "#18181b",
  padding: "10px",
  right:"0",
  left:"0",
  top: "0",
  transition: "top 0.3s ease-in-out",
  zIndex: 5
 }


 useEffect((e)=>{
  //The '.info/connected' in database represents the connection status of the client.
  let presenceRef = ref(database,'.info/connected')
  // The onValue means keeping an eye on a particular data and let me know if that particular data changes. And Execute the callback function if it changes.
  // The snapshot means give me a frozen picture of the data. The val() means give me only the javascript of the data excluding metadata.
  let presenceListener = onValue (presenceRef, (snapshot)=>{
    setIsOnline(snapshot.val())
  })

  return()=>{
    presenceListener()
  }
 }, [])



 //Background colour for navigation menu
 //let [selected, setSelected] = useState("Home")


 //Creating a Hamburger Menu / base represents mobile & md represents desktop
 let mobileView = useBreakpointValue({
  base: true,
  lg: false
 })

 let tabletView = useBreakpointValue({
  md : true
 })

 let [isOpen, setIsOpen] = useState(false)

 let onClose = ()=>{
  return setIsOpen(false)
 }

 
 if (mobileView) {
  return (
    <div style={Hstyle}>

      {scrollUp &&
    <HStack paddingLeft={1} width={tabletView && "100vw"}>
    {!successfulSignin? 
    <>
    <IconButton icon= {<FontAwesomeIcon icon={faBars} size={tabletView? "2xl":"xl"}/>} onClick={(e)=>{
      setIsOpen(true)
    }}/>
    <Container >
    <Image src={logo}  ml={tabletView && 40} maxWidth={tabletView && "25vw"} maxHeight={tabletView && "25vh"} onClick={(e)=>{
       navigate("/")
       setSelected("Home")
    }}  />  
    </Container> 
      </>
    :

    <>

    <Container   >
    <Image src={logo} width="full" ml={tabletView && 10} maxWidth={tabletView && "20vw"} maxHeight={tabletView && "20vw"} transform="scale(1.5)" onClick={(e)=>{
      setProfileSettings(false)
       navigate("/dashboard")
    }}/>  
    </Container>

    <Link to="/reservation">
      <Button mr={tabletView && 20} ml={tabletView && 20} transform={tabletView && "scale(1.2)"} onClick={(e)=> setSelected("Reservation")} style={{background: selected == "Reservation"? "#495E57" : "", color: selected == "Reservation"? "white": "" }}>Reservations</Button>
    </Link>

    <Menu >
    <HStack spacing={0} width={tabletView && "100vw"} justifyContent={tabletView && "space-between"}> 
      <MenuButton transform={tabletView && "scale(1.2)"} as={Avatar} _hover={{background: "blue.600"}} src={avatarUrl} name={name}>
        {/*The "em" means the size of the AvatarBadge relative to the Avatar size or fontSize */}
        <AvatarBadge boxSize="1.25em" bg={isOnline? 'green.500': 'tomato'} borderColor="papayawhip"/>
      </MenuButton >
     {/* <MenuButton as={Button} variant="ghost" onClick={(e)=> setSelected("Dash")} style={{background: selected == "Dash"? "#495E57" : "", color: selected == "Dash"? "white": "" }} >My Account </MenuButton> */}
      </HStack>
  
    <MenuList mr={mobileView?-20 : ""}>
    <MenuItem onClick={(e)=> {
      setProfileSettings(false)
      navigate("/dashboard")
    }}><span style={{marginRight: 120}}>Dashboard</span><FontAwesomeIcon icon={faGrip}/> </MenuItem>
    <MenuItem mt={2} onClick={(e)=> {
      setProfileSettings(true)
      navigate("/settings")
    }} ><span style={{marginRight: 140}}>Settings</span><FontAwesomeIcon icon={faGear}/> </MenuItem>
    <MenuItem onClick={(e)=>{
       setProfileSettings(false)
       navigate("/inbox")
    }} mt={2}><span style={{marginRight: 157}} >Inbox</span><FontAwesomeIcon icon={faEnvelope}/><Badge colorScheme="red" borderRadius={10} marginTop={-5}><span style={{color:"black"}}>0 </span></Badge></MenuItem>
    <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
    }} mt={2}><span style={{marginRight: 123}}>My Orders</span><FontAwesomeIcon icon={faHistory}/></MenuItem>
    <MenuItem onClick={(e)=>{
        setProfileSettings(false)
        navigate("/my-cards")
    }} mt={2}><span style={{marginRight: 130}}>My Cards</span><FontAwesomeIcon icon={faCreditCard}/></MenuItem>
    <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
          setLoyalty(true)
        }} mt={2}><span style={{marginRight: 145}}>Loyalty</span><FontAwesomeIcon icon={faGift}/></MenuItem>

        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
          setPromoCode(true)
        }} mt={2}><span style={{marginRight: 120}}>Enter Code</span><FontAwesomeIcon icon={faTag}/></MenuItem>

        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
          setLoyaltyCard(true)
        }} mt={2}><span style={{marginRight: 110}}>Loyalty Card</span><FontAwesomeIcon icon={faQrcode}/></MenuItem>



    <Box mt={5} paddingRight={3} paddingLeft={3} paddingBottom={1}>
    
    <Popover placement="auto" isOpen={openPop} onClose={(e)=> setOpenPop(false)}>
    <PopoverTrigger>
    <Button borderRadius={15} onClick={(e)=> setOpenPop(true)} fontWeight="bold" width="full" background="#F4CE14"><FontAwesomeIcon icon={faSignOut}/><span style={{marginLeft: 10}}>Log Out</span></Button>
    </PopoverTrigger>

    <PopoverContent>
    <PopoverArrow />
    <PopoverCloseButton />
    <PopoverHeader fontWeight="semibold"> Confirmation</PopoverHeader>
    <PopoverBody>Are you sure you want to continue with your action?</PopoverBody>
    
    <PopoverFooter display="flex" justifyContent="flex-end">
    <ButtonGroup>
    <Button size="sm" variant="outline" onClick={(e)=> setOpenPop(false)}>Cancel</Button>
    <Button size="sm" colorScheme="red" onClick={logOut}>Apply</Button>
    </ButtonGroup>
    </PopoverFooter>

    </PopoverContent>
    </Popover>
    </Box>

    </MenuList>
  </Menu>
  </> }

    <Link >
      <Button mr={tabletView && 5} onClick={(e)=> {
        setSelected("Cart")
        setIsOpenShop(true)
      }
        } style={{background: selected == "Cart"? "#495E57" : "", color: selected == "Cart"? "white": "" }}>
      <FontAwesomeIcon icon={faShoppingCart}  size={tabletView? "2xl":"xl"} cursor="pointer"/> 
      <Badge transform={tabletView && "scale(1.4)"} colorScheme="green" borderRadius={10} marginTop={-5}>{count}</Badge>
      </Button>     
      </Link>
       
      
    </HStack>}

  {successfulSignin?     
    <HStack mt={5} ml={tabletView && 20} width="90%" justifyContent="space-between">
      <Button transform={tabletView && "scale(1.2)"} onClick={(e) => setSelected("Order")} style={{ background: selected == "Order"? "#495E57" : "", color: selected == "Order"? "white" :""}}><FontAwesomeIcon icon={faMotorcycle}/><span style={{marginLeft:8}}></span> Delivery</Button> 
      <Button transform={tabletView && "scale(1.2)"} onClick={(e) => setSelected("Time")} style={{ background: selected == "Time"? "#495E57" : "", color: selected == "Time"? "white" :"", width:"40vw"}}><FontAwesomeIcon icon={faClock}/><span style={{marginLeft:8}}></span> {currentTime.toLocaleTimeString(["en-US"], {hour: "2-digit", minute:"2-digit", second: "2-digit"})}</Button> 
    </HStack>
    : "" }

    <Drawer 
    isOpen={isOpen}
    onClose={onClose}
    size={ tabletView && "md"}
     >
      <DrawerOverlay>
        <DrawerContent maxH="100vh" >
          <DrawerCloseButton color="black" size="lg" />
          
          <DrawerHeader color= "#EDEFEE">
            <Image src={logo} maxWidth="30vw"  />
          </DrawerHeader>
          <DrawerBody mt={20} id="drawerbody"  >
            <VStack>
            <Button transform="scale(1.2)" size="lg" as={Link} to="/" variant="ghost" onClick={(e) => setSelected("Home")} style={{ background: selected == "Home"? "#495E57" : "", color: selected == "Home"? "white" :""}}> Home</Button> 
            <Button transform="scale(1.2)" size="lg" as={Link} to="/about" variant="ghost" onClick={(e) => setSelected("About")} style={{ background: selected == "About"? "#495E57" : "", color: selected == "About"? "white" :""}} >About</Button> 
            <Button transform="scale(1.2)" size="lg" as={Link} to="/menu" variant="ghost" onClick={(e)=> setSelected("Menu")} style={{background: selected == "Menu"? "#495E57" : "", color: selected == "Menu"? "white": "" }}>Menu</Button>
            <Button transform="scale(1.2)" size="lg" as={Link} to="/reservation" variant="ghost" onClick={(e)=> setSelected("Reservation")} style={{background: selected == "Reservation"? "#495E57" : "", color: selected == "Reservation"? "white": "" }}>Reservations</Button>
            <Button transform="scale(1.2)" size="lg" as={Link} to="/orderOnline" variant="ghost" onClick={(e)=> setSelected("Order")} style={{background: selected == "Order"? "#495E57" : "", color: selected == "Order"? "white": "" }}>Order Online</Button> 
            <Button transform="scale(1.2)" size="lg" as={Link} to="/login" variant="ghost" onClick={(e)=> setSelected("Login")} style={{background: selected == "Login"? "#495E57" : "", color: selected == "Login"? "white": "" }}><FontAwesomeIcon icon={faUser} size="lg" cursor="pointer"/><span style={{marginLeft: 5}}>Login</span></Button>     
            <Button transform="scale(1.2)" size="lg" as={Link} to="/feedback" variant="ghost" onClick={(e) => setSelected("Feedback")} style={{ background: selected == "Feedback"? "#495E57" : "", color: selected == "Feedback"? "white" :""}} >Feedback</Button> 

            
            <HStack spacing={8} mt={20}>
            <Link href="https://www.stackoverflow.com/benasenso" isExternal mt="40">
            <FontAwesomeIcon icon={faStackOverflow} size="2xl" />
            </Link>
            <Link href="https://www.twitter.com/Ararat_Ben" isExternal mt="40">
            <FontAwesomeIcon icon={faTwitter} size="2xl" />
            </Link>
            <Link href="https://www.Instagram.com/bengizzlettl" isExternal mt="40">
            <FontAwesomeIcon icon={faInstagram} size="2xl" />
            </Link>
            </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    </div>
  )
 }

 else {
  return(
    <div>
      
      {scrollUp &&

      <HStack spacing={8} style={Hstyle}>

      <Container style={{display: "flex", justifyContent: "center", marginRight: 10}}>
      <Image src={logo} maxWidth= "20vw" maxHeight="20vh" onClick={(e)=> {
      if (!successfulSignin){
      navigate("/") 
      setSelected("Home") 
      }
      
      else{
      setProfileSettings(false) 
      navigate("/dashboard") 
      }
      
      }}/>
      
      </Container>

  
      <Box marginLeft="auto" marginRight="10">
      <HStack spacing={8} alignSelf="right" marginRight="20" marginLeft="-20">
     
     {!successfulSignin? 
      <>
      <Button as={Link} to="/" onClick={(e) => setSelected("Home")} style={{ background: selected == "Home"? "#495E57" : "", color: selected == "Home"? "white" :""}}> Home</Button> 
      <Button as={Link} to="/about" onClick={(e) => setSelected("About")} style={{ background: selected == "About"? "#495E57" : "", color: selected == "About"? "white" :""}} >About</Button> 
      <Button as={Link} to="/menu" onClick={(e)=> setSelected("Menu")} style={{background: selected == "Menu"? "#495E57" : "", color: selected == "Menu"? "white": "" }}>Menu</Button>
      </>
      :
      <>
    
      <Button onClick={(e) => setSelected("Order")} style={{ background: selected == "Order"? "#495E57" : "", color: selected == "Order"? "white" :""}}><FontAwesomeIcon icon={faMotorcycle}/><span style={{marginLeft:8}}></span> Delivery</Button> 
      <Button onClick={(e) => setSelected("Time")} style={{ background: selected == "Time"? "#495E57" : "", color: selected == "Time"? "white" :"", width:"10vw"}}><FontAwesomeIcon icon={faClock}/><span style={{marginLeft:8}}></span> {currentTime.toLocaleTimeString(["en-US"], {hour: "2-digit", minute:"2-digit", second: "2-digit"})}</Button> 
      </>
     }
    
      <Link to="/reservation">
      <Button  onClick={(e)=> setSelected("Reservation")} style={{background: selected == "Reservation"? "#495E57" : "", color: selected == "Reservation"? "white": "" }}>Reservations</Button>
      </Link>
    {!successfulSignin &&  <Button as={Link} to="/orderOnline"  onClick={(e)=> setSelected("Order")} style={{background: selected == "Order"? "#495E57" : "", color: selected == "Order"? "white": "" }}>Order Online</Button> }
     
     {successfulSignin?

      <Menu >
        <HStack spacing={0}> 
          <Avatar src={avatarUrl} name={name}>
            {/*The "em" means the size of the AvatarBadge relative to the Avatar size or fontSize */}
            <AvatarBadge boxSize="1.25em" bg={isOnline? 'green.500': 'tomato'} borderColor="papayawhip"/>
          </Avatar> 
          <MenuButton as={Button} variant="ghost" onClick={(e)=> setSelected("Dash")} style={{background: selected == "Dash"? "#495E57" : "", color: selected == "Dash"? "white": "" }} >My Account </MenuButton>
          </HStack>
      
        <MenuList>
        <MenuItem onClick={(e)=> {
          setProfileSettings(false)
          navigate("/dashboard")
        }}><span style={{marginRight: 120}}>Dashboard</span><FontAwesomeIcon icon={faGrip}/> </MenuItem>
        <MenuItem mt={2} onClick={(e)=> {
          setProfileSettings(true)
          navigate("/settings")
        }} ><span style={{marginRight: 140}}>Settings</span><FontAwesomeIcon icon={faGear}/> </MenuItem>
        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/inbox")
        }} mt={2}><span style={{marginRight: 157}}>Inbox</span><FontAwesomeIcon icon={faEnvelope}/><Badge colorScheme="red" borderRadius={10} marginTop={-5}><span style={{color:"black"}}>0 </span></Badge></MenuItem>

        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
        }} mt={2}><span style={{marginRight: 123}}>My Orders</span><FontAwesomeIcon icon={faHistory}/></MenuItem>

        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/my-cards")
        }} mt={2}><span style={{marginRight: 130}}>My Cards</span><FontAwesomeIcon icon={faCreditCard}/></MenuItem>

        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
          setLoyalty(true)
        }} mt={2}><span style={{marginRight: 145}}>Loyalty</span><FontAwesomeIcon icon={faGift}/></MenuItem>

        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
          setPromoCode(true)
        }} mt={2}><span style={{marginRight: 120}}>Enter Code</span><FontAwesomeIcon icon={faTag}/></MenuItem>

        <MenuItem onClick={(e)=>{
          setProfileSettings(false)
          navigate("/order-history")
          setLoyaltyCard(true)
        }} mt={2}><span style={{marginRight: 110}}>Loyalty Card</span><FontAwesomeIcon icon={faQrcode}/></MenuItem>

        <Box mt={5} paddingRight={3} paddingLeft={3} paddingBottom={1}>
        
        <Popover placement="left" isOpen={openPop} onClose={(e)=> setOpenPop(false)}>
        <PopoverTrigger>
        <Button borderRadius={15} onClick={(e)=> setOpenPop(true)} fontWeight="bold" width="full" background="#F4CE14"><FontAwesomeIcon icon={faSignOut}/><span style={{marginLeft: 10}}>Log Out</span></Button>
        </PopoverTrigger>

        <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="semibold"> Confirmation</PopoverHeader>
        <PopoverBody>Are you sure you want to continue with your action?</PopoverBody>
        
        <PopoverFooter display="flex" justifyContent="flex-end">
        <ButtonGroup>
        <Button size="sm" variant="outline" onClick={(e)=> setOpenPop(false)}>Cancel</Button>
        <Button size="sm" colorScheme="red" onClick={logOut}>Apply</Button>
        </ButtonGroup>
        </PopoverFooter>

        </PopoverContent>
        </Popover>
        </Box>

        </MenuList>
      </Menu>
      
      :<Button as={Link} to="/login" onClick={(e)=> setSelected("Login")} style={{background: selected == "Login"? "#495E57" : "", color: selected == "Login"? "white": "" }}><FontAwesomeIcon icon={faUser} size="lg" cursor="pointer"/><span style={{marginLeft: 5}}>Login</span></Button>
      }

      
      <Link >
      <Button onClick={(e)=> {
        setSelected("Cart")
        setIsOpenShop(true)
      }
        } style={{background: selected == "Cart"? "#495E57" : "", color: selected == "Cart"? "white": "" }}>
      <FontAwesomeIcon icon={faShoppingCart} size="lg" cursor="pointer"/> 
      <Badge colorScheme="green" borderRadius={10} marginTop={-5}>{count}</Badge>
      <span style={{marginLeft: 5}}>Cart</span>
      </Button>     
      </Link>
      

      </HStack>


     
      </Box>

      </HStack>}

  </div> 
  )
 }

} 
  
export default Header 
 