import { Heading, Select, FormHelperText,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, Card, HStack, Container, Text, VStack, Button, Box, Image, InputRightElement, InputGroup, FormLabel } from "@chakra-ui/react"
import React, {useState, useEffect} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input, Checkbox} from "@chakra-ui/react"
import * as Yup from 'yup'
import PhoneInput from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {ref} from "yup" 
import Signin from "./Signin"

import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, getFirestore, setDoc } from "firebase/firestore"
import app from './firebase';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import {Link} from 'react-router-dom'




function Login({login, setLogin}){

  let [visiblePass, setVisiblePass] = useState(false)
  let [signin, setSigin] = useState(false)   

  let [alertMessage, setAlertmessage] = useState("")

  let onClose = ()=> {
  setAlertmessage("")
  }


  let [alerterrorMessage, setAlerterrromessage] = useState("")

  let onClose2 = ()=> {
  setAlerterrromessage("")
  }


  let initialValues = {
    name:"",
    lastname:"",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    check: "",
    birthmonth: "",
    birthday: "",
    allergies:"",
    dietary: "",
  }


  let validationSchema = Yup.object({
    name:Yup.string().required("First name required").min(3, "First name must be at least 3 characters"),
    lastname: Yup.string().required("Last name required").min(3, "Last name must be at least 3 characters"),
    email: Yup.string().required("Email required").email("Invalid email format"),
    phoneNumber: Yup.string().required("Phone Number required"),
    password: Yup.string().required("Password required").min(8, "Password must be at least 8 characters").matches(/[0-9]/, "Password must contain at least one number").matches(/[!@#$%^&*_-]/, "Password must contain at least one special character"),
    confirmPassword:Yup.string().required("Confirm password required").oneOf([ref("password"), null], "Passwords must match"),
    check: Yup.string().required("Please check this box if you want to proceed"),
    birthmonth: Yup.string().required("Birth month required"),
    birthday: Yup.string().required("Birthday required"),
    allergies: Yup.string(),
    dietary: Yup.string()
  })

  let onSubmit = async (values, actions) => {

// try this code which might bring an error
    try{
      let auth = getAuth(app)  
      let dataB= getFirestore(app)
      // The waiting period
      await createUserWithEmailAndPassword(auth, values.email, values.password)
      await sendEmailVerification(auth.currentUser)//This property is for currently signed user
      let userDocumentReference = doc(dataB, "users", auth.currentUser.uid)
      await setDoc(userDocumentReference, values)
      console.log("Signup created successfully")
      actions.resetForm()
      setAlertmessage(`Thanks for your submission ${values.name}, You have successfully registered on Little Lemon! Check your email`)
 
    }

// When the errors occurs execute this
    catch(errors) {
      console.error("There was an error:", errors.message)
      setAlerterrromessage("Something went wrong, please try again later!")

    }

    setTimeout((e)=> {
      actions.setSubmitting(false)
      actions.resetForm()

    },500)

  }
  
 
    return(
        <div >
                                               
                <Card >
                <Text marginBottom={8}>Register to order online today, tomorrow and beyond.</Text>
                
                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit = {onSubmit}
                initialValues = {initialValues}
                validationSchema = {validationSchema}
                >

                {(formik) => (

                <Form>
                
                
                <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
                <FormLabel htmlFor="name">First Name<span style={{marginLeft: 4}}>*</span></FormLabel>
                <Field as={Input} id="name" placeholder="First Name" name="name"  />
                <FormErrorMessage>
                <ErrorMessage name="name" />
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.lastname && formik.touched.lastname} mt={4}>
                <FormLabel htmlFor="lastname">Last Name<span style={{marginLeft: 4}}>*</span></FormLabel>
                <Field as={Input}  id="lastname" placeholder="Last Name" name="lastname"  />
                <FormErrorMessage>
                <ErrorMessage name="lastname" />
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={4}>
                <FormLabel htmlFor="email">Email<span style={{marginLeft: 4}}>*</span></FormLabel>
                <Field as={Input} type="email" id="email" placeholder="Email address" name="email"  />
                <FormErrorMessage>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>
 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >
                <FormLabel htmlFor="password">Password<span style={{marginLeft: 4}}>*</span></FormLabel> 
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer"/>} />
                <Field as={Input} id="password" type={visiblePass? "text":"password"} placeholder="Password" name="password"  />
                </InputGroup>
                <FormErrorMessage>
                <ErrorMessage name="password"/>
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword} mt={4} >
                <FormLabel htmlFor="confirmPassword"> Confirm Password<span style={{marginLeft: 4}}>*</span></FormLabel> 
                <Field as={Input} id="confirmPassword" type="password" placeholder="Confirm password" name="confirmPassword"  />
                <FormErrorMessage>
                <ErrorMessage name="confirmPassword"/>
                </FormErrorMessage>

                </FormControl>

                
                <FormControl isInvalid={!!formik.errors.phoneNumber && formik.touched.phoneNumber} mt={4} >
                <FormLabel htmlFor="phoneNumber">Phone Number<span style={{marginLeft: 4}}>*</span></FormLabel>
                <Field as={PhoneInput} flags={flags} defaultCountry="US" id="phoneNumber" selected={formik.values.phoneNumber} onChange={(phoneNumber)=>formik.setFieldValue("phoneNumber", phoneNumber)} placeholder="Phone Number" name="phoneNumber"  />
                <FormErrorMessage>
                <ErrorMessage name="phoneNumber" />
                </FormErrorMessage>

                </FormControl>

                <HStack>

                <FormControl isInvalid={!!formik.errors.birthmonth && formik.touched.birthmonth} mt={4}>
                <FormLabel htmlFor="birthmonth">Birth Month<span style={{marginLeft: 4}}>*</span></FormLabel>
                <Select as={DatePicker} placeholderText="Birth Month" selected={formik.values.birthmonth} dateFormat="MMMM"showMonthYearPicker onChange={(birthmonth)=> formik.setFieldValue("birthmonth", birthmonth)}  id="birthmonth" placeholder="Birth Month" name="birthmonth"  />
                <FormErrorMessage>
                <ErrorMessage name="birthmonth" />
                </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formik.errors.birthday && formik.touched.birthday} mt={4}>
                <FormLabel htmlFor="birthday">Birth Day<span style={{marginLeft: 4}}>*</span></FormLabel>
                <Select as={DatePicker} id="birthday" placeholderText="Birth Day" selected={formik.values.birthday} dateFormat="dd" onChange={(birthday)=> formik.setFieldValue("birthday", birthday)} placeholder="Birth Day" name="birthday"  />
                <FormErrorMessage>
                <ErrorMessage name="birthday" />
                </FormErrorMessage>
                </FormControl>
                </HStack>

                <FormControl isInvalid={!!formik.errors.allergies && formik.touched.allergies} mt={4}>
                <FormLabel htmlFor="allergies">Allergies</FormLabel>
                <Field as={Select}  id="allergies" name="allergies">

                  <optgroup style={{color:"#495E57" }} label="Allergies will not be used when preparing your order.">
                  <option style={{fontStyle:"italic"}} disabled value=""> Select an option</option>
                  <option >Lactose</option>
                  <option>Shellfish</option>
                  <option>Peanuts</option>
                  <option>Nuts</option>
                  <option>Soy</option>
                  <option>Wheat (Gluten)</option>
                  <option>Eggs</option>
                  <option>Fish</option>

                  </optgroup>
                </Field>
                <FormErrorMessage>
                <ErrorMessage name="allergies" />
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.dietary && formik.touched.dietary} mt={4}>
                  <FormLabel>Dietary Preferences</FormLabel>
                  <Field as={Select} name= "dietary" id="dietary">
                  <optgroup style={{color:"#495E57" }} label="Let us know about your dietary preferences.">
                  <option style={{fontStyle:"italic"}} value="" disabled>Select an option</option>
                  <option>Vegan</option>
                  <option>Vegetarian</option>
                  <option>Kosher</option>
                  <option>Pescatarian</option>
                  </optgroup>

                  </Field>
                  <FormErrorMessage>
                <ErrorMessage name="dietary" />
                </FormErrorMessage>

                <FormHelperText  mt={5}>Your personal data will be used to support your experience throughout this website,
                to manage access to your account and for other purposes described in our privacy policy.
                </FormHelperText>
                </FormControl>

                <FormControl isInvalid={!!formik.errors.check && formik.touched.check} mt={5}>
                
                <Field as={Checkbox} id="check" name="check" required>I agree to the <Link to="/termsAndConditions" style={{color: "#EE9972",fontWeight:"bold", textDecoration: "underline"}}>Terms and Conditions</Link></Field>
                <FormErrorMessage>
                <ErrorMessage name="check" />
                </FormErrorMessage>

                </FormControl>
              

                <Button type="submit" isLoading={formik.isSubmitting} mt={8} width="full" background="#F4CE14" fontWeight="bold" >Register</Button>

                </Form>
                )}
                </Formik>

                <Text mt={4}>I have an account<span onClick={(e)=> setLogin(false)} style={{color: "#EE9972", fontWeight: "bold", cursor: "pointer", marginLeft: 8}}>Sign in</span></Text>

               
                </Card> 

                <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#81C784">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


            

            <AlertDialog
            isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
            onClose={onClose2}
            >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= "#FF8A65">
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>
        </div>
    )
}

export default Login