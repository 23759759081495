import { Heading, Image, useBreakpointValue, Box, Flex, Container, Center, Text, HStack, VStack } from "@chakra-ui/react"
import unsplash from "./Assets/unsplash.jpg"
import chefs from "./Assets/chefs.jpg"
import cook from "./Assets/cook.jpg"

import chefs2 from "./Assets/chefs2.jpg"
import lemonc from "./Assets/lemonc.jpg"
import lemon2 from "./Assets/lemon2.jpg"
import indoor from "./Assets/indoor.jpg"
import indoor2 from "./Assets/indoor2.jpg"
import indoor3 from "./Assets/indoor3.jpg"

import React, {useState} from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'




function About(){


  let mobileView = useBreakpointValue({
    base: true,
    lg: false
  })


  let tabletView = useBreakpointValue({
    md: true
  })


  let [hover, sethover] = useState(false)
  let [hover2, sethover2] = useState(false)
  let [hover3, sethover3] = useState(false)
  let [hover4, sethover4] = useState(false)

  
  let settings={
    fade:"true",
    speed:500,
     dots:"true",
    slidesToShow:1,
    slidesToScroll:1,
    infinite: "true"
  }


  if (mobileView){
    return(
      <div>
         <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10} id="about">

              <VStack spacing={5} alignItems="start"  marginLeft={5} marginRight={5}>
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                          About
                   </Heading><br/>

               
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                  
                    
                    <Text fontSize={tabletView && "2xl"} color="white" mt={5} >
                    We are a family owned Mediterranean restaurant 
                    focused on traditional recipes served with a
                    twist. At Little Lemon, we pride ourselves on
                    being more than just a dining destination; we're
                    a family-oriented Mediterranean haven where
                    traditions come to life with a contemporary flair.
                    Our commitment is to bring you an authentic taste
                    of the Mediterranean, weaving together 
                    time-honoured recipes and innovative culinary
                    techniques to create a symphony of flavors that
                    dance on the plate.<br/>
                    </Text>
                    

                  <Box overflow="hidden" borderRadius={10}>
                  <Image width={tabletView? 680:320} height={tabletView? 480:240} objectFit="cover" transform= {hover && "scale(1.1)"} transition="transform 0.3s ease-in-out" src={unsplash} borderRadius={10} onMouseEnter={(e)=> sethover(true)} onMouseLeave={(e)=> sethover(false)}/>
                  </Box>
        
              </VStack>
                 
            </Box>



            <Box p={5} minHeight="40vh" paddingBottom={10}>

              <VStack spacing={5} alignItems="start"  marginLeft={5} marginRight={5}>
                         
                    <Text fontSize={tabletView && "2xl"} >
                    Step into our restaurant and you'll find a warm 
                    and inviting atmosphere that reflects the essence 
                    of Mediterranean hospitality. Whether you're 
                    gathering with family, friends or colleagues, our 
                    goal is to provide a memorable dining experience
                    where every detail is crafted with care. From the
                    moment you enter, the aromas of our carefully
                    prepared dishes will transport you to the
                    sun-kissed shores of the Mediterranean<br/>
                    </Text>

                    <Box>
                    <Slider style={{width:tabletView? "680px": "310px"}} fade speed={2000} dots={true} autoplay autoplaySpeed={3000} slidesToScroll={1} slidesToShow={1}>
                    <div>
                      <Image src={lemon2} height={tabletView? 480: 240} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                    </div>

                    <div>
                      <Image src={lemonc} height={tabletView? 480: 240} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                    </div>

                    <div>
                      <Image src={indoor} width={tabletView? 680:320}  height={tabletView? 480: 240} objectFit="cover" transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                    </div>

                    <div>
                      <Image src={indoor2} width={tabletView? 680:320} objectFit="cover" height={tabletView? 480:240} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                    </div>

                    <div>
                      <Image src={indoor3} width={tabletView? 680:320} objectFit="cover" height={tabletView? 480: 240} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                    </div>
                  
                  </Slider>
                  </Box>
                   
                    <Text fontSize={tabletView && "2xl"}>
                    Our menu is a celebration of the region's 
                    diverse culinary heritage, featuring a curated
                    selection of dishes that pay homage to
                    traditional flavors while embracing the 
                    creativity of our chiefs. Each ingredient is
                    a handpicked to ensure freshness, authencity
                    and delivering a dining experience that captures
                    the true spirit of the Mediterranean.<br/>
                    </Text>

                    <Slider style={{width: tabletView? "690px": "310px"}} fade dots autoplay autoplaySpeed={3000} speed={2000} overflow="hidden" borderRadius={10} mt={-5}>
                    <Box >
                    <Image src={chefs} height={tabletView? 480: 240} transform= {hover2 && "scale(1.1)"} transition= "transform 0.3s ease-in-out"  borderRadius={10} mt={5} onMouseEnter={(e)=> sethover2(true)} onMouseLeave={(e)=> sethover2(false)}/>
                    </Box>
                    <Box >
                    <Image src={cook} height={tabletView? 490: 240} transform= {hover2 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover2(true)} onMouseLeave={(e)=> sethover2(false)}/>
                    </Box>
                    </Slider>

                    <Text fontSize={tabletView && "2xl"} >
                    But our commitment goes beyond just the food, it
                    extends to our dedication to sustainabily and 
                    and commity. We source our ingredients locally 
                    whenever possible, supporting local farmers and 
                    producers to bring you the best quality while
                    minimizing our environmental footprint. As a
                    family-oriented establishment footprint, we 
                    believe in creating connections with our 
                    community and strive to be a place where people
                    twist.<br/>
                    </Text>

                  
                    <Box overflow="hidden" borderRadius={10}>
                    <Image src={chefs2} height={tabletView? 480: 240} width={tabletView? 680:320} transform= {hover3 && "scale(1.1)"} transition= "transform 0.3s ease-in-out"  borderRadius={10} mt={-10} onMouseEnter={(e)=> sethover3(true)} onMouseLeave={(e)=> sethover3(false)}/>
                    </Box>

                    <Text fontSize={tabletView && "2xl"}>
                    At Little Lemon, we invite you to embark on 
                    culinary journey that transcends the ordinary. Join
                    us in savouring the rich tapestry of Mediterranean
                    flavors, where tradition meets innovation and every
                    meal is an opportunity to create lasting memorioes
                    Whether you're a seasoned lover of Mediterranean
                    cuisine or a newcomer eager to explore, we look
                    forward to welcoming you to our table for an
                    experince that goes beyond the plate.
                    </Text>
        
              </VStack>
                 
            </Box>


      </div>
    )
  }

  
  else{

    return(
        <div>
                        
            
            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={20}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                          About
                   </Heading><br/>

               
               <HStack width="93%" justifyContent="space-between" >
                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                    

                    <Text color="white" fontSize="xl" mt={5}>
                    We are a family owned Mediterranean restaurant <br/>
                    focused on traditional recipes served with a<br/>
                    twist. At Little Lemon, we pride ourselves on<br/>
                    being more than just a dining destination; we're<br/>
                    a family-oriented Mediterranean haven where<br/>
                    traditions come to life with a contemporary flair.<br/>
                    Our commitment is to bring you an authentic taste<br/>
                    of the Mediterranean, weaving together <br/>
                    time-honoured recipes and innovative culinary<br/>
                    techniques to create a symphony of flavors that<br/>
                    dance on the plate.<br/>
                    </Text>
                </Box>


                  <Box overflow="hidden" borderRadius={10} >
                <Image transform= {hover && "scale(1.1)"} transition="transform 0.3s ease-in-out" src={unsplash} borderRadius={10} onMouseEnter={(e)=> sethover(true)} onMouseLeave={(e)=> sethover(false)}/>
                  </Box>
                  
                </HStack>
                 
              </VStack>
        
            </Box>



            <Box p={5} minHeight="40vh" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={20}>
                  
               
               <HStack width="93%" justifyContent="space-between">
                <Box>

                    <Text mt={-130} color="black" fontSize="xl" >
                    Step into our restaurant and you'll find a warm <br/>
                    and inviting atmosphere that reflects the essence <br/>
                    of Mediterranean hospitality. Whether you're <br/>
                    gathering with family, friends or colleagues, our <br/>
                    goal is to provide a memorable dining experience<br/>
                    where every detail is crafted with care. From the<br/>
                    moment you enter, the aromas of our carefully<br/>
                    prepared dishes will transport you to the<br/>
                    sun-kissed shores of the Mediterranean<br/><br/> 
                   
                    Our menu is a celebration of the region's <br/>
                    diverse culinary heritage, featuring a curated<br/>
                    selection of dishes that pay homage to<br/>
                    traditional flavors while embracing the <br/>
                    creativity of our chiefs. Each ingredient is<br/>
                    a handpicked to ensure freshness, authencity<br/>
                    and delivering a dining experience that captures<br/>
                    the true spirit of the Mediterranean.<br/><br/>

                    But our commitment goes beyond just the food, it<br/>
                    extends to our dedication to sustainabily and <br/>
                    and commity. We source our ingredients locally <br/>
                    whenever possible, supporting local farmers and <br/>
                    producers to bring you the best quality while<br/>
                    minimizing our environmental footprint. As a<br/>
                    family-oriented establishment footprint, we <br/>
                    believe in creating connections with our <br/>
                    community and strive to be a place where people<br/>
                    thrive.<br/><br/>
                    At Little Lemon, we invite you to embark on <br/> 
                    culinary journey that transcends the ordinary. Join<br/>
                    us in savouring the rich tapestry of Mediterranean<br/>
                    flavors, where tradition meets innovation and every<br/>
                    meal is an opportunity to create lasting memorioes<br/>
                    Whether you're a seasoned lover of Mediterranean<br/>
                    cuisine or a newcomer eager to explore, we look<br/>
                    forward to welcoming you to our table for an<br/>
                    experince that goes beyond the plate.

                    </Text>
                </Box>

                <VStack spacing={10}>

                <Box>
                <Slider style={{width: "640px"}} fade speed={2000} dots={true} autoplay autoplaySpeed={3000} slidesToScroll={1} slidesToShow={1}>
                <div>
                  <Image src={lemon2} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                </div>

                <div>
                  <Image src={lemonc} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                </div>

                <div>
                  <Image src={indoor} width={640} height={427} objectFit="cover" transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                </div>

                <div>
                  <Image src={indoor2} width={640} objectFit="cover" height={427} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                </div>

                <div>
                  <Image src={indoor3} width={640} objectFit="cover" height={427} transform= {hover4 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" borderRadius={10} mt={5} onMouseEnter={(e)=> sethover4(true)} onMouseLeave={(e)=> sethover4(false)}/>
                </div>
               
               </Slider>
                </Box>


                <Slider style={{width: "640px"}} fade dots autoplay autoplaySpeed={3000} speed={2000} overflow="hidden" borderRadius={10} mt={-5}>
                <Box >
                <Image src={chefs} transform= {hover2 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" width={640} borderRadius={10} mt={5} onMouseEnter={(e)=> sethover2(true)} onMouseLeave={(e)=> sethover2(false)}/>
                </Box>
                <Box >
                <Image src={cook} height={427} transform= {hover2 && "scale(1.1)"} transition= "transform 0.3s ease-in-out" width={640} borderRadius={10} mt={5} onMouseEnter={(e)=> sethover2(true)} onMouseLeave={(e)=> sethover2(false)}/>
                </Box>
                </Slider>



                <Box overflow="hidden" borderRadius={10}>
                <Image src={chefs2} transform= {hover3 && "scale(1.1)"} transition= "transform 0.3s ease-in-out"  width={640} borderRadius={10} mt={-10} onMouseEnter={(e)=> sethover3(true)} onMouseLeave={(e)=> sethover3(false)}/>
                </Box>

                                
                </VStack>
                

                </HStack>

          
              </VStack>

             

                   
            </Box>

        </div>

    )
  }
}

export default About