import {Box, Text, Heading, HStack, VStack, UnorderedList, ListItem, ListIcon} from "@chakra-ui/react"
import { useBreakpointValue } from "@chakra-ui/react"


function TermsAndConditions(){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })


  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })



  if (mobileView){
    return(
          <div>
            
            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={5} marginRight={5}  >
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                          Terms And Conditions
                   </Heading><br/>

               
               <HStack spacing={40}>
                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                    

                    <Text fontWeight="bold" fontStyle="italic" color="white" fontSize="xl" mt={5}>
                    These terms and conditions outline the rules and regulations for the use of our website.
                    These guidelines ensure clarity and fairness in your interaction with our platform. Thank you 
                    for choosing Little Lemon- we're excited to have you join us on this culinary journey!
                    </Text>
                </Box>


            
                </HStack>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} mt={40} minHeight="40vh" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={5} marginRight={5}>
                  
               
                
                <Box>

                    <Text mt={-130} color="black" fontSize="xl" >
                    By accessing this website, we assume you accept these terms and conditions. 
                    Do not continue to use Little Lemon if you do not agree to take all of the 
                    terms and conditions stated on this page.<br/><br/> 

                    The following terminology applies to these Terms and Conditions, Privacy Statement 
                    and Disclaimer Notice and all agreements: "Client", "You" and "Your" refers to you, 
                    the person log on this website and compliant to Little Lemon’s terms and conditions. 
                    "Little Lemon", "Ourselves", "We", "Our" and "Us", refers to our restaurant. "Party", 
                    "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, 
                    acceptance, and consideration of payment necessary to undertake the process of our assistance 
                    to the Client in the most appropriate manner for the express purpose of meeting the Client’s 
                    needs in respect of provision of Little Lemon’s stated services, in accordance with and subject to, 
                    prevailing law of United States. Any use of the above terminology or other words in the singular, 
                    plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.

                    </Text>

                    <Box mt={8}>
                    <Heading as= "h2"  >
                    Cookies
                    </Heading>
                    </Box>
                   
                    <Text color="black" mt={5} fontSize="xl" >
                    We employ the use of cookies. By accessing Little Lemon, you agree to use cookies in agreement with Little Lemon's 
                    Privacy Policy.<br/><br/>
                    </Text>
 
                    
                    <Box >
                    <Heading as= "h2"  >
                    License
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Unless otherwise stated, Little Lemon and/or its licensors own the intellectual property 
                    rights for all material on Little Lemon. All intellectual property rights are reserved. 
                    You may access this from Little Lemon for your own personal use subjected to restrictions 
                    set in these terms and conditions.
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  >
                    You must not:
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    <UnorderedList>
                    <ListItem>Republish material from Little Lemon</ListItem>
                    <ListItem>Sell, rent or sub-license material from Little Lemon</ListItem>
                    <ListItem>Reproduce, duplicate or copy material from Little Lemon</ListItem>
                    <ListItem>Redistribute content from Little Lemon</ListItem>
                    </UnorderedList><br/>

                    This Agreement shall begin on the date hereof.<br/><br/>
                    Parts of this website offer an opportunity for users to post and exchange opinions and information 
                    in certain areas of the website. Little Lemon does not filter, edit, publish or review Comments prior
                    to their presence on the website. Comments do not reflect the views and opinions of Little Lemon, 
                    its agents, and/or affiliates. Comments reflect the views and opinions of the person who post their 
                    views and opinions. To the extent permitted by applicable laws, Little Lemon shall not be liable for 
                    the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use 
                    of and/or posting of and/or appearance of the Comments on this website.<br/><br/>

                    Little Lemon reserves the right to monitor all Comments and to remove any Comments which can be 
                    considered inappropriate, offensive or causes breach of these Terms and Conditions.<br/><br/>
                    You warrant and represent that:<br/><br/>

                    <UnorderedList>
                    <ListItem>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</ListItem>
                    <ListItem>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</ListItem>
                    <ListItem>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</ListItem>
                    <ListItem>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</ListItem>
                    </UnorderedList><br/>
                    You hereby grant Little Lemon a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce
                    and edit any of your Comments in any and all forms, formats or media.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Hyperlinking to our Content
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    The following organizations may link to our Website without prior written approval:<br/><br/>

                    <UnorderedList>
                    <ListItem>Government agencies</ListItem>
                    <ListItem>Search engines</ListItem>
                    <ListItem>News organizations</ListItem>
                    <ListItem>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses</ListItem>
                    <ListItem>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</ListItem>
                    </UnorderedList><br/>

                    These organizations may link to our home page, to publications or to other Website 
                    information so long as the link: (a) is not in any way deceptive; (b) does not falsely 
                    imply sponsorship, endorsement or approval of the linking party and its products and/or 
                    services; and (c) fits within the context of the linking party’s site.<br/><br/>

                    We may consider and approve other link requests from the following types of organizations:<br/><br/>
                    <ul>
                    <li>commonly-known consumer and/or business information sources</li>
                    <li>dot.com community sites</li>
                    <li>associations or other groups representing charities</li>
                    <li>online directory distributors</li>
                    <li>internet portals</li>
                    <li>accounting, law and consulting firms</li>
                    <li>educational institutions and trade associations.</li>

                    </ul><br/>
                    We will approve link requests from these organizations if we decide that: (a) the link would not make us 
                    look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative 
                    records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Little 
                    Lemon; and (d) the link is in the context of general resource information.<br/><br/>
                    These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does 
                    not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) 
                    fits within the context of the linking party’s site.<br/><br/>
                    If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you 
                    must inform us by sending an e-mail to Little Lemon. Please include your name, your organization name, contact 
                    information as well as the URL of your site, a list of any URLs from which you intend to link to our Website,
                    and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.<br/><br/>

                    Approved organizations may hyperlink to our Website as follows:<br/><br/>

                    <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                    </ul><br/>

                    No use of Little Lemon's logo or other artwork will be allowed for linking absent a trademark license agreement.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    iFrames
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the 
                    visual presentation or appearance of our Website.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Content Liability
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us 
                    against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted 
                    as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other 
                    violation of, any third-party rights.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Reservation of Rights
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We reserve the right to request that you remove all links or any particular link to our Website. You approve to 
                    immediately remove all links to our Website upon request. We also reserve the right to amend these terms and 
                    conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and 
                    follow these linking terms and conditions.
                    </Text><br/>

                    
                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Removal of links from our website
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    If you find any link on our Website that is offensive for any reason, you are free to contact and inform us 
                    at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.<br/><br/>
                    We do not ensure that the information on this website is correct; we do not warrant its completeness or accuracy;
                    nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Disclaimer
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties, 
                    and conditions relating to our website and the use of this website. Nothing in this disclaimer will:<br/><br/>

                   <ul>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>

                   </ul><br/>
                   The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: 
                   (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, 
                   including liabilities arising in contract, in tort, and for breach of statutory duty.<br/><br/>
                   As long as the website and the information and services on the website are provided free of charge, 
                   we will not be liable for any loss or damage of any nature.

                    </Text>

                </Box>
      
              </VStack>

                   
            </Box>        

          </div>
    )
  }

  else{

    return(
        <div>

            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10} id="Top">

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20} marginRight={30} paddingRight={tabletView? 0:20}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} >
                          Terms And Conditions
                   </Heading><br/>

               
               <HStack spacing={40}>
                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                    

                    <Text fontWeight="bold" fontStyle="italic" color="white" fontSize="xl" mt={5}>
                     These terms and conditions outline the rules and regulations for the use of our website.
                    These guidelines ensure clarity and fairness in your interaction with our platform. Thank you for choosing Little 
                    Lemon-we're excited to have you join us on this culinary journey!

                    </Text>
                </Box>


            
                </HStack>
                 
              </VStack>
                 
            </Box>



            
            <Box p={5} mt={40} minHeight="40vh" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20}  marginRight={30} paddingRight={tabletView? 0:20}>
                  
               
                <Box>

                    <Text mt={-130} color="black" fontSize="xl" >
                    By accessing this website, we assume you accept these terms and conditions. 
                    Do not continue to use Little Lemon if you do not agree to take all of the 
                    terms and conditions stated on this page.<br/><br/> 

                    The following terminology applies to these Terms and Conditions, Privacy Statement 
                    and Disclaimer Notice and all agreements: "Client", "You" and "Your" refers to you, 
                    the person log on this website and compliant to Little Lemon’s terms and conditions. 
                    "Little Lemon", "Ourselves", "We", "Our" and "Us", refers to our restaurant. "Party", 
                    "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, 
                    acceptance, and consideration of payment necessary to undertake the process of our assistance 
                    to the Client in the most appropriate manner for the express purpose of meeting the Client’s 
                    needs in respect of provision of Little Lemon’s stated services, in accordance with and subject to, 
                    prevailing law of United States. Any use of the above terminology or other words in the singular, 
                    plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.

                    </Text>

                    <Box mt={8}>
                    <Heading as= "h2"  >
                    Cookies
                    </Heading>
                    </Box>
                   
                    <Text color="black" mt={5} fontSize="xl" >
                    We employ the use of cookies. By accessing Little Lemon, you agree to use cookies in agreement with Little Lemon's 
                    Privacy Policy.<br/><br/>
                    </Text>
 
                    
                    <Box >
                    <Heading as= "h2"  >
                    License
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Unless otherwise stated, Little Lemon and/or its licensors own the intellectual property 
                    rights for all material on Little Lemon. All intellectual property rights are reserved. 
                    You may access this from Little Lemon for your own personal use subjected to restrictions 
                    set in these terms and conditions.
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  >
                    You must not:
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    <UnorderedList>
                    <ListItem>Republish material from Little Lemon</ListItem>
                    <ListItem>Sell, rent or sub-license material from Little Lemon</ListItem>
                    <ListItem>Reproduce, duplicate or copy material from Little Lemon</ListItem>
                    <ListItem>Redistribute content from Little Lemon</ListItem>
                    </UnorderedList><br/>

                    This Agreement shall begin on the date hereof.<br/><br/>
                    Parts of this website offer an opportunity for users to post and exchange opinions and information 
                    in certain areas of the website. Little Lemon does not filter, edit, publish or review Comments prior
                    to their presence on the website. Comments do not reflect the views and opinions of Little Lemon, 
                    its agents, and/or affiliates. Comments reflect the views and opinions of the person who post their 
                    views and opinions. To the extent permitted by applicable laws, Little Lemon shall not be liable for 
                    the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use 
                    of and/or posting of and/or appearance of the Comments on this website.<br/><br/>

                    Little Lemon reserves the right to monitor all Comments and to remove any Comments which can be 
                    considered inappropriate, offensive or causes breach of these Terms and Conditions.<br/><br/>
                    You warrant and represent that:<br/><br/>

                    <UnorderedList>
                    <ListItem>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</ListItem>
                    <ListItem>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</ListItem>
                    <ListItem>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</ListItem>
                    <ListItem>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</ListItem>
                    </UnorderedList><br/>
                    You hereby grant Little Lemon a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce
                    and edit any of your Comments in any and all forms, formats or media.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Hyperlinking to our Content
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    The following organizations may link to our Website without prior written approval:<br/><br/>

                    <UnorderedList>
                    <ListItem>Government agencies</ListItem>
                    <ListItem>Search engines</ListItem>
                    <ListItem>News organizations</ListItem>
                    <ListItem>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses</ListItem>
                    <ListItem>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</ListItem>
                    </UnorderedList><br/>

                    These organizations may link to our home page, to publications or to other Website 
                    information so long as the link: (a) is not in any way deceptive; (b) does not falsely 
                    imply sponsorship, endorsement or approval of the linking party and its products and/or 
                    services; and (c) fits within the context of the linking party’s site.<br/><br/>

                    We may consider and approve other link requests from the following types of organizations:<br/><br/>
                    <ul>
                    <li>commonly-known consumer and/or business information sources</li>
                    <li>dot.com community sites</li>
                    <li>associations or other groups representing charities</li>
                    <li>online directory distributors</li>
                    <li>internet portals</li>
                    <li>accounting, law and consulting firms</li>
                    <li>educational institutions and trade associations.</li>

                    </ul><br/>
                    We will approve link requests from these organizations if we decide that: (a) the link would not make us 
                    look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative 
                    records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Little 
                    Lemon; and (d) the link is in the context of general resource information.<br/><br/>
                    These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does 
                    not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) 
                    fits within the context of the linking party’s site.<br/><br/>
                    If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you 
                    must inform us by sending an e-mail to Little Lemon. Please include your name, your organization name, contact 
                    information as well as the URL of your site, a list of any URLs from which you intend to link to our Website,
                    and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.<br/><br/>

                    Approved organizations may hyperlink to our Website as follows:<br/><br/>

                    <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                    </ul><br/>

                    No use of Little Lemon's logo or other artwork will be allowed for linking absent a trademark license agreement.<br/><br/>
                    </Text>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    iFrames
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the 
                    visual presentation or appearance of our Website.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Content Liability
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us 
                    against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted 
                    as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other 
                    violation of, any third-party rights.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Reservation of Rights
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    We reserve the right to request that you remove all links or any particular link to our Website. You approve to 
                    immediately remove all links to our Website upon request. We also reserve the right to amend these terms and 
                    conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and 
                    follow these linking terms and conditions.
                    </Text><br/>

                    
                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Removal of links from our website
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    If you find any link on our Website that is offensive for any reason, you are free to contact and inform us 
                    at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.<br/><br/>
                    We do not ensure that the information on this website is correct; we do not warrant its completeness or accuracy;
                    nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                    </Text><br/>


                    <Box mt={8}>
                    <Heading as= "h2"  mt={-30}>
                    Disclaimer
                    </Heading>
                    </Box>

                    <Text color="black" mt={5} fontSize="xl" >
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties, 
                    and conditions relating to our website and the use of this website. Nothing in this disclaimer will:<br/><br/>

                   <ul>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>

                   </ul><br/>
                   The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: 
                   (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, 
                   including liabilities arising in contract, in tort, and for breach of statutory duty.<br/><br/>
                   As long as the website and the information and services on the website are provided free of charge, 
                   we will not be liable for any loss or damage of any nature.

                    </Text>

                </Box>
       b7                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
              </VStack>

                   
            </Box>

        </div>
    )
  }
}

export default TermsAndConditions