import {Text, Alert, AlertIcon, useBreakpointValue,Button, Card,Container, Center,HStack, Input,FormLabel, VStack, Box, Heading, FormControl, FormErrorMessage, InputLeftAddon, InputGroup, Select, Textarea, FormHelperText } from "@chakra-ui/react"
import {Formik,Form, Field, ErrorMessage} from "formik"
import * as Yup from 'yup'
import PhoneInput from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import 'react-phone-number-input/style.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faWarning, faStar } from "@fortawesome/free-solid-svg-icons";
import React, {useState, useEffect, useRef} from 'react'
import app from "./firebase";
import bg from "./Assets/bg.jpg"
import { addDoc, getFirestore, collection } from "firebase/firestore";
import {getDatabase, ref, onValue} from 'firebase/database'

import {Fade, Slide, SlideFade, useToast, Tooltip} from '@chakra-ui/react'




function Feedback(){


let database = getDatabase(app)


let toast = useToast()


let mobileView = useBreakpointValue({
    base: true,
    md: false
})



// This is to target the md or tablet/ipad view
let tabletView = useBreakpointValue({
    md: true,
    lg: false
})




let [inpValue, setInpValue] = useState("6")
let [isOpen, setIsOpen] = useState(false)
let [isOpen2, setIsOpen2] = useState(false)
let [alertMessage, setAlertMessage] = useState(false)
let [errorMessage, setErrorMessage] = useState(false)
let [online, setOnline] = useState()

                                                                                                           
let [initialValues, setInitialValues] = useState({

    star:inpValue,
    firstName: "",
    lastName: "",
    email:"",
    phone: "",
    role: "",
    comment: ""    
})

let validationSchema = Yup.object({
    star: Yup.string(),
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    email: Yup.string().email("Invalid email format").required("Email required"),
    phone: Yup.string().required("Phone Number required"),
    role: Yup.string().required("Role required"),
    comment:inpValue >5? Yup.string().min(25, "Characters must be at least 25 ") : Yup.string().required("Provide feedback on your satisfaction with Little Lemon").min(25, "Comment must be at least 25 characters")
})


useEffect((e)=>{

let connectionStatus = ref(database, '.info/connected')
// onValue means keep an eye on the connectionStatus if it changes give me a picture/snapshot
let connectionStatusListener = onValue(connectionStatus, (snapshot)=>{
setOnline(snapshot.val())

})

return()=>{
    connectionStatusListener()
}

}, [])


let onSubmit = async(values, actions)=> {
    
   setTimeout(() => {
    actions.setSubmitting(false)
   }, 30000);
    
    if (online){
    // Try this code which might bring an error
         
    try{
        let dataB = getFirestore(app)
        console.log("form Data:", values)
        console.log("ScoreValue:", inpValue)
    
        await addDoc(collection(dataB, "Feedback"), values)
        setIsOpen(true)
        setAlertMessage(true)    
        actions.resetForm()
        
    }

    // If the error occurs, execute this code
    catch (error){
        console.error("There was an error submitting data", error.message)
    }
}

else{
    setIsOpen2(true)
    setErrorMessage(true)
}

    setTimeout((e)=> {
        actions.setSubmitting(false)
      },500)

    setTimeout((e)=>{
        setAlertMessage(false)
        setIsOpen(false)
        setIsOpen2(false)
        setErrorMessage(false)
    },5000)

}



if(mobileView){
    return(
        <div>
        
            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start"  marginLeft={5} marginRight={5}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                          Feedback
                   </Heading><br/>

               
               <HStack spacing={40}>
                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                    
                    <Text fontWeight="bold" fontStyle="italic" color="white" fontSize="xl" mt={5}>
                    By using the Feedback form, you agree to the website terms of use, the privacy
                    policy, and the use of cookies.
                    </Text>

    
                </Box>
        
                </HStack>
                 
              </VStack>
                 
            </Box>



            <Box p={5} minHeight="40vh" paddingBottom={10} backgroundImage={bg}>

            <Card spacing={5} alignItems="start" marginLeft={2} marginRight={2} borderRadius={10}  border= "10px solid #ccc" bg="blue.800" color="white">
            
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>

                {(formik) => (

                    <Form >
                            
                        <FormControl marginLeft={5} mt={10} >
                        <FormLabel htmlFor="score" >Score: {inpValue}<FontAwesomeIcon icon={faStar} color="#F4CE14"/></FormLabel>
                        <Tooltip label="Rate your satisfaction with the service of Little Lemon" hasArrow arrowSize={15}>
                        <input type="range" style={{width: "70vw"}}  value={inpValue} onChange={(e)=> setInpValue(e.target.value)} name="score" min="1" max="10" id="score"  />
                        </Tooltip>
                        
                        </FormControl> 


                        <Container style={{display: "grid", gridTemplateColumns: "1fr", marginLeft:2}} mt={10}>
                        <Box >
                       
                            <FormControl fontSize="xl" isInvalid={!!formik.errors.firstName && formik.errors.firstName}>
                            <FormLabel>First Name <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={Input} name="firstName" id="firstName" width="70vw" color="black" bg="white" placeholder="First Name" fontSize="xl"/>
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="firstName" />
                            </FormErrorMessage>

                            </FormControl>


                            <FormControl fontSize="xl" mt={8} isInvalid={!!formik.errors.lastName && formik.errors.lastName}>
                            <FormLabel>Last Name <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={Input} name="lastName" id="lastName" width="70vw" placeholder="Last Name" color="black" bg="white" fontSize="xl"/>
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="lastName" />
                            </FormErrorMessage>

                            </FormControl>
                            
                            <FormControl fontSize="xl" mt={8} isInvalid={!!formik.errors.email && formik.errors.email}>
                            <FormLabel>Email <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={Input} name="email" id="email" width="70vw" placeholder="email" color="black" bg="white" fontSize="xl"/>
                            <FormHelperText color="white">We'll never share your email</FormHelperText>
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="email" />
                            </FormErrorMessage>

                            </FormControl>
                            

                            <FormControl fontSize="xl" mt={8} isInvalid={!!formik.errors.phone && formik.touched.phone} color="black">
                            <FormLabel color="white">Phone Number <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={PhoneInput} style={{width: "70vw"}} flags={flags} defaultCountry="US" name="phone" id="phone" placeholder="Phone number" selected={formik.values.phone} onChange={(phone)=> formik.setFieldValue("phone", phone)}/>
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="phone" />
                            </FormErrorMessage>

                            </FormControl>


                            <FormControl mt={8} isInvalid={!!formik.errors.role && formik.touched.role}>
                                <FormLabel htmlFor="role">Role <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                                <Field as={Select} name="role" id="role" color="black" bg="white" fontSize="xl">
                                    <option value="" selected disabled>Select Role</option>
                                    <option >Individual</option>
                                    <option >Business</option>
                                </Field>
                                <FormErrorMessage>
                                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                                <ErrorMessage name="role" />
                                </FormErrorMessage>
                            </FormControl>
                        </Box>



                        <Box>
                           

                            <FormControl fontSize="xl" mt={8} isInvalid={!!formik.errors.comment && formik.errors.comment}>
                            <FormLabel fontWeight="bold">Comment:</FormLabel>
                            <Field as={Textarea} name="comment" id="comment" color="black" bg="white" minHeight={150} fontSize="xl" placeholder="Write comment here..." />
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="comment" />
                            </FormErrorMessage>

                            </FormControl>

                        </Box>


                        </Container>
    
                        <Button type="submit" width="71vw" isLoading={formik.isSubmitting} marginBottom={20} marginTop={20} marginLeft={4} background="#F4CE14" fontWeight="bold">Submit</Button><br/>
        
                    </Form>
                )}

                    </Formik>

                    {/*The "in" prop is what triggers the animation*/}
                {alertMessage &&   
                <Slide in={isOpen} direction='right' style={{marginTop:120}}>
                 <Alert status="success" marginBottom={5} mt={-10}>
                        <AlertIcon />
                <span style={{color:"black"}}>Your feedback has been successfully submitted</span>
                    </Alert>
                    </Slide> }


                    {errorMessage &&    
                <Slide in={isOpen2} direction="right" style={{marginTop: 120}}>
                <Alert status="error" marginBottom={5} mt={-10}>
                        <AlertIcon />
                <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
                    </Alert> 
                    </Slide>}



            </Card>
            </Box>
        

        </div>
    )
}



else{

    return(
        <div>
            
            <Box p={5} minHeight="40vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start" marginLeft={30} paddingLeft={tabletView? 0:20} marginRight={30} paddingRight={tabletView? 0:20}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} id="Top">
                          Feedback
                   </Heading><br/>

               
               <HStack spacing={40}>
                <Box>
                   <Box mt={8}>
                    <Heading as= "h1" size="2xl" color="#F4CE14" mt={-30}>
                    Little Lemon
                    </Heading>
                    <Heading as="h2" color="white">Chicago</Heading>
                    </Box>
                    
                    <Text fontWeight="bold" fontStyle="italic" color="white" fontSize="xl" mt={5}>
                    By using the Feedback form, you agree to the website terms of use, the privacy
                    policy, and the use of cookies.
                    </Text>

    
                </Box>
        
                </HStack>
                 
              </VStack>
                 
            </Box>



            <Box p={5} minHeight="40vh" paddingBottom={10} backgroundImage={bg}>

            <Card spacing={5} alignItems="start" border="10px solid #ccc" bg="blue.800" color="white" marginLeft={tabletView? 0:20} paddingLeft={20} marginRight={tabletView? 0:20} paddingRight={20} borderRadius={10}>
            
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>

                {(formik) => (

                    <Form >
                            
                        <FormControl marginLeft={-12} mt={10}>
                        <FormLabel htmlFor="star" >Score: {inpValue}<FontAwesomeIcon icon={faStar} color="#F4CE14"/></FormLabel>
                        <Tooltip label="Rate your level of satisfaction with the service of Little Lemon" hasArrow arrowSize={15}>
                        <input type="range" style={{width: tabletView? "40vw":"30vw"}}  value={inpValue} onChange={(e)=> setInpValue(e.target.value)} name="star" min="1" max="10" id="star"  />
                        </Tooltip>
                        </FormControl> 
                            
                        
                        <Container style={{display: "grid", gridTemplateColumns: "1fr 1fr", gap:tabletView? 50: 255, marginLeft:-69}} mt={10}>
                        <Box >
                       

                            <FormControl isInvalid={!!formik.errors.firstName && formik.touched.firstName} >
                            <FormLabel htmlFor="firstName" >First Name<sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={Input} name="firstName" id="firstName" width={tabletView? "40vw":"30vw"} color="black" bg="white" fontSize="2xl" placeholder="First Name" />
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="firstName" />
                            </FormErrorMessage>
                            </FormControl>


                            <FormControl fontSize="2xl" mt={8} isInvalid={!!formik.errors.phone && formik.touched.phone} color="black">
                            <FormLabel color="white">Phone Number <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={PhoneInput} flags={flags} defaultCountry="US" name="phone" id="phone" style={{width: tabletView? 300: ""}}  placeholder="Phone number" selected={formik.values.phone} onChange={(phone)=> formik.setFieldValue("phone", phone)}/>
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="phone" />
                            </FormErrorMessage>

                            </FormControl>


                            <FormControl mt={8} isInvalid={!!formik.errors.role && formik.touched.role}>
                                <FormLabel htmlFor="role">Role <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                                <Field as={Select} name="role" id="role" color="black" bg="white" width={tabletView? "40vw":"30vw"} fontSize="2xl">
                                    <option value="" selected disabled>Select Role</option>
                                    <option >Individual</option>
                                    <option >Business</option>
                                </Field>
                                <FormErrorMessage>
                                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                                <ErrorMessage name="role" />
                                </FormErrorMessage>
                            </FormControl>
                        </Box>



                        <Box>
                            <FormControl isInvalid={!!formik.errors.lastName && formik.touched.lastName}>
                            <FormLabel htmlFor="lastName" >Last Name <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={Input} name="lastName" id="lastName" width={tabletView? "40vw":"30vw"} color="black" bg="white"  fontSize="2xl" placeholder="Last Name" />
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="lastName" />
                            </FormErrorMessage>
                            </FormControl>


                            <FormControl fontSize="2xl" mt={8} isInvalid={!!formik.errors.email && formik.errors.email} >
                            <FormLabel>Email <sup style={{marginLeft: 4}}>*</sup></FormLabel>
                            <Field as={Input} name="email" id="email" color="black" bg="white" width={tabletView? "40vw":"30vw"} placeholder="Email"  fontSize="2xl"/>
                            <FormHelperText color="white" >We'll never share your email</FormHelperText>
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="email" />
                            </FormErrorMessage>

                            </FormControl>


                            <FormControl fontSize="2xl" mt={8} isInvalid={!!formik.errors.comment && formik.errors.comment}>
                            <FormLabel fontWeight="bold">Comment:</FormLabel>
                            <Field as={Textarea} name="comment" id="comment" minHeight={150} fontSize="2xl" color="black" bg="white" width={tabletView? "40vw":"30vw"}  placeholder="Write comment here..." />
                            <FormErrorMessage>
                            <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/></span>
                            <ErrorMessage name="comment" />
                            </FormErrorMessage>

                            </FormControl>

                        </Box>


                        </Container>
    
                        <Button type="submit" width="70vw" isLoading={formik.isSubmitting} marginBottom={20} marginTop={20} marginLeft={1} background="#F4CE14" fontWeight="bold">Submit</Button><br/>
        
                    </Form>
                )}

            </Formik>
 

        {alertMessage &&    
        <Slide in={isOpen} direction="right" style={{marginTop: 120}}>
        <Alert status="success" marginBottom={5} mt={-10}>
                <AlertIcon />
            <span style={{color:"black"}}>Your feedback has been successfully submitted</span>

            </Alert> 
            </Slide>}


            {errorMessage &&    
        <Slide in={isOpen2} direction="right" style={{marginTop: 120}}>
        <Alert status="error" marginBottom={5} mt={-10}>
                <AlertIcon />
            <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
            </Alert> 
            </Slide>}



            </Card>
            </Box>
            

        </div>
    )
    }
}

export default Feedback