import { Heading, useBreakpointValue, Drawer,DrawerOverlay,DrawerContent,DrawerHeader,DrawerCloseButton, DrawerBody, FormControl ,Card, Button, Image, Box,Text, Container, HStack, VStack, Input, Textarea, IconButton } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import MenuDashMob from "./Menu4Dash Mob";
import {Formik, Form, Field, FormikConsumer, useFormikContext} from 'formik'
import * as Yup from 'yup'
import app from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDoc, doc, getFirestore } from "firebase/firestore";



import React, {useEffect, useState} from 'react'
import crab from "./Assets/crab.jpg"
import spinach from "./Assets/spinach.jpg"
import Nachos from "./Assets/Nachos.jpg"
import Couscous from "./Assets/Couscous.jpg"
import mezze from "./Assets/mezze.jpg"
import paella from "./Assets/paella.jpg"
import Ratato from "./Assets/Ratato.jpg"
import risotto from "./Assets/risotto.jpg"
import shak from "./Assets/shak.jpg"
import greeksalad from "./Assets/greeksalad.jpg"
import bruschetta from "./Assets/bruschetta.jpg"
import dessert from "./Assets/dessert.jpg"


function MenuDash ({count, setCount, addToCart, isOpenShop, setIsOpenShop, cart, setCart, avatarUrl, setAvatarUrl, name, setName}){

let mobileView = useBreakpointValue({
  base: true,
  md: false
})



// This is to target the md or tablet/ipad view
let tabletView = useBreakpointValue({
  md: true,
  lg: false
})



//React documentation recommends the functional form of state update when new state value depends on previous state value. Otherwise the simpler form is used.
// It becomes crirical especially when async is involved
  let handleCount = ()=> {
    setCount(prevCount => prevCount  + addSub)
  }

  let handleCount2 = ()=> {
    setCount(prevCount => prevCount  + addSub2)
  }

  let handleCount3 = ()=> {
    setCount(prevCount => prevCount  + addSub3)
  }

  let handleCount4 = ()=> {
    setCount(prevCount => prevCount  + addSub4)
  }

  let handleCount5 = ()=> {
    setCount(prevCount => prevCount  + addSub5)
  }

  let handleCount6 = ()=> {
    setCount(prevCount => prevCount  + addSub6)
  }

  let handleCount7 = ()=> {
    setCount(prevCount => prevCount  + addSub7)
  }

  let handleCount8 = ()=> {
    setCount(prevCount => prevCount  + addSub8)
  }

  let handleCount9 = ()=> {
    setCount(prevCount => prevCount  + addSub9)
  }

  let handleCount10 = ()=> {
    setCount(prevCount => prevCount  + addSub10)
  }

  let handleCount11 = ()=> {
    setCount(prevCount => prevCount  + addSub11)
  }

  let handleCount12 = ()=> {
    setCount(prevCount => prevCount  + addSub12)
  }

  let [addSub, setAddSub] = useState(1)
  let [addSub2, setAddSub2] = useState(1)
  let [addSub3, setAddSub3] = useState(1)
  let [addSub4, setAddSub4] = useState(1)
  let [addSub5, setAddSub5] = useState(1)
  let [addSub6, setAddSub6] = useState(1)
  let [addSub7, setAddSub7] = useState(1)
  let [addSub8, setAddSub8] = useState(1)
  let [addSub9, setAddSub9] = useState(1)
  let [addSub10, setAddSub10] = useState(1)
  let [addSub11, setAddSub11] = useState(1)
  let [addSub12, setAddSub12] = useState(1)


// This is for Addition
  let handleAdd =()=> {
    setAddSub(prevValue=> prevValue + 1)
  }

  let handleAdd2 =()=> {
    setAddSub2(prevValue=> prevValue + 1)
  }

   let handleAdd3 =()=> {
    setAddSub3(prevValue=> prevValue + 1)
  }

  let handleAdd4 =()=> {
    setAddSub4(prevValue=> prevValue + 1)
  }

  let handleAdd5 =()=> {
    setAddSub5(prevValue=> prevValue + 1)
  }

  let handleAdd6 =()=> {
    setAddSub6(prevValue=> prevValue + 1)
  }

  let handleAdd7 =()=> {
    setAddSub7(prevValue=> prevValue + 1)
  }

  let handleAdd8 =()=> {
    setAddSub8(prevValue=> prevValue + 1)
  }

  let handleAdd9 =()=> {
    setAddSub9(prevValue=> prevValue + 1)
  }

  let handleAdd10 =()=> {
    setAddSub10(prevValue=> prevValue + 1)
  }

  let handleAdd11 =()=> {
    setAddSub11(prevValue=> prevValue + 1)
  }

  let handleAdd12 =()=> {
    setAddSub12(prevValue=> prevValue + 1)
  }



  //This is for Subtraction
  
  let handleSub = ()=>{
    if(addSub > 1){
    setAddSub((prevValue) => prevValue -1)
    }
  }
  
  let handleSub2 = ()=>{
    if(addSub2 > 1){
    setAddSub2((prevValue) => prevValue -1)
    }
  }

  let handleSub3 = ()=>{
    if(addSub3 > 1){
    setAddSub3((prevValue) => prevValue -1)
    }
  }


  let handleSub4 = ()=>{
    if(addSub4 > 1){
    setAddSub4((prevValue) => prevValue -1)
    }
  }

  let handleSub5 = ()=>{
    if(addSub5 > 1){
    setAddSub5((prevValue) => prevValue -1)
    }
  }

  let handleSub6 = ()=>{
    if(addSub6 > 1){
    setAddSub6((prevValue) => prevValue -1)
    }
  }

  let handleSub7 = ()=>{
    if(addSub7 > 1){
    setAddSub7((prevValue) => prevValue -1)
    }
  }

  let handleSub8 = ()=>{
    if(addSub8 > 1){
    setAddSub8((prevValue) => prevValue -1)
    }
  }

  let handleSub9 = ()=>{
    if(addSub9 > 1){
    setAddSub9((prevValue) => prevValue -1)
    }
  }

  let handleSub10 = ()=>{
    if(addSub2 > 1){
    setAddSub10((prevValue) => prevValue -1)
    }
  }

  let handleSub11 = ()=>{
    if(addSub11 > 1){
    setAddSub11((prevValue) => prevValue -1)
    }
  }

  let handleSub12 = ()=>{
    if(addSub12 > 1){
    setAddSub12((prevValue) => prevValue -1)
    }
  }
  //let {values} = useFormikContext()

  let auth = getAuth(app)
  let dataB = getFirestore(app)


    let [hover1A, sethover1A] = useState(false)
    let [hover2A, sethover2A] = useState(false)
    let [hover3A, sethover3A] = useState(false)
    let [hover1M, sethover1M] = useState(false)
    let [hover2M, sethover2M] = useState(false)
    let [hover3M, sethover3M] = useState(false)
    let [hover4M, sethover4M] = useState(false)
    let [hover5M, sethover5M] = useState(false)
    let [hover6M, sethover6M] = useState(false)
    let [hover1D, sethover1D] = useState(false)
    let [hover2D, sethover2D] = useState(false)
    let [hover3D, sethover3D] = useState(false)
    
    let [initialValues, setInitialvalues] = useState({
      name: ""
    })

    let validationSchema = Yup.object({
      name: Yup.string()
    })



    useEffect((e)=> {
     

      let authStateChangeListener = onAuthStateChanged(auth, async(currentUser) => {

        if (currentUser){
          let userDoc = doc(dataB, "users", currentUser.uid)
          let getUserDoc = await getDoc(userDoc)

          getUserDoc.exists() && setInitialvalues(getUserDoc.data())
          getUserDoc.exists() && setAvatarUrl(getUserDoc.data().avatarUrl)
          getUserDoc.exists() && setName(getUserDoc.data().name)
        }

        else{
          return;
        }

      })
      
      return()=> {
        authStateChangeListener()
      }

    }, [auth, dataB])

    
    
    let handleChange = (e)=> {
      setInitialvalues({...initialValues, [e.target.name]: e.target.value })
    }


    let style ={
      background: "transparent",
      
      outline: "none",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
      color: "#F4CE14",
      borderRadius: 0
    }

    
    if (mobileView){
      return(
        <div>
          <MenuDashMob addToCart={addToCart} setCart={setCart} setCount={setCount}/>

        </div>
      )
    }


    else{

    

    return(
        <div>
            <Container p={5} minHeight="40vh" maxWidth="full" background="#495E57" postion="relative" id="landing-section">
          
          <VStack spacing={5} alignItems="start" marginLeft={tabletView ?10:30} paddingLeft={tabletView? 0:20}>
          
          <Box>
          
          <HStack spacing={4}>
          <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10}>
            Welcome, 
          </Heading>

          <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}>

            {(formik)=> (

            <Form>
          <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
          <Field as={Input} fontWeight="bold" size="5xl" mt={10} readOnly style={style} name="name" value={initialValues.name} fontSize="5xl" onChange={handleChange}/>
          </FormControl>
                   
          </Form>

            )}

 
          </Formik>
      
          </HStack>
          <Text color="white" fontSize="2xl" mt={4}>Continue to order online today, tomorrow and beyond!</Text>
        

          <Heading mt={5} as= "h1" size="3xl" color="#F4CE14" paddingTop={10}>
            Our Menu
          </Heading>
          <Heading  mt={20} as="h2" color="white">Appetizers</Heading>
            </Box>

          </VStack>


           {/*Cards Here */}
      
        <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: tabletView? "1fr 1fr": "1fr 1fr 1fr", gap: tabletView? 20: 40, paddingLeft: tabletView? 20: 100, paddingRight: tabletView? 20: 100, paddingBottom: 50}}>
          
          <Card style={{boxShadow: hover1A && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1A(true)} onMouseLeave={(e)=> sethover1A(false)} overflow="hidden" borderRadius={10} cursor="pointer">
            <Image src={crab} height={238} objectFit="cover" borderRadius={10} transform= {hover1A && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack spacing={79}>
            <Heading as="h3" size="md">Crab Artichoke</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$9.99</Text>
              </HStack><br/>
            <Text paddingBottom={2} textAlign="left">Indulge in a culinary delight with our
            perfect blend of succulent crab meat and tender artichoke hearts, expertly crafted
            to tantalize your taste buds.            
            </Text><br/>
            <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub} isDisabled={addSub == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
              addToCart({id: 1, name: "Crab Artichoke", image: crab,descp1:"Crab meat", descp2:"Tender artichoke hearts", price: 9.99 * addSub, qty: addSub})
              handleCount()
              }}>Add To Cart $9.99</Button>

            </HStack>

            </Box>
          </Card>

          <Card overflow="hidden" onMouseEnter={(e)=> sethover2A(true)} onMouseLeave={(e)=> sethover2A(false)} style={{boxShadow: hover2A && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={spinach} objectFit="cover" borderRadius={10} height={238} transform={hover2A && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
            <Box p={8} background="#EDEFEE">
              <HStack spacing={110}>
            <Heading as="h3" size="md">Spinach Balls</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$5.99</Text>
              </HStack><br/>
            <Text textAlign="left" paddingBottom={2}> These little bites are crafted with fresh spinach, 
            savory herbs and a hint of garlic creating a crispy exterior that gives way 
            to a soft, flavorful center.
            </Text><br/>
            <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd2} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub2}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub2} isDisabled={addSub2 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
              addToCart({id: 2, name: "Spinach Balls", image: spinach,descp1:"Fresh spinach", descp2:"Savory herbs", price: 5.99 * addSub2, qty: addSub2})
              handleCount2()

              }}>Add To Cart $5.99</Button>

            </HStack>
            </Box>
          </Card>

          <Card overflow="hidden" onMouseEnter={(e)=> sethover3A(true)} onMouseLeave={(e)=> sethover3A(false)} style={{boxShadow: hover3A && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
            <Image src={Nachos} height={238} borderRadius={10} objectFit="cover" transform={hover3A && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
            <Box p={8} background="#EDEFEE">
              <HStack spacing={100}>
            <Heading as="h3" size="md">Vegan Nachos</Heading>
            <Text marginBottom={2} fontWeight="bold" color="#EE9972">$7.99</Text>
              </HStack><br/>
            <Text paddingBottom={2} textAlign="left">Dive into the acrumptious world of
            our Nachos layered with a mix of black beans, fresh vegetables and a dairy-free cheese
            that melts in every bite.
            </Text><br/>
            <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd3} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub3}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub3} isDisabled={addSub3 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                addToCart({id: 3, name: "Vegan Nachos", image: Nachos,descp1:"Black beans", descp2:"Fresh vegetables", price: 7.99 * addSub3, qty: addSub3})
                handleCount3()
              }}>Add To Cart $7.99</Button>

            </HStack>

            </Box>
          </Card>
          

        </Container>

        {/*Cards end here*/}
        </Container>



        
        {/*Main starts here*/}
        <Container p={5} minHeight="40vh" maxWidth="full" postion="relative" id="landing-section">
          
        <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0:20}>
        
        <Box>
       
        <Heading  mt={10} as="h2" >Main Dishes</Heading>
          </Box>

        </VStack>


         {/*Cards Here */}
    
      <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: tabletView? "1fr 1fr": "1fr 1fr 1fr", gap: tabletView? 20: 40, paddingLeft: tabletView? 20: 100, paddingRight: tabletView? 20: 100, paddingBottom: 50}}>
        
        <Card style={{boxShadow: hover1M && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1M(true)} onMouseLeave={(e)=> sethover1M(false)} overflow="hidden" borderRadius={10} cursor="pointer">
          <Image src={Couscous} height={238} objectFit="cover" borderRadius={10} transform= {hover1M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={130}>
          <Heading as="h3" size="md">Couscous</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$10.99</Text>
            </HStack><br/>
          <Text paddingBottom={2} textAlign="left">Dive into a plate of our exquisite Couscous, 
          infused with aromatic herbs and spices that transports you to the shores of the 
          Mediterranean.
          </Text><br/>
          <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd4} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub4}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub4} isDisabled={addSub4 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                addToCart({id: 4, name: "Couscous", image: Couscous,descp1:"Aromatic herbs", descp2:"Spices", price: 10.99 * addSub4, qty: addSub4})
                handleCount4()
              }}>Add To Cart $10.99</Button>

            </HStack>
          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover2M(true)} onMouseLeave={(e)=> sethover2M(false)} style={{boxShadow: hover2M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={mezze} borderRadius={10} height={238} transform={hover2M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
          <Box p={8} background="#EDEFEE">
            <HStack spacing={40}>
          <Heading as="h3" size="md">Mezze</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$10.99</Text>
            </HStack><br/>
          <Text textAlign="left" paddingBottom={2}>Embark on an adventure with our Mezze platter, an array of
          small bites that dance on your taste buds. Each dip and bite tell a story of flavors.
          
          </Text><br/>
          <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd5} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub5}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub5} isDisabled={addSub5 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                addToCart({id: 5, name: "Mezze", image: mezze,descp1:"Small bites", descp2:"Flavors", price: 10.99 * addSub5, qty: addSub5})
                handleCount5()
              }}>Add To Cart $10.99</Button>

            </HStack>
          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover3M(true)} onMouseLeave={(e)=> sethover3M(false)} style={{boxShadow: hover3M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={paella} height={238} borderRadius={10} objectFit="cover" transform={hover3M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={169}>
          <Heading as="h3" size="md">Paella</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$14.99</Text>
            </HStack><br/>
          <Text paddingBottom={2} textAlign="left">An authentic masterpiece that brings the spirit of Valencia
            to your table. Each spoonful is a blend of saffron-infused rice, seafood and spices.
          </Text><br/>
          <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd6} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub6}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub6} isDisabled={addSub6 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                addToCart({id: 6, name: "Paella", image: paella,descp1:"Saffron-infused rice", descp2:"Seafood", price: 14.99 * addSub6, qty: addSub6})
                handleCount6()
              }}>Add To Cart $14.99</Button>

            </HStack>

          </Box>
        </Card>


        {/*Second set for Main starts here */}
        <Card style={{boxShadow: hover4M && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover4M(true)} onMouseLeave={(e)=> sethover4M(false)} overflow="hidden" borderRadius={10} cursor="pointer">
          <Image src={Ratato} height={238} objectFit="cover" borderRadius={10} transform= {hover4M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={130}>
          <Heading as="h3" size="md">Ratatouille</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$11.99</Text>
            </HStack><br/>
          <Text paddingBottom={2} textAlign="left">A vegetable medley that's as visually stunning as it's delicious.
          Layers of perfectly-roasted vegetables, bathed in a tomato-based sauce.
          </Text><br/>
          <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd7} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub7}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub7} isDisabled={addSub7 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                addToCart({id: 7, name: "Ratatouille", image: Ratato,descp1:"Roasted vegetables", descp2:"Tomato-based sauce", price: 11.99 * addSub7, qty: addSub7})
                handleCount7()
              }}>Add To Cart $11.99</Button>

            </HStack>

          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover5M(true)} onMouseLeave={(e)=> sethover5M(false)} style={{boxShadow: hover5M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={risotto} objectFit="cover" borderRadius={10} height={238} transform={hover5M && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
          <Box p={8} background="#EDEFEE">
            <HStack spacing={40}>
          <Heading as="h3" size="md">Risotto</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
            </HStack><br/>
          <Text textAlign="left" paddingBottom={2}>Wrap yourself in the embrace of our creamy italian classic that takes you on
          a journey through Northern Italy. Arborio rice slow-cooked to perfection.
          
          </Text><br/>
          <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd8} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub8}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub8} isDisabled={addSub8 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                addToCart({id: 8, name: "Risotto", image: risotto,descp1:"Creamy italian classic", descp2:"Arborio rice", price: 12.99 * addSub8, qty: addSub8})
                handleCount8()
              }}>Add To Cart $12.99</Button>

            </HStack>
          </Box>
        </Card>

        <Card overflow="hidden" onMouseEnter={(e)=> sethover6M(true)} onMouseLeave={(e)=> sethover6M(false)} style={{boxShadow: hover6M && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
          <Image src={shak} height={238} borderRadius={10} objectFit="cover" transform={hover6M && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
          <Box p={8} background="#EDEFEE">
            <HStack spacing={127}>
          <Heading as="h3" size="md">Shakshuka</Heading>
          <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
            </HStack><br/>
          <Text paddingBottom={2} textAlign="left">Energize your day with our North-African delight that's a burst of flavors.
          Poached eggs in a tomato and pepper stew create a 
          breakfast experience.
          </Text><br/>
          <Heading as="h4" fontSize="sm">Quantity</Heading>

            <HStack spacing={4}>
            <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
              <IconButton margin={1} size="sm" onClick={handleAdd9} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
              <Text fontWeight="bold" marginBottom={0}>{addSub9}</Text>
              <IconButton margin={1} size="sm" onClick={handleSub9} isDisabled={addSub9 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
            </HStack>
              <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
                addToCart({id: 9, name: "Shakshuka", image: shak,descp1:"Poached eggs", descp2:"Tomato and pepper stew", price: 12.99 * addSub9, qty: addSub9})
                handleCount9()
              }}>Add To Cart $12.99</Button>

            </HStack>

          </Box>
        </Card>
        

      </Container>

      {/*Cards end here*/}
      </Container>



     {/*Desserts starts here*/}
     <Container p={5}  background="#495E57" minHeight="40vh" maxWidth="full" postion="relative" id="landing-section">
          
     <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0:20}>
     
     <Box>
    
     <Heading mt={10} as="h2" color="white">Desserts</Heading>
       </Box>

     </VStack>


      {/*Cards Here */}
 
   <Container minHeight="40vh" maxWidth="full" p={15} style={{display: "grid", gridTemplateColumns: tabletView? "1fr 1fr": "1fr 1fr 1fr", gap: tabletView? 20: 40, paddingLeft: tabletView? 20: 100, paddingRight: tabletView? 20:100, paddingBottom: 50}}>
     
     <Card style={{boxShadow: hover1D && "0px 0px 50px rgba(0,0,0,0.5)"}} onMouseEnter={(e)=> sethover1D(true)} onMouseLeave={(e)=> sethover1D(false)} overflow="hidden" borderRadius={10} cursor="pointer">
       <Image src={greeksalad} borderRadius={10} transform= {hover1D && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
       <Box p={8} background="#EDEFEE">
         <HStack spacing={115}>
       <Heading as="h3" size="md">Greek Salad</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
         </HStack><br/>
       <Text paddingBottom={4} textAlign="left">The famous greek salad of crispy
       lettuce, peppers,  olives and our Chicago style chesse,
       garnished with crunchy garlic and rosemary
       croutone.
       </Text><br/>
       <Heading as="h4" fontSize="sm">Quantity</Heading>

        <HStack spacing={4}>
        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
          <IconButton margin={1} size="sm" onClick={handleAdd10} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
          <Text fontWeight="bold" marginBottom={0}>{addSub10}</Text>
          <IconButton margin={1} size="sm" onClick={handleSub10} isDisabled={addSub10 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
        </HStack>
          <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
            addToCart({id: 10, name: "Greek Salad", image: greeksalad,descp1:"Crispy lettuce", descp2:"Crunchy garlic", price: 12.99 * addSub10, qty: addSub10})
            handleCount10()
          }}>Add To Cart $12.99</Button>

        </HStack>

       </Box>
     </Card>

     <Card overflow="hidden" onMouseEnter={(e)=> sethover2D(true)} onMouseLeave={(e)=> sethover2D(false)} style={{boxShadow: hover2D && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
       <Image src={bruschetta} borderRadius={10} height={238} transform={hover2D && "scale(1.1)"} transition="transform 0.3s ease-in-out"/>
       <Box p={8} background="#EDEFEE">
         <HStack spacing={40}>
       <Heading as="h3" size="md">Bruchetta</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$5.99</Text>
         </HStack><br/>
       <Text textAlign="left" paddingBottom={2}>Our Bruchetta is made from grilled
       bread that has been smeared with garlic and seasoned with salt and olive
       oil.
       </Text><br/>
       <Heading as="h4" fontSize="sm">Quantity</Heading>

        <HStack spacing={4}>
        <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
          <IconButton margin={1} size="sm" onClick={handleAdd11} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
          <Text fontWeight="bold" marginBottom={0}>{addSub11}</Text>
          <IconButton margin={1} size="sm" onClick={handleSub11} isDisabled={addSub11 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
        </HStack>
          <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
            addToCart({id: 11, name: "Bruchetta", image: bruschetta, descp1:"Grilled bread", descp2:"Salt and olive oil", price: 5.99 * addSub11, qty: addSub11})
            handleCount11()
          }}>Add To Cart $5.99</Button>

        </HStack>
       </Box>
     </Card>

     <Card overflow="hidden" onMouseEnter={(e)=> sethover3D(true)} onMouseLeave={(e)=> sethover3D(false)} style={{boxShadow: hover3D && "0px 0px 50px rgba(0,0,0,0.5)"}} borderRadius={10} cursor="pointer">
       <Image src={dessert} height={238} borderRadius={10} objectFit="cover" transform={hover3D && "scale(1.1)"} transition="transform 0.3s ease-in-out" />
       <Box p={8} background="#EDEFEE">
         <HStack spacing={70}>
       <Heading as="h3" size="md">Lemon Dessert</Heading>
       <Text marginBottom={2} fontWeight="bold" color="#EE9972">$12.99</Text>
         </HStack><br/>
       <Text paddingBottom={2} textAlign="left">This comes straight from grandma's recipe book,
       every last ingredient has been sourced and is as authentic
       as can be imagined.
       </Text><br/>
       <Heading as="h4" fontSize="sm">Quantity</Heading>

      <HStack spacing={4}>
      <HStack spacing={2} style={{border: "2px solid gray", borderRadius: 8}} marginTop={-2}>
        <IconButton margin={1} size="sm" onClick={handleAdd12} background="gray.300" icon={<FontAwesomeIcon icon={faAdd}/>} />
        <Text fontWeight="bold" marginBottom={0}>{addSub12}</Text>
        <IconButton margin={1} size="sm" onClick={handleSub12} isDisabled={addSub12 == 1} background="gray.300" icon={<FontAwesomeIcon icon={faMinus}/>} />
      </HStack>
        <Button as="h3" size="md" background="#F4CE14" borderRadius={10} fontWeight="bold" onClick={(e)=>{
          addToCart({id: 12, name: "Lemon Dessert", image: dessert, descp1:"Lemon", descp2:"Grandma's recipe", price: 12.99 * addSub12, qty: addSub12})
          handleCount12()
        }}>Add To Cart $12.99</Button>

      </HStack>

       </Box>
     </Card>

    </Container>


    </Container>
    


      </div>
    ) 
  }
}

export default MenuDash