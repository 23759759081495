import {Box, Text, Center,Heading, HStack, VStack} from "@chakra-ui/react"
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useBreakpointValue } from "@chakra-ui/react"
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons"


function Cards(){

  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })



  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })




  if (mobileView){
    return(
          <div>
            
            <Box p={5} minHeight="100vh" background="#495E57" paddingBottom={10}>

              <VStack spacing={5} alignItems="start" marginLeft={5} marginRight={5}  >
 
                      
                  <Heading as= "h1" size="2xl" color="#F4CE14" paddingTop={10} id="inbox">
                          My Cards
                   </Heading><br/>

                   
                  <Text fontWeight="bold" color="white" fontSize="xl" mt={5}>
                    You don't have any credit cards added to your account.

                  </Text>

                 
              </VStack>

              <Box alignItems="start" mt={10} color="white">
              <Center>
              <HStack width="90%" justifyContent="space-between">
                <FontAwesomeIcon size="8x" icon={faCcVisa}/>
                <FontAwesomeIcon size="8x" icon={faCcMastercard}/>
                   
              </HStack>
            </Center>
            </Box>
                 
            </Box>


          </div>
    )
  }

  else{

    return(
        <div>

            <Box p={5} minHeight="100vh" background="#495E57" paddingBottom={10} id="privacyPolicy">

              <VStack spacing={5} alignItems="start" marginLeft={tabletView? 10:30} paddingLeft={tabletView? 0:20} marginRight={tabletView? 10:30} paddingRight={tabletView? 0:20}>
 
                      
                  <Heading as= "h1" size="3xl" color="#F4CE14" paddingTop={10} >
                          My Cards
                   </Heading><br/>


                  <Text fontWeight="bold" color="white" fontSize="xl" mt={5}>
                    You don't have any credit cards added to your account.

                  </Text>

               
              </VStack>

              <Box alignItems="start" mt={10} color="white">
            <Center>
                <HStack width={tabletView? "60%":"40%"} justifyContent="space-between">
                <FontAwesomeIcon size="10x" icon={faCcVisa}/>
                <FontAwesomeIcon size="10x" icon={faCcMastercard}/>
                   
                </HStack>
            </Center>
            </Box>
                 
            </Box>


        </div>
    )
  }
}

export default Cards